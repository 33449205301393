import RequestUtils from "utils/RequestUtils";
import DepartureRes from "types/res/DepartureRes";

export default class DepartureApi {
  static async findAll() {
    return await RequestUtils.getArray(
      DepartureRes,
      `/management/departure/find-all`
    );
  }
}
