import AuthRes from "types/res/AuthRes";
import { createSlice } from "@reduxjs/toolkit";
import { login, logout } from "redux/actions/auth";
import StaffRes from "types/res/StaffRes";
import { fileUpload } from "redux/actions/account";

export type AuthState = AuthRes;
const initialState: AuthState = { accessToken: "", staff: {} as StaffRes };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (_, { payload }) => ({
      ...payload,
    }));
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(fileUpload.fulfilled, (state, { payload }) => ({
      ...state,
      staff: {
        ...state.staff,
        account: payload,
      },
    }));
  },
});

export default authSlice.reducer;
