import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  MaxLength,
} from "class-validator";
import Spec from "constant/Spec";

export default class DailyReportReq {
  @IsNotEmpty({
    message: "未入力です",
  })
  @IsNumber()
  driverId!: number;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsNumber()
  dealId!: number;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsBoolean()
  isApprove!: boolean;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsString()
  date!: string;

  @IsOptional()
  @IsString()
  startTime!: string;

  @IsOptional()
  @IsString()
  endTime?: string;

  @IsOptional()
  @IsNumber()
  distance?: number;

  @IsOptional()
  @IsNumber()
  bringOut1?: number;

  @IsOptional()
  @IsNumber()
  bringOut2?: number;

  @IsOptional()
  @IsNumber()
  deliveryComplete1?: number;

  @IsOptional()
  @IsNumber()
  deliveryComplete2?: number;

  @IsOptional()
  @IsNumber()
  bringOutLocation?: number;

  @IsOptional()
  @IsNumber()
  deliveryCompleteLocation?: number;

  @IsOptional()
  @IsNumber()
  overTime?: number;

  @IsOptional()
  @IsNumber()
  expenses?: number;

  @IsOptional()
  @MaxLength(Spec.maxLength.driver.remarks, {
    message: "最大文字数を超えています",
  })
  @IsString()
  remarks?: string;

  constructor(arg: Partial<DailyReportReq>) {
    Object.assign(this, arg);
  }
}
