import RequestUtils from "utils/RequestUtils";
import SupplierRes from "types/res/SupplierRes";
import SupplierReq from "types/req/SupplierReq";
import SearchSupplierReq from "types/req/search/SearchSupplierReq";

export default class SupplierApi {
  static async findAll(body: SearchSupplierReq) {
    return await RequestUtils.postArray(
      SupplierRes,
      `/management/supplier/find-all`,
      body
    );
  }

  static async count(body: SearchSupplierReq) {
    return await RequestUtils.postCount(`/management/supplier/count`, body);
  }

  static async create(req: SupplierReq) {
    return await RequestUtils.post(SupplierRes, `/management/supplier`, req);
  }

  static async update(id: number, req: SupplierReq) {
    return await RequestUtils.put(
      SupplierRes,
      `/management/supplier/${id}`,
      req
    );
  }

  static async delete(id: number) {
    return await RequestUtils.delete(`/management/supplier/${id}`);
  }
}
