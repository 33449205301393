import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import SupplierRes from "types/res/SupplierRes";
import SupplierReq from "types/req/SupplierReq";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  createSupplier,
  deleteSupplier,
  fetchAllSupplier,
  updateSupplier,
} from "redux/actions/supplier";
import { FormType } from "components/FormField";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import SupplierApi from "api/SupplierApi";

const Supplier = () => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const supplierIds = useAppSelector((state) => state.supplier.result);
  const supplierEntities = useAppSelector(
    (state) => state.supplier.entities.suppliers
  );
  const suppliers = supplierIds.map((id) => supplierEntities[id]);
  const headers: { key: keyof SupplierRes; label: string }[] = [
    { key: "name", label: "取引先名" },
    { key: "kana", label: "取引先かな" },
    { key: "invoiceNumber", label: "登録番号" },
    { key: "tel", label: "電話番号" },
    { key: "email", label: "メールアドレス" },
    { key: "postCode", label: "郵便番号" },
    { key: "address", label: "住所" },
    { key: "registeredAt", label: "登録日" },
    { key: "remarks", label: "備考" },
  ];

  const forms = [
    {
      label: "取引先名",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "取引先かな",
      key: "kana",
      type: FormType.Text,
    },
    {
      label: "登録番号",
      key: "invoiceNumber",
      type: FormType.Text,
    },
    {
      label: "電話番号",
      key: "tel",
      type: FormType.Text,
    },
    {
      label: "メールアドレス",
      key: "email",
      type: FormType.Text,
    },
    {
      label: "郵便番号",
      key: "postCode",
      type: FormType.Text,
    },
    {
      label: "住所",
      key: "address",
      type: FormType.Text,
    },
    {
      label: "登録日",
      key: "registeredAt",
      type: FormType.Date,
    },
    {
      label: "備考",
      key: "remarks",
      type: FormType.TextArea,
    },
  ];

  useEffect(() => {
    const apiCall = async () => {
      const result = await SupplierApi.count({
        limit: 0,
        offset: 0,
      });
      setTotalCount(result);
    };
    apiCall();
  }, []);

  useEffect(() => {
    dispatch(fetchAllSupplier({ req: { limit, offset } }));
  }, [dispatch, limit, offset]);

  const onClickSearch = async () => {
    setIsLoading(true);
    await dispatch(fetchAllSupplier({ req: { limit, offset, name, tel } }));
    const result = await SupplierApi.count({
      limit: 0,
      offset: 0,
      name,
      tel,
    });
    setTotalCount(result);
    setIsLoading(false);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Header
        name={name}
        setName={setName}
        tel={tel}
        setTel={setTel}
        onClickSearch={onClickSearch}
      />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <SupplierTable
          formId={"supplier"}
          data={suppliers}
          addType={SupplierReq}
          addFunc={(formData) => createSupplier({ req: formData })}
          limit={limit}
          offset={offset}
          setLimit={setLimit}
          setOffset={setOffset}
          totalCount={totalCount}
          values={[
            (s) => s.name,
            (s) => s.kana,
            (s) => s.invoiceNumber || "未設定",
            (s) => s.tel || "未設定",
            (s) => s.email || "未設定",
            (s) => s.postCode || "未設定",
            (s) => s.address || "未設定",
            (s) =>
              s.registeredAt
                ? DateTime.fromJSDate(s.registeredAt).toFormat(
                    FORMAT_TYPE.YEAR_DAY
                  )
                : "未設定",
            (s) => s.remarks || "未設定",
          ]}
          deleteFunc={(formData) => deleteSupplier({ id: formData.id })}
          title={"取引先"}
          rows={headers}
          updateFunc={(formData) =>
            updateSupplier({ id: formData.id, req: formData })
          }
          forms={forms}
        />
      )}
    </Box>
  );
};

type HeaderProps = {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  tel: string;
  setTel: React.Dispatch<React.SetStateAction<string>>;
  onClickSearch: () => Promise<void>;
};
const Header = ({ name, setName, tel, setTel, onClickSearch }: HeaderProps) => {
  return (
    <Box display="flex" margin={2} alignItems={"flex-end"}>
      <Box>
        <TextField
          label="取引先名"
          variant="standard"
          value={name}
          onChange={(event) => {
            event.persist();
            setName(event.target.value);
          }}
        />
      </Box>
      <Box marginLeft={1}>
        <TextField
          label="電話番号"
          variant="standard"
          value={tel}
          onChange={(event) => {
            event.persist();
            setTel(event.target.value);
          }}
        />
      </Box>
      <Box marginLeft={1}>
        <Button variant="contained" color="secondary" onClick={onClickSearch}>
          検索
        </Button>
      </Box>
    </Box>
  );
};

const SupplierTable = styled<
  React.FC<CommonTableProps<SupplierRes, SupplierReq>>
>(CommonTable)`
  margin-top: 24px;
`;

export default Supplier;
