import React from "react";
import {
  Box,
  Checkbox,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";

type Props = {
  id: string;
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  options: any[];
  label: string;
};
const MultipleSelect = ({
  id,
  selected,
  setSelected,
  options,
  label,
}: Props) => {
  return (
    <Box>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        multiple
        value={selected}
        onChange={(event) => {
          setSelected((prev: string[]) => {
            if (
              prev.includes("すべて") &&
              (event.target.value as string[]).indexOf("すべて") === -1
            ) {
              return [];
            } else if (
              !prev.includes("すべて") &&
              (event.target.value as string[]).indexOf("すべて") !== -1
            ) {
              return [...options.map((option) => String(option.id)), "すべて"];
            } else if (
              (event.target.value as string[]).length === options.length &&
              (event.target.value as string[]).indexOf("すべて") === -1
            ) {
              return [...options.map((option) => String(option.id)), "すべて"];
            } else if (
              (event.target.value as string[]).length <= options.length
            ) {
              return (event.target.value as string[]).filter(
                (name) => name !== "すべて"
              );
            } else {
              return event.target.value as string[];
            }
          });
        }}
        input={<Input id={id} />}
        style={{ width: "100px" }}
        renderValue={(selected) => {
          if ((selected as string[]).includes("すべて")) {
            return "すべて";
          } else {
            return options
              .filter((option) =>
                (selected as string[]).includes(String(option.id))
              )
              .map((option) => option.name)
              .join(", ");
          }
        }}
      >
        <MenuItem key={"すべて"} value={"すべて"}>
          <Checkbox name="all" checked={selected.indexOf("すべて") > -1} />
          <ListItemText primary={"すべて"} />
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.id} value={String(option.id)}>
            <Checkbox checked={selected.indexOf(String(option.id)) !== -1} />
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default MultipleSelect;
