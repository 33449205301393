import SchemaUtils from "utils/SchemaUtils";
import SupplierRes from "types/res/SupplierRes";
import { normalize } from "normalizr";
import { createAsyncThunk } from "@reduxjs/toolkit";
import SupplierApi from "api/SupplierApi";
import SupplierReq from "types/req/SupplierReq";
import SearchSupplierReq from "types/req/search/SearchSupplierReq";

const supplierSchema = SchemaUtils.createEntity<SupplierRes>("suppliers");
type schemaType = { suppliers: { [key: number]: SupplierRes } };

export const fetchAllSupplier = createAsyncThunk(
  "fetchAllSupplier",
  async ({ req }: { req: SearchSupplierReq }) => {
    const data = await SupplierApi.findAll(req);
    return normalize<undefined, schemaType, number[]>(data, [supplierSchema]);
  }
);

export const fetchAllSupplierWithSelect = createAsyncThunk(
  "fetchAllSupplierWithSelect",
  async ({ req }: { req: SearchSupplierReq }) => {
    const data = await SupplierApi.findAll(req);
    return normalize<undefined, schemaType, number[]>(data, [supplierSchema]);
  }
);

export const createSupplier = createAsyncThunk(
  "createSupplier",
  async ({ req }: { req: SupplierReq }) => {
    const data = await SupplierApi.create(req);
    return normalize<undefined, schemaType, number>(data, supplierSchema);
  }
);

export const updateSupplier = createAsyncThunk(
  "updateSupplier",
  async ({ id, req }: { id: number; req: SupplierReq }) => {
    const data = await SupplierApi.update(id, req);
    return normalize<undefined, schemaType, number>(data, supplierSchema);
  }
);

export const deleteSupplier = createAsyncThunk(
  "deleteSupplier",
  async ({ id }: { id: number }) => {
    await SupplierApi.delete(id);
    return id;
  }
);
