import RequestUtils from "utils/RequestUtils";
import DetailRes from "types/res/DetailRes";
import DetailReq from "types/req/DetailReq";
import SearchDetailReq from "types/req/search/SearchDetailReq";

export default class DetailApi {
  static async findAll(body: SearchDetailReq) {
    return await RequestUtils.postArray(
      DetailRes,
      `/management/detail/find-all`,
      body
    );
  }

  static async count(body: SearchDetailReq) {
    return await RequestUtils.postCount(`/management/detail/count`, body);
  }

  static async sendMail(body: { detailId: number; image: string }) {
    return await RequestUtils.postVoid(`/management/detail/send-mail`, body);
  }
  static async find(id: number) {
    return await RequestUtils.get(DetailRes, `/management/detail/${id}`);
  }

  static async findPrevData(driverId: number, month: string) {
    return await RequestUtils.get(
      DetailRes,
      `/management/detail/findPrevData/${driverId}/${month}`
    );
  }

  static async create(req: DetailReq) {
    return await RequestUtils.post(DetailRes, `/management/detail`, req);
  }

  static async update(id: number, req: DetailReq) {
    return await RequestUtils.put(DetailRes, `/management/detail/${id}`, req);
  }

  static async delete(id: number) {
    return await RequestUtils.delete(`/management/detail/${id}`);
  }
}
