import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import DailyReportRes from "types/res/DailyReportRes";
import DailyReportReq from "types/req/DailyReportReq";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { fetchAllDeal } from "redux/actions/deal";
import { fetchAllDriver } from "redux/actions/driver";
import { FormType } from "components/FormField";
import {
  createDailyReport,
  deleteDailyReport,
  fetchAllDailyReport,
  updateDailyReport,
} from "redux/actions/dailyReport";
import MultipleSelect from "components/MultipleSelect";
import DealRes from "types/res/DealRes";
import DriverRes from "types/res/DriverRes";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import CsvUtils from "utils/CsvUtils";
import DailyReportApi from "api/DailyReportApi";

const DailyReport = () => {
  const dispatch = useAppDispatch();
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [startDate, setStartDate] = useState(
    DateTime.local().minus({ day: 1 }).toFormat(FORMAT_TYPE.YEAR_DAY)
  );
  const [endDate, setEndDate] = useState(
    DateTime.local().toFormat(FORMAT_TYPE.YEAR_DAY)
  );
  const [selectDriverIds, setSelectDriverIds] = useState<string[]>([]);
  const [selectDealIds, setSelectDealIds] = useState<string[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [summary, setSummary] = useState<{
    sales: number;
    companyProfit: number;
  }>({ sales: 0, companyProfit: 0 });
  const driverIds = useAppSelector((state) => state.driver.result);
  const driverEntities = useAppSelector(
    (state) => state.driver.entities.drivers
  );
  const drivers = driverIds.map((id) => driverEntities[id]);
  const dealIds = useAppSelector((state) => state.deal.result);
  const dealEntities = useAppSelector((state) => state.deal.entities.deals);
  const deals = dealIds.map((id) => dealEntities[id]);

  const dailyReportIds = useAppSelector((state) => state.dailyReport.result);
  const dailyReportEntities = useAppSelector(
    (state) => state.dailyReport.entities.dailyReports
  );
  const dailyReports = dailyReportIds.map((id) => dailyReportEntities[id]);

  useEffect(() => {
    dispatch(fetchAllDriver({ req: { limit: 1000, offset: 0 } }));
    dispatch(fetchAllDeal({ req: { limit: 1000, offset: 0 } }));
  }, [dispatch]);

  useEffect(() => {
    if (!startDate || !endDate) return;

    const apiCall = async () => {
      const res = await DailyReportApi.summarySales({
        limit,
        offset,
        dealIds: selectDealIds.includes("すべて")
          ? undefined
          : selectDealIds.map((id) => Number(id)),
        driverIds: selectDriverIds.includes("すべて")
          ? undefined
          : selectDriverIds.map((id) => Number(id)),
        startDate,
        endDate,
      });
      setSummary({
        sales: res.totalSales,
        companyProfit: res.totalCompanyProfit,
      });
    };
    apiCall();
  }, [selectDealIds, selectDriverIds, startDate, endDate]);

  useEffect(() => {
    if (!startDate || !endDate) return;
    dispatch(
      fetchAllDailyReport({
        req: {
          limit,
          offset,
          dealIds: selectDealIds.includes("すべて")
            ? undefined
            : selectDealIds.map((id) => Number(id)),
          driverIds: selectDriverIds.includes("すべて")
            ? undefined
            : selectDriverIds.map((id) => Number(id)),
          startDate,
          endDate,
        },
      })
    );
  }, [limit, offset, selectDealIds, selectDriverIds, startDate, endDate]);

  useEffect(() => {
    if (!startDate || !endDate) return;
    const apiCall = async () => {
      const result = await DailyReportApi.count({
        limit: 0,
        offset: 0,
        dealIds: selectDealIds.includes("すべて")
          ? undefined
          : selectDealIds.map((id) => Number(id)),
        driverIds: selectDriverIds.includes("すべて")
          ? undefined
          : selectDriverIds.map((id) => Number(id)),
        startDate,
        endDate,
      });
      setTotalCount(result);
    };
    apiCall();
  }, [selectDealIds, selectDriverIds, startDate, endDate]);

  useEffect(() => {
    setSelectDealIds([...deals.map((deal) => String(deal.id)), "すべて"]);
  }, [dealIds]);

  useEffect(() => {
    setSelectDriverIds([
      ...drivers.map((driver) => String(driver.id)),
      "すべて",
    ]);
  }, [driverIds]);

  const headers: { key: keyof DailyReportRes; label: string }[] = [
    { key: "driverId", label: "ドライバー名" },
    { key: "dealId", label: "現場名" },
    { key: "date", label: "稼働日" },
    { key: "startTime", label: "開始" },
    { key: "endTime", label: "終了" },
    { key: "distance", label: "距離(km)" },
    { key: "bringOut1", label: "持出個数1" },
    { key: "bringOut2", label: "持出個数2" },
    { key: "deliveryComplete1", label: "配完個数1" },
    { key: "deliveryComplete2", label: "配完個数2" },
    { key: "bringOutLocation", label: "持出件数" },
    { key: "deliveryCompleteLocation", label: "配完件数" },
    { key: "overTime", label: "残業" },
    { key: "expenses", label: "経費(税込)" },
    { key: "remarks", label: "備考" },
    { key: "isApprove", label: "" },
  ];

  const forms = [
    {
      label: "ドライバー名",
      key: "driverId",
      type: FormType.AutoCompleteSelect,
      options: drivers,
    },
    {
      label: "現場名",
      key: "dealId",
      type: FormType.AutoCompleteSelect,
      options: deals,
      optionFilterKey: "driverId",
      optionFilterFunc: (option: DealRes, input: number) => {
        return option.drivers?.map((driver) => driver.id)?.includes(input);
      },
    },
    {
      label: "稼働日",
      key: "date",
      type: FormType.DateString,
    },
    {
      label: "開始",
      key: "startTime",
      type: FormType.Time,
    },
    {
      label: "終了",
      key: "endTime",
      type: FormType.Time,
    },
    {
      label: "距離(km)",
      key: "distance",
      type: FormType.Number,
    },
    {
      label: "持出個数1",
      key: "bringOut1",
      type: FormType.Number,
    },
    {
      label: "持出個数2",
      key: "bringOut2",
      type: FormType.Number,
    },
    {
      label: "配完個数1",
      key: "deliveryComplete1",
      type: FormType.Number,
    },
    {
      label: "配完個数2",
      key: "deliveryComplete2",
      type: FormType.Number,
    },
    {
      label: "持出件数",
      key: "bringOutLocation",
      type: FormType.Number,
    },
    {
      label: "配完件数",
      key: "deliveryCompleteLocation",
      type: FormType.Number,
    },
    {
      label: "残業",
      key: "overTime",
      type: FormType.Number,
    },
    {
      label: "経費(税込)",
      key: "expenses",
      type: FormType.Number,
    },
    {
      label: "備考",
      key: "remarks",
      type: FormType.TextArea,
    },
  ];

  const onClickSearch = async () => {
    dispatch(
      fetchAllDailyReport({
        req: {
          limit,
          offset: 0,
          dealIds: selectDealIds.includes("すべて")
            ? undefined
            : selectDealIds.map((id) => Number(id)),
          driverIds: selectDriverIds.includes("すべて")
            ? undefined
            : selectDriverIds.map((id) => Number(id)),
          startDate,
          endDate,
        },
      })
    );
    const result = await DailyReportApi.count({
      limit: 0,
      offset: 0,
      dealIds: selectDealIds.includes("すべて")
        ? undefined
        : selectDealIds.map((id) => Number(id)),
      driverIds: selectDriverIds.includes("すべて")
        ? undefined
        : selectDriverIds.map((id) => Number(id)),
      startDate,
      endDate,
    });
    setTotalCount(result);
  };

  const onClickApprove = async (id: number) => {
    dispatch(
      updateDailyReport({
        id,
        req: { ...dailyReportEntities[id], isApprove: true },
      })
    );
  };

  const onClickCsvDownload = async () => {
    const header = headers.map((header) => header.label);
    const data = dailyReports.map((dailyReport) => {
      return [
        dailyReport.driver.name,
        dailyReport.deal.name,
        dailyReport.date,
        dailyReport.startTime,
        dailyReport.endTime,
        dailyReport.distance,
        dailyReport.bringOut1,
        dailyReport.bringOut2,
        dailyReport.deliveryComplete1,
        dailyReport.deliveryComplete2,
        dailyReport.bringOutLocation,
        dailyReport.deliveryCompleteLocation,
        dailyReport.overTime,
        dailyReport.expenses,
        dailyReport.remarks,
      ];
    });
    const csv = [header, ...data];
    await CsvUtils.download("日報一覧", csv);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Header
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        drivers={drivers}
        selectDriverIds={selectDriverIds}
        setSelectDriverIds={setSelectDriverIds}
        deals={deals}
        selectDealIds={selectDealIds}
        setSelectDealIds={setSelectDealIds}
        onClickSearch={onClickSearch}
        summary={summary}
      />
      <DailyReportTable
        formId={"dailyReport"}
        data={dailyReports}
        addFunc={(formData) => createDailyReport({ req: formData })}
        addType={DailyReportReq}
        defaultValue={{
          isApprove: false,
          date: DateTime.local()
            .minus({ day: 1 })
            .toFormat(FORMAT_TYPE.YEAR_DAY),
        }}
        values={[
          (s) => s.driver.name,
          (s) => s.deal.name,
          (s) => s.date,
          (s) => s.startTime || "未設定",
          (s) => s.endTime || "未設定",
          (s) => s.distance || "未設定",
          (s) => s.bringOut1 || "未設定",
          (s) => s.bringOut2 || "未設定",
          (s) => s.deliveryComplete1 || "未設定",
          (s) => s.deliveryComplete2 || "未設定",
          (s) => s.bringOutLocation || "未設定",
          (s) => s.deliveryCompleteLocation || "未設定",
          (s) => s.overTime || "未設定",
          (s) => s.expenses || "未設定",
          (s) => s.remarks || "未設定",
          (s) => (
            <Button
              variant="contained"
              color="secondary"
              disabled={s.isApprove}
              onClick={() => onClickApprove(s.id)}
            >
              承認
            </Button>
          ),
        ]}
        limit={limit}
        setLimit={setLimit}
        offset={offset}
        setOffset={setOffset}
        totalCount={totalCount}
        deleteFunc={(formData) => deleteDailyReport({ id: formData.id })}
        title={"日報"}
        rows={headers}
        updateFunc={(formData) =>
          updateDailyReport({ id: formData.id, req: formData })
        }
        forms={forms}
        onClickCsvDownload={onClickCsvDownload}
      />
    </Box>
  );
};

type HeaderProps = {
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  drivers: DriverRes[];
  selectDriverIds: string[];
  setSelectDriverIds: React.Dispatch<React.SetStateAction<string[]>>;
  deals: DealRes[];
  selectDealIds: string[];
  setSelectDealIds: React.Dispatch<React.SetStateAction<string[]>>;
  onClickSearch: () => Promise<void>;
  summary: { sales: number; companyProfit: number };
};
const Header = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  drivers,
  selectDriverIds,
  setSelectDriverIds,
  deals,
  selectDealIds,
  setSelectDealIds,
  onClickSearch,
  summary,
}: HeaderProps) => {
  return (
    <Box display={"flex"} justifyContent={"space-between"}>
      <Box display="flex" margin={2} alignItems={"flex-end"}>
        <Box marginLeft={1}>
          <TextField
            type="date"
            label="開始日"
            variant="standard"
            value={startDate}
            onChange={(event) => {
              event.persist();
              setStartDate(event.target.value);
            }}
          />
        </Box>
        <Box marginLeft={1}>
          <TextField
            type="date"
            label="終了日"
            variant="standard"
            value={endDate}
            onChange={(event) => {
              event.persist();
              setEndDate(event.target.value);
            }}
          />
        </Box>
        <Box marginLeft={1}>
          <MultipleSelect
            selected={selectDriverIds}
            setSelected={setSelectDriverIds}
            id={"select-multiple-driver"}
            options={drivers}
            label={"ドライバー名"}
          />
        </Box>
        <Box marginLeft={1}>
          <MultipleSelect
            selected={selectDealIds}
            setSelected={setSelectDealIds}
            id={"select-multiple-deal"}
            options={deals}
            label={"担当現場名"}
          />
        </Box>
        <Box marginLeft={1}>
          <Button variant="contained" color="secondary" onClick={onClickSearch}>
            検索
          </Button>
        </Box>
      </Box>
      <Box display={"flex"} flexDirection={"column"} margin={2}>
        <Typography>合計請求金額: {summary.sales}円</Typography>
        <Typography>合計粗利金額: {summary.companyProfit}円</Typography>
      </Box>
    </Box>
  );
};

const DailyReportTable = styled<
  React.FC<CommonTableProps<DailyReportRes, DailyReportReq>>
>(CommonTable)`
  margin-top: 24px;
`;

export default DailyReport;
