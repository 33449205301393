import BaseRes from "types/res/BaseRes";
import { Expose, Type } from "class-transformer";
import InvoiceRes from "types/res/InvoiceRes";
import DailyReportRes from "types/res/DailyReportRes";

export default class InvoiceItemRes extends BaseRes {
  @Expose() readonly invoiceId!: number;
  @Type(() => InvoiceRes)
  @Expose()
  readonly invoice!: InvoiceRes;
  @Expose() readonly dailyReportId?: number;
  @Type(() => DailyReportRes)
  @Expose()
  readonly dailyReport?: DailyReportRes;
  @Expose() readonly text!: string;
  @Expose() readonly date?: string;
  @Expose() readonly dateNum?: number;
  @Expose() readonly fare?: number;
  @Expose() readonly distance?: number;
  @Expose() readonly distanceUnitPrice?: number;
  @Expose() readonly distanceOverTime?: number;
  @Expose() readonly distanceOverTimeUnitPrice?: number;
  @Expose() readonly location?: number;
  @Expose() readonly locationUnitPrice?: number;
  @Expose() readonly quantity1?: number;
  @Expose() readonly quantity2?: number;
  @Expose() readonly quantityUnitPrice1?: number;
  @Expose() readonly quantityUnitPrice2?: number;
  @Expose() readonly customize1?: number;
  @Expose() readonly customize2?: number;
  @Expose() readonly customize3?: number;
  @Expose() readonly customize4?: number;
  @Expose() readonly customize5?: number;
  @Expose() readonly customize6?: number;
  @Expose() readonly customize7?: number;
  @Expose() readonly customize8?: number;
  @Expose() readonly customize9?: number;
  @Expose() readonly customize10?: number;
  @Expose() readonly total!: number;
}
