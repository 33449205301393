import RequestUtils from "utils/RequestUtils";
import DealRes from "types/res/DealRes";
import DealReq from "types/req/DealReq";
import SearchDealReq from "types/req/search/SearchDealReq";

export default class DealApi {
  static async findAll(body: SearchDealReq) {
    return await RequestUtils.postArray(
      DealRes,
      `/management/deal/find-all`,
      body
    );
  }

  static async count(body: SearchDealReq) {
    return await RequestUtils.postCount(`/management/deal/count`, body);
  }

  static async create(req: DealReq) {
    return await RequestUtils.post(DealRes, `/management/deal`, req);
  }

  static async update(id: number, req: DealReq) {
    return await RequestUtils.put(DealRes, `/management/deal/${id}`, req);
  }

  static async delete(id: number) {
    return await RequestUtils.delete(`/management/deal/${id}`);
  }
}
