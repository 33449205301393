import InvoiceRes from "types/res/InvoiceRes";
import { createSlice } from "@reduxjs/toolkit";
import {
  createInvoice,
  deleteInvoice,
  fetchAllInvoice,
  fetchInvoice,
  updateInvoice,
} from "redux/actions/invoice";

export type InvoiceState = {
  entities: { invoices: { [key: number]: InvoiceRes } };
  result: number[];
};
const initialState: InvoiceState = { entities: { invoices: {} }, result: [] };

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllInvoice.fulfilled, (_, { payload }) => ({
      entities: { invoices: { ...payload.entities.invoices } },
      result: payload.result,
    }));
    builder.addCase(fetchInvoice.fulfilled, (_, { payload }) => ({
      entities: { invoices: { ...payload.entities.invoices } },
      result: [payload.result],
    }));
    builder.addCase(createInvoice.fulfilled, (state, { payload }) => ({
      entities: {
        invoices: { ...state.entities.invoices, ...payload.entities.invoices },
      },
      result: [...state.result, payload.result],
    }));
    builder.addCase(updateInvoice.fulfilled, (state, { payload }) => ({
      entities: {
        invoices: {
          ...state.entities.invoices,
          ...payload.entities.invoices,
        },
      },
      result: state.result,
    }));
    builder.addCase(deleteInvoice.fulfilled, (state, { payload }) => {
      const entities = { ...state.entities.invoices };
      delete entities[payload];
      return {
        entities: {
          invoices: {
            ...entities,
          },
        },
        result: state.result.filter((id) => id !== payload),
      };
    });
  },
});

export default invoiceSlice.reducer;
