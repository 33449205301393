import RequestUtils from "utils/RequestUtils";
import PageUtils from "utils/PageUtils";

export default class CsvUtils {
  private constructor() {}

  public static async templateDownload(templateUrl: string, fileName: string) {
    const blob = await RequestUtils.getBlob(templateUrl);
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    PageUtils.download(new Blob([bom, blob], { type: "text/csv" }), fileName);
  }

  public static async download(fileName: string, records: any[]) {
    const data = records.map((record) => record.join(",")).join("\r\n");
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    PageUtils.download(new Blob([bom, data], { type: "text/csv" }), fileName);
  }
}
