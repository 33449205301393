import SchemaUtils from "utils/SchemaUtils";
import DealRes from "types/res/DealRes";
import { normalize } from "normalizr";
import { createAsyncThunk } from "@reduxjs/toolkit";
import DealApi from "api/DealApi";
import DealReq from "types/req/DealReq";
import SearchDealReq from "types/req/search/SearchDealReq";

const dealSchema = SchemaUtils.createEntity<DealRes>("deals");
type schemaType = { deals: { [key: number]: DealRes } };

export const fetchAllDeal = createAsyncThunk(
  "fetchAllDeal",
  async ({ req }: { req: SearchDealReq }) => {
    const data = await DealApi.findAll(req);
    return normalize<undefined, schemaType, number[]>(data, [dealSchema]);
  }
);

export const createDeal = createAsyncThunk(
  "createDeal",
  async ({ req }: { req: DealReq }) => {
    const data = await DealApi.create(req);
    return normalize<undefined, schemaType, number>(data, dealSchema);
  }
);

export const updateDeal = createAsyncThunk(
  "updateDeal",
  async ({ id, req }: { id: number; req: DealReq }) => {
    const data = await DealApi.update(id, req);
    return normalize<undefined, schemaType, number>(data, dealSchema);
  }
);

export const deleteDeal = createAsyncThunk(
  "deleteDeal",
  async ({ id }: { id: number }) => {
    await DealApi.delete(id);
    return id;
  }
);
