import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import DriverRes from "types/res/DriverRes";
import DriverReq from "types/req/DriverReq";
import { Box, Button, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  createDriver,
  deleteDriver,
  fetchAllDriver,
  updateDriver,
} from "redux/actions/driver";
import MultipleSelect from "components/MultipleSelect";
import { FormType } from "components/FormField";
import { BankAccountType } from "types/enum/BankAccountType";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import EnumUtils from "utils/EnumUtils";
import { fetchAllDeal } from "redux/actions/deal";
import DriverApi from "api/DriverApi";

const Driver = () => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const [selectedDeals, setSelectedDeals] = useState<string[]>([]);
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const driverIds = useAppSelector((state) => state.driver.result);
  const driverEntities = useAppSelector(
    (state) => state.driver.entities.drivers
  );
  const drivers = driverIds.map((id) => driverEntities[id]);
  const dealIds = useAppSelector((state) => state.deal.result);
  const dealEntities = useAppSelector((state) => state.deal.entities.deals);
  const deals = dealIds.map((id) => dealEntities[id]);

  useEffect(() => {
    dispatch(fetchAllDeal({ req: { limit: 100, offset: 0 } }));
    const apiCall = async () => {
      const result = await DriverApi.count({
        limit: 0,
        offset: 0,
      });
      setTotalCount(result);
    };
    apiCall();
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAllDriver({ req: { limit, offset } }));
  }, [dispatch, limit, offset]);

  const headers: { key: keyof DriverRes; label: string }[] = [
    { key: "name", label: "名前" },
    { key: "kana", label: "かな" },
    { key: "invoiceNumber", label: "登録番号" },
    { key: "tel", label: "電話番号" },
    { key: "email", label: "メールアドレス" },
    { key: "postCode", label: "郵便番号" },
    { key: "address", label: "住所" },
    { key: "registeredAt", label: "登録日" },
    { key: "deals", label: "担当現場名" },
    { key: "remarks", label: "備考" },
    { key: "bankName", label: "銀行名" },
    { key: "bankAccountName", label: "口座名義" },
    { key: "bankAccountType", label: "口座種別" },
    { key: "bankAccountNumber", label: "口座番号" },
  ];
  const forms = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "かな",
      key: "kana",
      type: FormType.Text,
    },
    {
      label: "登録番号",
      key: "invoiceNumber",
      type: FormType.Text,
    },
    {
      label: "電話番号",
      key: "tel",
      type: FormType.Text,
    },
    {
      label: "メールアドレス",
      key: "email",
      type: FormType.Text,
    },
    {
      label: "郵便番号",
      key: "postCode",
      type: FormType.Text,
    },
    {
      label: "住所",
      key: "address",
      type: FormType.Text,
    },
    {
      label: "登録日",
      key: "registeredAt",
      type: FormType.Date,
    },
    {
      label: "担当現場名",
      key: "deals",
      type: FormType.AutoCompleteMultiOptions,
      options: deals.map((s) => ({ id: s.id, name: s.name })),
      value: (val: any) => val.map((v: any) => v.id),
    },
    {
      label: "備考",
      key: "remarks",
      type: FormType.TextArea,
    },
    {
      label: "銀行名",
      key: "bankName",
      type: FormType.Text,
    },
    {
      label: "口座名義",
      key: "bankAccountName",
      type: FormType.Text,
    },
    {
      label: "口座種別",
      key: "bankAccountType",
      type: FormType.Select,
      options: Object.entries(BankAccountType).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
    {
      label: "口座番号",
      key: "bankAccountNumber",
      type: FormType.Text,
    },
  ];

  const onClickSearch = async () => {
    setIsLoading(true);
    dispatch(
      fetchAllDriver({
        req: {
          name,
          tel,
          dealIds: selectedDeals.includes("すべて")
            ? undefined
            : selectedDeals.filter((s) => s !== "すべて").map(Number),
          limit,
          offset,
        },
      })
    );
    const result = await DriverApi.count({
      limit: 0,
      offset: 0,
      name,
      tel,
      dealIds: selectedDeals.includes("すべて")
        ? undefined
        : selectedDeals.filter((s) => s !== "すべて").map(Number),
    });
    setTotalCount(result);
    setIsLoading(false);
  };
  return (
    <Box display="flex" flexDirection="column">
      <Header
        name={name}
        setName={setName}
        tel={tel}
        setTel={setTel}
        selectedDeals={selectedDeals}
        setSelectedDeals={setSelectedDeals}
        onClickSearch={onClickSearch}
      />
      <DriverTable
        formId={"driver"}
        isLoading={isLoading}
        data={drivers}
        addType={DriverReq}
        limit={limit}
        offset={offset}
        setLimit={setLimit}
        setOffset={setOffset}
        totalCount={totalCount}
        addFunc={(formData) => createDriver({ req: formData })}
        values={[
          (s) => s.name,
          (s) => s.kana,
          (s) => s.invoiceNumber || "未設定",
          (s) => s.tel || "未設定",
          (s) => s.email,
          (s) => s.postCode || "未設定",
          (s) => s.address || "未設定",
          (s) =>
            s.registeredAt
              ? DateTime.fromJSDate(s.registeredAt).toFormat(
                  FORMAT_TYPE.YEAR_DAY
                )
              : "未設定",
          (s) => s.deals?.map((deal) => deal.name).join(",") || "未設定",
          (s) => s.remarks || "未設定",
          (s) => s.bankName || "未設定",
          (s) => s.bankAccountName || "未設定",
          (s) =>
            s.bankAccountType
              ? EnumUtils.mapToEnum(BankAccountType, s.bankAccountType) ||
                "未設定"
              : "未設定",
          (s) => s.bankAccountNumber || "未設定",
        ]}
        deleteFunc={(formData) => deleteDriver({ id: formData.id })}
        title={"ドライバー"}
        rows={headers}
        updateFunc={(formData) =>
          updateDriver({ id: formData.id, req: formData })
        }
        forms={forms}
      />
    </Box>
  );
};

type HeaderProps = {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  tel: string;
  setTel: React.Dispatch<React.SetStateAction<string>>;
  selectedDeals: string[];
  setSelectedDeals: React.Dispatch<React.SetStateAction<string[]>>;
  onClickSearch: () => Promise<void>;
};
const Header = ({
  name,
  setName,
  tel,
  setTel,
  selectedDeals,
  setSelectedDeals,
  onClickSearch,
}: HeaderProps) => {
  const dealIds = useAppSelector((state) => state.deal.result);
  const dealEntities = useAppSelector((state) => state.deal.entities.deals);
  const deals = dealIds.map((id) => dealEntities[id]);
  useEffect(() => {
    setSelectedDeals([...deals.map((deal) => String(deal.id)), "すべて"]);
  }, [dealIds]);

  return (
    <Box display="flex" margin={2} alignItems={"flex-end"}>
      <Box>
        <TextField
          label="ドライバー名"
          variant="standard"
          value={name}
          onChange={(event) => {
            event.persist();
            setName(event.target.value);
          }}
        />
      </Box>
      <Box marginLeft={1}>
        <TextField
          label="電話番号"
          variant="standard"
          value={tel}
          onChange={(event) => {
            event.persist();
            setTel(event.target.value);
          }}
        />
      </Box>
      <Box marginLeft={1}>
        <MultipleSelect
          selected={selectedDeals}
          setSelected={setSelectedDeals}
          id={"select-multiple-deal"}
          options={deals}
          label={"担当現場"}
        />
      </Box>
      <Box marginLeft={1}>
        <Button variant="contained" color="secondary" onClick={onClickSearch}>
          検索
        </Button>
      </Box>
    </Box>
  );
};

const DriverTable = styled<React.FC<CommonTableProps<DriverRes, DriverReq>>>(
  CommonTable
)`
  margin-top: 24px;
`;
export default Driver;
