import React from "react";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Settings } from "luxon";
import { Route, Routes } from "react-router-dom";
import Login from "pages/Login";
import { useAppSelector } from "redux/hooks";
import Account from "pages/Account";
import Staff from "pages/Staff";
import Supplier from "pages/Supplier";
import Driver from "pages/Driver";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import Deal from "pages/Deal";
import DailyReport from "pages/DailyReport";
import ResetPassword from "pages/ResetPassword";
import ResetPasswordSent from "pages/ResetPasswordSent";
import Detail from "pages/Detail";
import IndividualDetail from "pages/IndividualDetail";
import Invoice from "pages/Invoice";
import IndividualInvoice from "pages/IndividualInvoice";
import Departure from "pages/Departure";
Settings.defaultLocale = "ja";
Settings.defaultZone = "Asia/Tokyo";
const App = () => {
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      adapterLocale={"ja"}
      dateFormats={{ monthAndYear: FORMAT_TYPE.YEAR_MONTH }}
    >
      <Routes>
        {accessToken ? (
          <>
            <Route path={"dailyReport"} Component={DailyReport} />
            <Route path={"deal"} Component={Deal} />
            <Route path={"detail"}>
              <Route index Component={Detail} />
              <Route path={":id"} Component={IndividualDetail} />
            </Route>
            <Route path={"invoice"}>
              <Route index Component={Invoice} />
              <Route path={":id"} Component={IndividualInvoice} />
            </Route>
            <Route path={"driver"} Component={Driver} />
            <Route path={"supplier"} Component={Supplier} />
            <Route path={"account"} Component={Account} />
            <Route path={"staff"} Component={Staff} />
            <Route path={"departure"} Component={Departure} />
            <Route path={"*"} Component={DailyReport} />
          </>
        ) : (
          <>
            <Route path={"resetPassword"} Component={ResetPassword} />
            <Route path={"resetPassword/send"} Component={ResetPasswordSent} />
            <Route path={"*"} Component={Login} />
          </>
        )}
      </Routes>
    </LocalizationProvider>
  );
};

export default App;
