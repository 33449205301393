import SchemaUtils from "utils/SchemaUtils";
import DetailRes from "types/res/DetailRes";
import { normalize } from "normalizr";
import { createAsyncThunk } from "@reduxjs/toolkit";
import DetailApi from "api/DetailApi";
import DetailReq from "types/req/DetailReq";
import SearchDetailReq from "types/req/search/SearchDetailReq";

const detailSchema = SchemaUtils.createEntity<DetailRes>("details");
type schemaType = { details: { [key: number]: DetailRes } };

export const fetchDetail = createAsyncThunk(
  "fetchDetail",
  async ({ id }: { id: number }) => {
    const data = await DetailApi.find(id);
    return normalize<undefined, schemaType, number>(data, detailSchema);
  }
);

export const fetchAllDetail = createAsyncThunk(
  "fetchAllDetail",
  async ({ req }: { req: SearchDetailReq }) => {
    const data = await DetailApi.findAll(req);
    return normalize<undefined, schemaType, number[]>(data, [detailSchema]);
  }
);

export const createDetail = createAsyncThunk(
  "createDetail",
  async ({ req }: { req: DetailReq }) => {
    const data = await DetailApi.create(req);
    return normalize<undefined, schemaType, number>(data, detailSchema);
  }
);

export const updateDetail = createAsyncThunk(
  "updateDetail",
  async ({ id, req }: { id: number; req: DetailReq }) => {
    const data = await DetailApi.update(id, req);
    return normalize<undefined, schemaType, number>(data, detailSchema);
  }
);

export const deleteDetail = createAsyncThunk(
  "deleteDetail",
  async ({ id }: { id: number }) => {
    await DetailApi.delete(id);
    return id;
  }
);
