import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  createInvoice,
  deleteInvoice,
  fetchAllInvoice,
} from "redux/actions/invoice";
import InvoiceRes from "types/res/InvoiceRes";
import { FormType } from "components/FormField";
import { Box, Button, TextField } from "@mui/material";
import InvoiceReq from "types/req/InvoiceReq";
import MultipleSelect from "components/MultipleSelect";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import { fetchAllSupplier } from "redux/actions/supplier";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import EnumUtils from "utils/EnumUtils";
import { useNavigate } from "react-router-dom";
import { InvoiceType } from "types/enum/InvoiceType";
import InvoiceApi from "api/InvoiceApi";
const Invoice = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [targetMonth, setTargetMonth] = useState(new Date());
  const [selectSupplierIds, setSelectSupplierIds] = useState<string[]>([]);
  const supplierIds = useAppSelector((state) => state.supplier.result);
  const supplierEntities = useAppSelector(
    (state) => state.supplier.entities.suppliers
  );
  const suppliers = supplierIds.map((id) => supplierEntities[id]);

  const invoiceIds = useAppSelector((state) => state.invoice.result);
  const invoiceEntities = useAppSelector(
    (state) => state.invoice.entities.invoices
  );
  const invoices = invoiceIds.map((id) => invoiceEntities[id]);
  useEffect(() => {
    dispatch(fetchAllSupplier({ req: { limit: 1000, offset: 0 } }));
    const apiCall = async () => {
      const result = await InvoiceApi.count({
        limit: 0,
        offset: 0,
        targetMonth: DateTime.fromJSDate(targetMonth).toFormat(
          FORMAT_TYPE.YEAR_DAY
        ),
      });
      setTotalCount(result);
    };
    apiCall();
  }, [dispatch, targetMonth]);

  useEffect(() => {
    dispatch(
      fetchAllInvoice({
        req: {
          limit,
          offset,
          targetMonth: DateTime.fromJSDate(targetMonth).toFormat(
            FORMAT_TYPE.YEAR_DAY
          ),
        },
      })
    );
  }, [limit, offset]);
  const headers: { key: keyof InvoiceRes; label: string }[] = [
    { key: "supplierId", label: "請求先" },
    { key: "targetMonth", label: "対象月" },
    { key: "type", label: "区分" },
    { key: "subtotal", label: "小計" },
    { key: "tax", label: "消費税" },
    { key: "advancePaid", label: "経費(税込)" },
    { key: "total", label: "合計" },
    { key: "total", label: "" },
  ];

  const forms = [
    {
      label: "請求先名",
      key: "supplierId",
      type: FormType.AutoCompleteSelect,
      options: suppliers,
    },
    {
      label: "対象月",
      key: "targetMonth",
      type: FormType.MonthString,
    },
    {
      label: "請求書タイプ",
      key: "type",
      type: FormType.Select,
      options: Object.entries(InvoiceType).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
  ];

  const onClickSearch = async () => {
    dispatch(
      fetchAllInvoice({
        req: {
          limit,
          offset: 0,
          targetMonth: DateTime.fromJSDate(targetMonth).toFormat(
            FORMAT_TYPE.YEAR_DAY
          ),
          supplierIds: selectSupplierIds.includes("すべて")
            ? undefined
            : selectSupplierIds.map((id) => Number(id)),
        },
      })
    );
    const result = await InvoiceApi.count({
      limit: 0,
      offset: 0,
      targetMonth: DateTime.fromJSDate(targetMonth).toFormat(
        FORMAT_TYPE.YEAR_DAY
      ),
      supplierIds: selectSupplierIds.includes("すべて")
        ? undefined
        : selectSupplierIds.map((id) => Number(id)),
    });
    setTotalCount(result);
  };
  return (
    <Box display="flex" flexDirection="column">
      <Header
        targetMonth={targetMonth}
        setTargetMonth={setTargetMonth}
        selectedSupplierIds={selectSupplierIds}
        setSelectedSupplierIds={setSelectSupplierIds}
        onClickSearch={onClickSearch}
      />
      <InvoiceTable
        formId={"invoice"}
        data={invoices}
        fullWidth
        addFunc={(formData) => createInvoice({ req: formData })}
        addType={InvoiceReq}
        values={[
          (s) => s.supplier.name,
          (s) =>
            DateTime.fromFormat(s.targetMonth, FORMAT_TYPE.YEAR_DAY).toFormat(
              FORMAT_TYPE.YEAR_MONTH
            ),
          (s) => EnumUtils.mapToEnum(InvoiceType, s.type) || "",
          (s) =>
            s.subtotal ||
            s.invoiceItems.reduce(
              (sum, invoiceItem) => sum + invoiceItem.total,
              0
            ),
          (s) =>
            s.tax ||
            s.invoiceItems.reduce(
              (sum, invoiceItem) => sum + invoiceItem.total,
              0
            ) * 0.1,
          (s) => s.advancePaid || "",
          (s) =>
            Math.trunc(
              s.total ||
                s.invoiceItems.reduce(
                  (sum, invoiceItem) => sum + invoiceItem.total,
                  0
                ) * 1.1
            ),
          (s) => (
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(`/invoice/${s.id}`)}
            >
              詳細
            </Button>
          ),
        ]}
        limit={limit}
        setLimit={setLimit}
        offset={offset}
        setOffset={setOffset}
        totalCount={totalCount}
        deleteFunc={(formData) => deleteInvoice({ id: formData.id })}
        title={"請求"}
        rows={headers}
        forms={forms}
      />
    </Box>
  );
};

type HeaderProps = {
  targetMonth: Date;
  setTargetMonth: React.Dispatch<React.SetStateAction<Date>>;
  selectedSupplierIds: string[];
  setSelectedSupplierIds: React.Dispatch<React.SetStateAction<string[]>>;
  onClickSearch: () => Promise<void>;
};
const Header = ({
  targetMonth,
  setTargetMonth,
  selectedSupplierIds,
  setSelectedSupplierIds,
  onClickSearch,
}: HeaderProps) => {
  const supplierIds = useAppSelector((state) => state.supplier.result);
  const supplierEntities = useAppSelector(
    (state) => state.supplier.entities.suppliers
  );
  const suppliers = supplierIds.map((id) => supplierEntities[id]);

  useEffect(() => {
    setSelectedSupplierIds([
      ...suppliers.map((supplier) => String(supplier.id)),
      "すべて",
    ]);
  }, [supplierIds]);

  return (
    <Box display="flex" margin={2} alignItems={"flex-end"}>
      <Box marginLeft={1}>
        <TextField
          label={"対象月"}
          type={"month"}
          lang={"jaJP"}
          value={DateTime.fromJSDate(targetMonth).toFormat(
            FORMAT_TYPE.YEAR_MONTH
          )}
          onChange={(e) => {
            setTargetMonth(
              DateTime.fromFormat(
                e.target.value,
                FORMAT_TYPE.YEAR_MONTH
              ).toJSDate()
            );
          }}
        />
      </Box>
      <Box marginLeft={1}>
        <MultipleSelect
          selected={selectedSupplierIds}
          setSelected={setSelectedSupplierIds}
          id={"select-multiple-supplier"}
          options={suppliers}
          label={"請求先"}
        />
      </Box>
      <Box marginLeft={1}>
        <Button variant="contained" color="secondary" onClick={onClickSearch}>
          検索
        </Button>
      </Box>
    </Box>
  );
};

const InvoiceTable = styled<React.FC<CommonTableProps<InvoiceRes, InvoiceReq>>>(
  CommonTable
)`
  margin-top: 24px;
`;

export default Invoice;
