import BaseRes from "types/res/BaseRes";
import { Expose, Type } from "class-transformer";
import DealRes from "types/res/DealRes";

export default class SupplierRes extends BaseRes {
  @Expose() name!: string;
  @Expose() kana!: string;
  @Expose() invoiceNumber?: string;
  @Expose() tel?: string;
  @Expose() email?: string;
  @Expose() postCode?: string;
  @Expose() address?: string;
  @Type(() => Date)
  @Expose()
  registeredAt?: Date;
  @Expose() remarks?: string;
  @Type(() => DealRes)
  @Expose()
  deals?: DealRes[];
}
