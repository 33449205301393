import RequestUtils from "utils/RequestUtils";
import AccountRes from "types/res/AccountRes";
import AccountReq from "types/req/AccountReq";

export default class AccountApi {
  static async findOne(id: number) {
    return await RequestUtils.get(AccountRes, `/management/account/${id}`);
  }

  static async update(id: number, req: AccountReq) {
    return await RequestUtils.put(AccountRes, `/management/account/${id}`, req);
  }

  static async fileUpload(id: number, body: { file: string }) {
    return await RequestUtils.post(
      AccountRes,
      `/management/account/${id}/file-upload`,
      body
    );
  }
}
