import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAppSelector } from "redux/hooks";
import EnumUtils from "utils/EnumUtils";
import { BankAccountType } from "types/enum/BankAccountType";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { HonorificTitle } from "types/enum/HonorificTitle";
import ImageUtils from "utils/ImageUtils";

type Props = {
  setIsPdfRenderer: React.Dispatch<React.SetStateAction<boolean>>;
};
const IndividualDetailPdf = ({ setIsPdfRenderer }: Props) => {
  const detailEntity = useAppSelector((state) => state.detail.entities.details);
  const detailId = useAppSelector((state) => state.detail.result[0]);
  const detail = detailEntity[detailId];
  const account = useAppSelector((state) => state.auth.staff.account);
  const [image, setImage] = useState<string>("");

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#DDDDDD",
      color: theme.palette.common.black,
      border: "1px solid #707070",
      padding: "8px",
      fontSize: "24px",
    },
    [`&.${tableCellClasses.body}`]: {
      color: theme.palette.common.black,
      border: "1px solid #707070",
      padding: "8px",
      fontSize: "24px",
    },
  }));

  const StyledTypography = styled(Typography)`
    font-size: 24px;
  `;

  useEffect(() => {
    if (!account?.fileUrl) return;
    const apiCall = async () => {
      const result = await ImageUtils.toBase64ImageUrl(
        `https://tar-zan-file-upload.s3.ap-northeast-1.amazonaws.com/${account.fileUrl}` ||
          ""
      );
      setImage(result);
    };
    apiCall();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      pdfDocument();
    }, 3 * 1000);

    //クリーンアップ
    return () => {
      clearTimeout(timer);
    };
  });
  const pdfDocument = () => {
    const input = document.getElementById("detail-pdf");
    if (!input) return;
    const doc = new jsPDF({
      orientation: "p",
      format: "a4",
    });

    html2canvas(input, { scale: 2 }).then(function (canvas) {
      const dataURI = canvas.toDataURL("image/jpeg");
      const width = doc.internal.pageSize.width;
      doc.addImage(dataURI, "JPEG", 0, 0, width, 0);
      doc.save("download.pdf");
    });
    setIsPdfRenderer(false);
  };
  const rowsSpan =
    7 +
    (detail.betweenSubtotalAndTaxText && detail.betweenSubtotalAndTax ? 1 : 0) +
    (detail.betweenVehicleFeeAndTotalText1 && detail.betweenVehicleFeeAndTotal1
      ? 1
      : 0) +
    (detail.betweenVehicleFeeAndTotalText2 && detail.betweenVehicleFeeAndTotal2
      ? 1
      : 0) +
    (detail.betweenVehicleFeeAndTotalText3 && detail.betweenVehicleFeeAndTotal3
      ? 1
      : 0);

  const columnsSpan =
    9 + (detail.customize1 ? 1 : 0) + (detail.customize2 ? 1 : 0);
  const subtotal =
    detail.subtotal ||
    detail.detailItems.reduce((sum, item) => sum + item.total, 0);
  const tax = Math.trunc(
    detail.tax ||
      (detail.detailItems.reduce((sum, item) => sum + item.total, 0) -
        (detail.betweenSubtotalAndTax || 0)) *
        0.1
  );
  const total =
    detail.total ||
    subtotal -
      (detail.betweenSubtotalAndTax || 0) +
      tax +
      (detail.advancePaid || 0) -
      (detail.vehicleFee || 0) -
      (detail.betweenVehicleFeeAndTotal1 || 0) -
      (detail.betweenVehicleFeeAndTotal2 || 0) -
      (detail.betweenVehicleFeeAndTotal3 || 0);

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box
        padding={8}
        display={"flex"}
        flexDirection={"column"}
        id={"detail-pdf"}
      >
        <Box display="flex" flexDirection="column" margin={2}>
          <Box display="flex" justifyContent={"center"}>
            <Typography
              style={{ margin: "auto", fontSize: "48px", fontWeight: "bold" }}
            >
              {detail.title}
            </Typography>
          </Box>
          <Box display={"flex"} marginX={2} marginTop={1}>
            <StyledTypography
              style={{ fontSize: "48px", borderBottom: "2px solid" }}
            >
              合計金額{total.toLocaleString()}円
            </StyledTypography>
          </Box>
          <Box display={"flex"} margin={2} justifyContent={"space-between"}>
            <Box
              display={"flex"}
              flexDirection="column"
              marginLeft={3}
              marginTop={8}
            >
              <StyledTypography>
                {detail.driverPostCode || detail.driver.postCode
                  ? `〒${detail.driverPostCode || detail.driver.postCode}`
                  : ""}
              </StyledTypography>
              <StyledTypography>
                {detail.driverAddress || detail.driver.address}
              </StyledTypography>
              <StyledTypography style={{ fontSize: "36px" }}>
                {detail.driver.name}{" "}
                {EnumUtils.mapToEnum(HonorificTitle, detail.honorificTitle) ||
                  "様"}
              </StyledTypography>
              <StyledTypography>
                登録番号：
                {detail.driverInvoiceNumber ||
                  detail.driver?.invoiceNumber ||
                  "未設定"}
              </StyledTypography>
            </Box>
            <Box
              display={"flex"}
              flexDirection="column"
              marginLeft={3}
              marginTop={8}
            >
              <StyledTypography>振込先</StyledTypography>
              <StyledTypography>
                名義
                {detail.driverBankAccountName ||
                  detail.driver.bankAccountName ||
                  "未設定"}
              </StyledTypography>
              <StyledTypography>
                {detail.driverBankName || detail.driver.bankName || "未設定"}
              </StyledTypography>
              <StyledTypography>
                {EnumUtils.mapToEnum(
                  BankAccountType,
                  detail.driverBankAccountType || detail.driver.bankAccountType
                )}{" "}
                ){" "}
                {detail.driverBankAccountNumber ||
                  detail.driver.bankAccountNumber ||
                  "未設定"}
              </StyledTypography>
            </Box>
            <Box display={"flex"} alignItems={"flex-end"}>
              <Box display={"flex"} flexDirection="column" marginLeft={3}>
                <StyledTypography>支払い日</StyledTypography>
                <StyledTypography>{detail.payDate}</StyledTypography>
                <StyledTypography marginTop={2}>
                  {detail.accountName || detail.account.name}
                </StyledTypography>
                <StyledTypography>
                  {detail.accountPostCode || detail.account.postCode}
                </StyledTypography>
                <StyledTypography>
                  {detail.accountAddress || detail.account.address}
                </StyledTypography>
                <StyledTypography>
                  TEL：{detail.accountTel || detail.account.tel}　FAX：
                  {detail.accountFax || detail.account.fax}
                </StyledTypography>
              </Box>
              <Box marginLeft={1}>
                {account?.fileUrl && (
                  <img src={image} alt={""} width={100} height={100} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell variant={"head"} align={"center"}>
                <StyledTypography>日付</StyledTypography>
              </StyledTableCell>
              <StyledTableCell width={240} variant={"head"} align={"center"}>
                <StyledTypography>現場名</StyledTypography>
              </StyledTableCell>
              <StyledTableCell variant={"head"} align={"center"}>
                <StyledTypography>距離</StyledTypography>
              </StyledTableCell>
              <StyledTableCell variant={"head"} align={"center"}>
                <StyledTypography>持出個数①</StyledTypography>
              </StyledTableCell>
              <StyledTableCell variant={"head"} align={"center"}>
                <StyledTypography>持出個数②</StyledTypography>
              </StyledTableCell>
              <StyledTableCell variant={"head"} align={"center"}>
                <StyledTypography>配完個数①</StyledTypography>
              </StyledTableCell>
              <StyledTableCell variant={"head"} align={"center"}>
                <StyledTypography>配完個数②</StyledTypography>
              </StyledTableCell>
              <StyledTableCell variant={"head"} align={"center"}>
                <StyledTypography>持出件数</StyledTypography>
              </StyledTableCell>
              <StyledTableCell variant={"head"} align={"center"}>
                <StyledTypography>配完件数</StyledTypography>
              </StyledTableCell>
              <StyledTableCell variant={"head"} align={"center"}>
                <StyledTypography>残業</StyledTypography>
              </StyledTableCell>
              <StyledTableCell variant={"head"} align={"center"}>
                <StyledTypography>経費(税込)</StyledTypography>
              </StyledTableCell>
              {detail.customize1 && (
                <StyledTableCell variant={"head"} align={"center"}>
                  <StyledTypography>{detail.customize1}</StyledTypography>
                </StyledTableCell>
              )}
              {detail.customize2 && (
                <StyledTableCell variant={"head"} align={"center"}>
                  <StyledTypography>{detail.customize2}</StyledTypography>
                </StyledTableCell>
              )}
              <StyledTableCell variant={"head"}>
                <StyledTypography>金額</StyledTypography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {detail.detailItems.map((item, index) => (
              <TableRow key={item.id}>
                <StyledTableCell>
                  <StyledTypography>{item.date}</StyledTypography>
                </StyledTableCell>
                <StyledTableCell>
                  <StyledTypography>{item.deal.name}</StyledTypography>
                </StyledTableCell>
                <StyledTableCell align={"center"}>
                  <StyledTypography>
                    {item.distance || item.dailyReport?.distance}
                  </StyledTypography>
                </StyledTableCell>
                <StyledTableCell align={"center"}>
                  <StyledTypography>
                    {item.bringOut1 || item.dailyReport?.bringOut1 || ""}
                  </StyledTypography>
                </StyledTableCell>
                <StyledTableCell align={"center"}>
                  <StyledTypography>
                    {item.bringOut2 || item.dailyReport?.bringOut2 || ""}
                  </StyledTypography>
                </StyledTableCell>
                <StyledTableCell align={"center"}>
                  <StyledTypography>
                    {item.deliveryComplete1 ||
                      item.dailyReport?.deliveryComplete1 ||
                      ""}
                  </StyledTypography>
                </StyledTableCell>
                <StyledTableCell align={"center"}>
                  <StyledTypography>
                    {item.deliveryComplete2 ||
                      item.dailyReport?.deliveryComplete2 ||
                      ""}
                  </StyledTypography>
                </StyledTableCell>
                <StyledTableCell align={"center"}>
                  <StyledTypography>
                    {item.bringOutLocation ||
                      item.dailyReport?.bringOutLocation ||
                      ""}
                  </StyledTypography>
                </StyledTableCell>
                <StyledTableCell align={"center"}>
                  <StyledTypography>
                    {item.deliveryCompleteLocation ||
                      item.dailyReport?.deliveryCompleteLocation ||
                      ""}
                  </StyledTypography>
                </StyledTableCell>
                <StyledTableCell align={"center"}>
                  <StyledTypography>
                    {item.overTime || item.dailyReport?.overTime || ""}
                  </StyledTypography>
                </StyledTableCell>
                <StyledTableCell align={"right"}>
                  <StyledTypography>
                    {item.expenses?.toLocaleString() ||
                      item.dailyReport?.expenses?.toLocaleString() ||
                      ""}
                  </StyledTypography>
                </StyledTableCell>
                {detail.customize1 && (
                  <StyledTableCell align={"right"}>
                    <StyledTypography>
                      {item.customize1?.toLocaleString() || ""}
                    </StyledTypography>
                  </StyledTableCell>
                )}
                {detail.customize2 && (
                  <StyledTableCell
                    align={
                      isNaN(Number(item.customize2 || "")) ? "center" : "right"
                    }
                  >
                    <StyledTypography>
                      {isNaN(Number(item.customize2 || ""))
                        ? item.customize2
                        : item.customize2?.toLocaleString() || ""}
                    </StyledTypography>
                  </StyledTableCell>
                )}
                <StyledTableCell align={"right"}>
                  <StyledTypography>
                    {item.total?.toLocaleString()}
                  </StyledTypography>
                </StyledTableCell>
              </TableRow>
            ))}
            <TableRow>
              <StyledTableCell
                style={{ backgroundColor: "#DDDDDD" }}
                rowSpan={rowsSpan}
              >
                備考
              </StyledTableCell>
              <StyledTableCell rowSpan={rowsSpan} colSpan={columnsSpan}>
                <StyledTypography>{detail.remarks}</StyledTypography>
              </StyledTableCell>
              <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
                {detail.subtotalTitle}
              </StyledTableCell>
              <StyledTableCell align={"right"}>
                <StyledTypography>
                  {subtotal?.toLocaleString()}
                </StyledTypography>
              </StyledTableCell>
            </TableRow>
            {detail.betweenSubtotalAndTax &&
            detail.betweenSubtotalAndTaxText ? (
              <TableRow>
                <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
                  <StyledTypography>
                    {detail.betweenSubtotalAndTaxText}
                  </StyledTypography>
                </StyledTableCell>
                <StyledTableCell align={"right"}>
                  <StyledTypography>
                    {detail.betweenSubtotalAndTax?.toLocaleString()}
                  </StyledTypography>
                </StyledTableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
                {detail.taxTitle}
              </StyledTableCell>
              <StyledTableCell align={"right"}>
                <StyledTypography>{tax?.toLocaleString()}</StyledTypography>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
                {detail.advancePaidTitle}
              </StyledTableCell>
              <StyledTableCell align={"right"}>
                <StyledTypography>
                  {detail.advancePaid?.toLocaleString()}
                </StyledTypography>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
                {detail.vehicleFeeTitle}
              </StyledTableCell>
              <StyledTableCell align={"right"}>
                <StyledTypography>
                  {detail.vehicleFee?.toLocaleString()}
                </StyledTypography>
              </StyledTableCell>
            </TableRow>
            {detail.betweenVehicleFeeAndTotal1 &&
            detail.betweenVehicleFeeAndTotalText1 ? (
              <TableRow>
                <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
                  <StyledTypography>
                    {detail.betweenVehicleFeeAndTotalText1}
                  </StyledTypography>
                </StyledTableCell>
                <StyledTableCell>
                  <StyledTypography align={"right"}>
                    {detail.betweenVehicleFeeAndTotal1.toLocaleString()}
                  </StyledTypography>
                </StyledTableCell>
              </TableRow>
            ) : null}
            {detail.betweenVehicleFeeAndTotal2 &&
            detail.betweenVehicleFeeAndTotalText2 ? (
              <TableRow>
                <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
                  <StyledTypography>
                    {detail.betweenVehicleFeeAndTotalText2}
                  </StyledTypography>
                </StyledTableCell>
                <StyledTableCell>
                  <StyledTypography align={"right"}>
                    {detail.betweenVehicleFeeAndTotal2?.toLocaleString()}
                  </StyledTypography>
                </StyledTableCell>
              </TableRow>
            ) : null}
            {detail.betweenVehicleFeeAndTotal3 &&
            detail.betweenVehicleFeeAndTotalText3 ? (
              <TableRow>
                <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
                  <StyledTypography>
                    {detail.betweenVehicleFeeAndTotalText3}
                  </StyledTypography>
                </StyledTableCell>
                <StyledTableCell>
                  <StyledTypography align={"right"}>
                    {detail.betweenVehicleFeeAndTotal3?.toLocaleString()}
                  </StyledTypography>
                </StyledTableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
                {detail.totalTitle}
              </StyledTableCell>
              <StyledTableCell align={"right"}>
                <StyledTypography>{total.toLocaleString()}</StyledTypography>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default IndividualDetailPdf;
