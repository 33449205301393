import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "components/Modal";
import useModal from "hooks/useModal";
import FormField from "./FormField";
import { validate, ValidationError } from "class-validator";
import { Constructor } from "./CommonTable";
import { Box, Button, CircularProgress } from "@mui/material";
import { useAppDispatch } from "redux/hooks";

const configureErrorText = (error: string): string => {
  if (
    error.includes("should not be empty") ||
    error.includes("must be an array")
  ) {
    return "必須項目です。";
  }

  if (error.includes("must be shorter than or equal to 128 characters")) {
    return "128文字以内でご入力下さい。";
  }

  if (error.includes("must be shorter than or equal to 256 characters")) {
    return "256文字以内でご入力下さい。";
  }

  if (error.includes("must be an email")) {
    return "Eメールのフォーマットにして下さい。";
  }

  if (error.includes("must be shorter than or equal to 2048 characters")) {
    return "2048文字以内でご入力下さい。";
  }

  if (
    error.includes("must be a number conforming to the specified constraints")
  ) {
    return "数字をご入力下さい。";
  }

  if (error.includes("must be a boolean value")) {
    return "";
  }

  return error;
};

const configureErrors = (errors: ValidationError[]) => {
  const byProperty: any = {};
  errors.forEach((error) => {
    if (error.constraints) {
      const constraints = Object.values(error.constraints).map(
        configureErrorText
      );

      byProperty[error.property] = Object.values(constraints);
    }
  });

  return byProperty;
};

const FormContainer = styled.div`
  > * {
    margin-bottom: 1.4rem;
  }
`;

type Props<T> = {
  formId: string;
  title: string;
  forms: any[];
  addFunc: (a: T) => any;
  updateFunc?: (a: T) => any;
  addType?: Constructor<T>;
};

const FormModal = <T extends {}>({
  formId,
  title,
  forms,
  addFunc,
  updateFunc,
  addType,
}: Props<T>) => {
  const dispatch = useAppDispatch();
  const [showForm, setShowForm] = useModal(formId);
  const defaultValues = showForm?.meta?.item;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const formTitle = showForm?.meta?.type === "addData" ? title : "編集";
  useEffect(() => {
    const next = defaultValues ? { ...defaultValues } : {};
    if (showForm?.meta?.type !== "addData") {
      forms
        .filter((form) => Boolean(form.value))
        .forEach((form) => {
          if (defaultValues?.[form.key]) {
            next[form.key] = form.value(defaultValues[form.key]);
          }
        });
    }
    setFormData(next);
  }, [JSON.stringify(defaultValues), forms.sort().join()]);

  const updateInfo = async (func: any) => {
    setLoading(true);
    await func();
    setFormData({});
    setLoading(false);
    setShowForm(false);
  };

  const onClickAddButton = async () => {
    if (!addType) return;
    const data = Object.assign(new addType(), { ...formData });
    const errors = await validate(data);
    if (errors.length > 0) {
      setErrors(configureErrors(errors));
    } else {
      await updateInfo(() => dispatch(addFunc(data)));
      setErrors({});
    }
  };

  const onClickUpdateButton = async () => {
    if (!addType || !updateFunc) return;
    const data = Object.assign(new addType(), { ...formData });
    const errors = await validate(data);
    if (errors.length > 0) {
      setErrors(configureErrors(errors));
    } else {
      await updateInfo(() => dispatch(updateFunc(data)));
      setErrors({});
    }
  };

  const onClick =
    showForm?.meta?.type === "addData" ? onClickAddButton : onClickUpdateButton;
  const handleSetShowForm = (show: boolean) => {
    setShowForm(show);
    setFormData({});
  };

  return (
    <Modal show={showForm?.show} setShow={handleSetShowForm} title={formTitle}>
      <FormContainer>
        {forms
          .filter(
            (form) =>
              !(form.key === "password" && showForm?.meta?.type === "editData")
          )
          .map((form) => {
            return (
              <Box marginTop={1}>
                <FormField
                  key={form.key}
                  label={form.label}
                  formData={formData}
                  setFormData={setFormData}
                  formKey={form.key}
                  formType={form.type}
                  options={form.options}
                  disabled={form.disabled}
                  unSelectOptions={form.unSelectOptions}
                  error={errors?.[form.key]}
                  loadMoreResults={form.loadMoreResults}
                  optionFilterKey={form.optionFilterKey}
                  optionFilterFunc={form.optionFilterFunc}
                />
              </Box>
            );
          })}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          onClick={onClick}
        >
          {loading ? <CircularProgress color="inherit" size={24} /> : "決定"}
        </Button>
      </FormContainer>
    </Modal>
  );
};

export default FormModal;
