import { IsNotEmpty, IsString } from "class-validator";
import { StaffRole } from "types/enum/StaffRole";

export default class StaffReq {
  @IsNotEmpty({
    message: "未入力です",
  })
  @IsString()
  name!: string;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsString()
  email!: string;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsString()
  tel!: string;

  @IsNotEmpty({
    message: "未入力です",
  })
  role!: StaffRole;

  constructor(arg: {
    name: string;
    email: string;
    tel: string;
    role: StaffRole;
  }) {
    arg = arg || {};
    this.name = arg.name;
    this.email = arg.email;
    this.tel = arg.tel;
    this.role = arg.role;
  }
}
