import RequestUtils from "utils/RequestUtils";
import DailyReportRes from "types/res/DailyReportRes";
import DailyReportReq from "types/req/DailyReportReq";
import SearchDailyReportReq from "types/req/search/SearchDailyReportReq";
import DailyReportSummaryRes from "types/res/DailyReportSummaryRes";

export default class DailyReportApi {
  static async findAll(body: SearchDailyReportReq) {
    return await RequestUtils.postArray(
      DailyReportRes,
      `/management/daily-report/find-all`,
      body
    );
  }

  static async count(body: SearchDailyReportReq) {
    return await RequestUtils.postCount(`/management/daily-report/count`, body);
  }

  static async summarySales(body: SearchDailyReportReq) {
    return await RequestUtils.post(
      DailyReportSummaryRes,
      `/management/daily-report/summary-sales`,
      body
    );
  }

  static async create(req: DailyReportReq) {
    return await RequestUtils.post(
      DailyReportRes,
      `/management/daily-report`,
      req
    );
  }

  static async update(id: number, req: DailyReportReq) {
    return await RequestUtils.put(
      DailyReportRes,
      `/management/daily-report/${id}`,
      req
    );
  }

  static async delete(id: number) {
    return await RequestUtils.delete(`/management/daily-report/${id}`);
  }
}
