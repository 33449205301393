import RequestUtils from "utils/RequestUtils";
import DriverRes from "types/res/DriverRes";
import DriverReq from "types/req/DriverReq";
import SearchDriverReq from "types/req/search/SearchDriverReq";

export default class DriverApi {
  static async findAll(body: SearchDriverReq) {
    return await RequestUtils.postArray(
      DriverRes,
      `/management/driver/find-all`,
      body
    );
  }

  static async count(body: SearchDriverReq) {
    return await RequestUtils.postCount(`/management/driver/count`, body);
  }

  static async create(req: DriverReq) {
    return await RequestUtils.post(DriverRes, `/management/driver`, req);
  }

  static async update(id: number, req: DriverReq) {
    return await RequestUtils.put(DriverRes, `/management/driver/${id}`, req);
  }

  static async delete(id: number) {
    return await RequestUtils.delete(`/management/driver/${id}`);
  }
}
