const Spec = {
  maxLength: {
    account: {
      name: 256,
      invoiceNumber: 14,
      postCode: 8,
      address: 1024,
      email: 256,
      tel: 11,
      fax: 11,
      bankName: 128,
      bankAccountName: 128,
      bankAccountNumber: 7,
    },
    staff: {
      name: 256,
      email: 256,
      tel: 11,
    },
    driver: {
      name: 256,
      kana: 256,
      invoiceNumber: 14,
      tel: 11,
      email: 256,
      postCode: 8,
      address: 2048,
      remarks: 65535,
      bankName: 128,
      bankAccountName: 128,
      bankAccountNumber: 7,
    },
    supplier: {
      name: 256,
      kana: 256,
      siteName: 256,
      siteKana: 256,
      invoiceNumber: 14,
      tel: 11,
      postCode: 8,
      email: 256,
      address: 2048,
      remarks: 65535,
    },
  },
};
export default Spec;
