import AccountRes from "types/res/AccountRes";
import { createSlice } from "@reduxjs/toolkit";
import { fetchOneAccount, updateAccount } from "redux/actions/account";

export type AccountState = {
  entities: { accounts: { [key: number]: AccountRes } };
  result: number[];
};
const initialState: AccountState = { entities: { accounts: {} }, result: [] };

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOneAccount.fulfilled, (_, { payload }) => ({
      entities: { accounts: { ...payload.entities.accounts } },
      result: [payload.result],
    }));
    builder.addCase(updateAccount.fulfilled, (state, { payload }) => ({
      entities: {
        accounts: {
          ...state.entities.accounts,
          ...payload.entities.accounts,
        },
      },
      result: state.result,
    }));
  },
});

export default accountSlice.reducer;
