import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { fetchAllDriver } from "redux/actions/driver";
import { useParams } from "react-router-dom";
import { fetchDetail, updateDetail } from "redux/actions/detail";
import EnumUtils from "utils/EnumUtils";
import { styled } from "@mui/material/styles";
import { BankAccountType } from "types/enum/BankAccountType";
import DetailRes from "types/res/DetailRes";
import DetailItemApi from "api/DetailItemApi";
import DetailItemReq from "types/req/DetailItemReq";
import useModal from "hooks/useModal";
import Modal from "components/Modal";
import IndividualDetailPdf from "pages/IndividualDetail/pdf";
import IndividualDetailMail from "pages/IndividualDetail/mail";
import { fetchAllDeal } from "redux/actions/deal";
import { HonorificTitle } from "types/enum/HonorificTitle";
import DetailApi from "api/DetailApi";
import { toast } from "react-toastify";
const IndividualDetail = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [targetMonth, setTargetMonth] = useState(new Date());
  const [selectDriverIds, setSelectDriverIds] = useState<string[]>([]);
  const [isPdfRender, setIsPdfRender] = useState(false);
  const [isSendMail, setIsSendMail] = useState(false);
  const detailEntity = useAppSelector((state) => state.detail.entities.details);
  const detailId = useAppSelector((state) => state.detail.result[0]);
  const detail = detailEntity[detailId];
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatch(fetchAllDriver({ req: { limit: 100, offset: 0 } }));
    dispatch(fetchAllDeal({ req: { limit: 1000, offset: 0 } }));
  }, [dispatch]);

  useEffect(() => {
    if (!id) return;
    const apiCall = async () => {
      setIsLoading(true);
      await dispatch(fetchDetail({ id: Number(id) }));
      setIsLoading(false);
    };
    apiCall();
  }, [id]);

  if (!detail) return <></>;

  const printDocument = () => {
    setIsPdfRender(true);
  };

  const onClickSendMail = async () => {
    setIsSendMail(true);
  };
  if (isLoading) {
    return (
      <Box display={"flex"} justifyContent={"center"} marginTop={3}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      <Header
        detail={detail}
        targetMonth={targetMonth}
        setTargetMonth={setTargetMonth}
        selectedDriverIds={selectDriverIds}
        setSelectedDriverIds={setSelectDriverIds}
        printDocument={printDocument}
        isPdfRender={isPdfRender}
        isSendMail={isSendMail}
        onClickSendMail={onClickSendMail}
      />
      <DetailTable />
      {isPdfRender && <IndividualDetailPdf setIsPdfRenderer={setIsPdfRender} />}
      {isSendMail && <IndividualDetailMail setIsSendMail={setIsSendMail} />}
    </Box>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#DDDDDD",
    color: theme.palette.common.black,
    border: "1px solid #707070",
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.common.black,
    border: "1px solid #707070",
    padding: "8px",
  },
}));
const StyledTextFieldAtNumber = styled(TextField)(({ theme }) => ({
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
}));

const DetailTable = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const dealEntities = useAppSelector((state) => state.deal.entities.deals);
  const dealIds = useAppSelector((state) => state.deal.result);
  const detailEntity = useAppSelector((state) => state.detail.entities.details);
  const detailId = useAppSelector((state) => state.detail.result[0]);
  const detail = detailEntity[detailId];
  const deals = dealIds.map((id) => dealEntities[id]);
  const [changeDetail, setChangeDetail] = useState<DetailRes>(detail);
  const [prevDetail, setPrevDetail] = useState<DetailRes>();
  const [newDetailItems, setNewDetailItems] = useState<
    {
      date?: string;
      dealId?: number;
      distance?: number;
      bringOut1?: number;
      bringOut2?: number;
      deliveryComplete1?: number;
      deliveryComplete2?: number;
      bringOutLocation?: number;
      deliveryCompleteLocation?: number;
      overTime?: number;
      expenses?: number;
      customize1?: number;
      customize2?: string;
      total?: number;
    }[]
  >(
    [...Array(10)].map(() => ({
      date: undefined,
      dealId: undefined,
      distance: undefined,
      bringOut1: undefined,
      bringOut2: undefined,
      deliveryComplete1: undefined,
      deliveryComplete2: undefined,
      bringOutLocation: undefined,
      deliveryCompleteLocation: undefined,
      overTime: undefined,
      expenses: undefined,
      customize1: undefined,
      customize2: undefined,
      total: undefined,
    }))
  );
  const [updateDetailItems, setUpdateDetailItems] = useState(
    detail.detailItems
  );
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    setUpdateDetailItems(detail.detailItems);
    setChangeDetail(detail);
  }, [detailId]);

  useEffect(() => {
    if (!detail) return;
    const apiCall = async () => {
      const result = await DetailApi.findPrevData(
        detail.driverId,
        detail.targetMonth
      );
      setPrevDetail(result);
    };
    apiCall();
  }, [detail]);

  useEffect(() => {
    if (!newDetailItems.length || !updateDetailItems.length) return;
    const subtotal = changeDetail.subtotal
      ? changeDetail.subtotal
      : newDetailItems.reduce(
          (sum, item) => sum + (Number(item.total) || 0),
          0
        ) +
        updateDetailItems.reduce(
          (sum, item) => sum + (Number(item.total) || 0),
          0
        );
    const advancePaid = changeDetail.advancePaid
      ? changeDetail.advancePaid
      : newDetailItems.reduce(
          (sum, item) => sum + (Number(item.expenses) || 0),
          0
        ) +
        updateDetailItems.reduce(
          (sum, item) => sum + (Number(item.expenses) || 0),
          0
        );
    setChangeDetail((prev) => ({
      ...prev,
      subtotal,
      advancePaid,
      tax: changeDetail.tax
        ? changeDetail.tax
        : Math.trunc(
            (subtotal - (changeDetail.betweenSubtotalAndTax || 0)) * 0.1
          ),
      total: changeDetail.total
        ? changeDetail.total
        : Math.trunc(
            (subtotal - (changeDetail.betweenSubtotalAndTax || 0)) * 1.1 -
              (changeDetail.betweenSubtotalAndTax || 0) +
              advancePaid -
              (prev.vehicleFee || 0) +
              (Number(changeDetail.betweenSubtotalAndTax) || 0) +
              (Number(changeDetail.betweenVehicleFeeAndTotal1) || 0) +
              (Number(changeDetail.betweenVehicleFeeAndTotal2) || 0) +
              (Number(changeDetail.betweenVehicleFeeAndTotal3) || 0)
          ),
    }));
  }, [newDetailItems, updateDetailItems, changeDetail]);

  const onClickEditNewDetailItem = (index: number, key: string, value: any) => {
    setNewDetailItems((prev) => {
      const newDetailItems: any = [...prev];
      newDetailItems[index][key] = value;
      return newDetailItems;
    });
  };

  const onClickEditUpdateDetailItem = (
    index: number,
    key: string,
    value: any
  ) => {
    setUpdateDetailItems((prev) => {
      const updateDetailItems: any = [...prev];
      updateDetailItems[index][key] = value;
      return updateDetailItems;
    });
  };

  const onClickReset = () => {
    setNewDetailItems(
      [...Array(10)].map(() => ({
        date: undefined,
        supplierId: undefined,
        distance: undefined,
        bringOut1: undefined,
        bringOut2: undefined,
        deliveryComplete1: undefined,
        deliveryComplete2: undefined,
        bringOutLocation: undefined,
        deliveryCompleteLocation: undefined,
        overTime: undefined,
        expenses: undefined,
        customize1: undefined,
        customize2: undefined,
        total: undefined,
      }))
    );
    setUpdateDetailItems(detail.detailItems);
  };

  const onClickSave = async () => {
    setErrors([]);
    updateDetailItems.forEach((updateDetailItem, index) => {
      const error = [];
      if (!updateDetailItem.date) {
        error.push("日付");
      }
      if (!updateDetailItem.dealId) {
        error.push("現場名");
      }
      if (!updateDetailItem.total) {
        error.push("金額");
      }
      if (error.length) {
        const text = `${index + 1}行目の${error.join("、")}が未入力です。`;
        setErrors((prev) => [...prev, text]);
      }
    });
    newDetailItems.forEach((newDetailItem, index) => {
      if (Object.values(newDetailItem).every((value) => value === undefined))
        return;
      const error = [];
      if (!newDetailItem.date) {
        error.push("日付");
      }
      if (!newDetailItem.dealId) {
        error.push("現場名");
      }
      if (!newDetailItem.total) {
        error.push("金額");
      }
      if (error.length) {
        const text = `${index + 1 + updateDetailItems.length}行目の${error.join(
          "、"
        )}が未入力です。`;
        setErrors((prev) => [...prev, text]);
      }
    });
    if (errors.length) return;
    const newReqs = newDetailItems
      .filter(
        (newDetailItem) =>
          newDetailItem.total && newDetailItem.dealId && newDetailItem.date
      )
      .map(
        (newDetailItem) => new DetailItemReq({ ...newDetailItem, detailId })
      );
    const updateReqs = updateDetailItems.map(
      (updateDetailItem: any) => new DetailItemReq({ ...updateDetailItem })
    );
    await DetailItemApi.create(newReqs);
    await DetailItemApi.update(updateReqs);
    dispatch(
      updateDetail({
        id: detailId,
        req: {
          ...changeDetail,
          subtotalTitle:
            changeDetail.subtotalTitle || prevDetail?.subtotalTitle,
          taxTitle: changeDetail.taxTitle || prevDetail?.taxTitle,
          advancePaidTitle:
            changeDetail.advancePaidTitle || prevDetail?.advancePaidTitle,
          vehicleFeeTitle:
            changeDetail.vehicleFeeTitle || prevDetail?.vehicleFeeTitle,
          totalTitle: changeDetail.totalTitle || prevDetail?.totalTitle,
          betweenSubtotalAndTaxText:
            changeDetail.betweenSubtotalAndTaxText ||
            prevDetail?.betweenSubtotalAndTaxText,
          betweenVehicleFeeAndTotalText1:
            changeDetail.betweenVehicleFeeAndTotalText1 ||
            prevDetail?.betweenVehicleFeeAndTotalText1,
          betweenVehicleFeeAndTotalText2:
            changeDetail.betweenVehicleFeeAndTotalText2 ||
            prevDetail?.betweenVehicleFeeAndTotalText2,
          betweenVehicleFeeAndTotalText3:
            changeDetail.betweenVehicleFeeAndTotalText3 ||
            prevDetail?.betweenVehicleFeeAndTotalText3,
          betweenSubtotalAndTax:
            changeDetail.betweenSubtotalAndTax !== undefined
              ? Number(changeDetail.betweenSubtotalAndTax)
              : undefined,
          betweenVehicleFeeAndTotal1:
            changeDetail.betweenVehicleFeeAndTotal1 !== undefined
              ? Number(changeDetail.betweenVehicleFeeAndTotal1)
              : undefined,
          betweenVehicleFeeAndTotal2:
            changeDetail.betweenVehicleFeeAndTotal2 !== undefined
              ? Number(changeDetail.betweenVehicleFeeAndTotal2)
              : undefined,
          betweenVehicleFeeAndTotal3:
            changeDetail.betweenVehicleFeeAndTotal3 !== undefined
              ? Number(changeDetail.betweenVehicleFeeAndTotal3)
              : undefined,
          vehicleFee:
            changeDetail.vehicleFee !== undefined
              ? Number(changeDetail.vehicleFee)
              : undefined,
        },
      })
    );
    dispatch(fetchDetail({ id: Number(id) }));
    toast("保存しました");
  };

  const onChangeDetail = (key: string, value: any) => {
    setChangeDetail((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Box margin={2} display={"flex"} flexDirection={"column"}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>日付</Typography>
            </StyledTableCell>
            <StyledTableCell width={240} variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>現場名</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>距離</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>持出個数①</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>持出個数②</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>配完個数①</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>配完個数②</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>持出件数</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>配完件数</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>残業</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>経費(税込)</Typography>
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeDetail.customize1 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeDetail("customize1", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeDetail.customize2 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeDetail("customize2", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>金額</Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {updateDetailItems.map((item, index) => (
            <TableRow key={item.id}>
              <StyledTableCell>
                <TextField
                  type={"date"}
                  size={"small"}
                  value={item.date || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateDetailItem(
                      index,
                      "date",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <Select
                  size={"small"}
                  variant={"standard"}
                  style={{ width: "220px" }}
                  value={item.dealId || ""}
                  onChange={(event) => {
                    onClickEditUpdateDetailItem(
                      index,
                      "dealId",
                      event.target.value
                    );
                  }}
                >
                  {deals.map((deal) => (
                    <MenuItem key={deal.id} value={deal.id}>
                      {deal.name}
                    </MenuItem>
                  ))}
                </Select>
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.distance || item.dailyReport?.distance || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateDetailItem(
                      index,
                      "distance",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.bringOut1 || item.dailyReport?.bringOut1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateDetailItem(
                      index,
                      "bringOut1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.bringOut2 || item.dailyReport?.bringOut2 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateDetailItem(
                      index,
                      "bringOut2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={
                    item.deliveryComplete1 ||
                    item.dailyReport?.deliveryComplete1 ||
                    ""
                  }
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateDetailItem(
                      index,
                      "deliveryComplete1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={
                    item.deliveryComplete2 ||
                    item.dailyReport?.deliveryComplete2 ||
                    ""
                  }
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateDetailItem(
                      index,
                      "deliveryComplete2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={
                    item.bringOutLocation ||
                    item.dailyReport?.bringOutLocation ||
                    ""
                  }
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateDetailItem(
                      index,
                      "bringOutLocation",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={
                    item.deliveryCompleteLocation ||
                    item.dailyReport?.deliveryCompleteLocation ||
                    ""
                  }
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateDetailItem(
                      index,
                      "deliveryCompleteLocation",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.overTime || item.dailyReport?.overTime || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateDetailItem(
                      index,
                      "overTime",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.expenses ?? item.dailyReport?.expenses ?? ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateDetailItem(
                      index,
                      "expenses",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateDetailItem(
                      index,
                      "customize1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  size={"small"}
                  variant={"standard"}
                  value={item.customize2 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateDetailItem(
                      index,
                      "customize2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "160px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.total || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateDetailItem(
                      index,
                      "total",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
            </TableRow>
          ))}
          {newDetailItems.map((newDetailItem, index) => (
            <TableRow key={index}>
              <StyledTableCell>
                <TextField
                  type={"date"}
                  size={"small"}
                  value={newDetailItem.date || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewDetailItem(index, "date", event.target.value);
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <Select
                  size={"small"}
                  style={{ width: "220px" }}
                  value={newDetailItem.dealId || ""}
                  onChange={(event) => {
                    onClickEditNewDetailItem(
                      index,
                      "dealId",
                      event.target.value
                    );
                  }}
                >
                  {deals.map((deal) => (
                    <MenuItem key={deal.id} value={deal.id}>
                      {deal.name}
                    </MenuItem>
                  ))}
                </Select>
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newDetailItem.distance || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewDetailItem(
                      index,
                      "distance",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newDetailItem.bringOut1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewDetailItem(
                      index,
                      "bringOut1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewDetailItem(
                      index,
                      "bringOut2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newDetailItem.deliveryComplete1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewDetailItem(
                      index,
                      "deliveryComplete1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newDetailItem.deliveryComplete2 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewDetailItem(
                      index,
                      "deliveryComplete2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newDetailItem.bringOutLocation || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewDetailItem(
                      index,
                      "bringOutLocation",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newDetailItem.deliveryCompleteLocation || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewDetailItem(
                      index,
                      "deliveryCompleteLocation",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newDetailItem.overTime || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewDetailItem(
                      index,
                      "overTime",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newDetailItem.expenses || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewDetailItem(
                      index,
                      "expenses",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newDetailItem.customize1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewDetailItem(
                      index,
                      "customize1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  size={"small"}
                  variant={"standard"}
                  value={newDetailItem.customize2 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewDetailItem(
                      index,
                      "customize2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "160px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newDetailItem.total || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewDetailItem(
                      index,
                      "total",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
            </TableRow>
          ))}
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }} rowSpan={9}>
              備考
            </StyledTableCell>
            <StyledTableCell rowSpan={9} colSpan={11}>
              <TextareaAutosize
                minRows={25}
                style={{ width: "100%" }}
                value={changeDetail.remarks || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeDetail("remarks", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={
                  changeDetail.subtotalTitle || prevDetail?.subtotalTitle || ""
                }
                onChange={(event) => {
                  event.persist();
                  onChangeDetail("subtotalTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeDetail.subtotal || ""}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeDetail("subtotal", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={
                  changeDetail.betweenSubtotalAndTaxText ||
                  prevDetail?.betweenSubtotalAndTaxText ||
                  ""
                }
                onChange={(event) => {
                  event.persist();
                  onChangeDetail(
                    "betweenSubtotalAndTaxText",
                    event.target.value
                  );
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <TextField
                size={"small"}
                variant={"standard"}
                type={"number"}
                value={changeDetail.betweenSubtotalAndTax || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeDetail("betweenSubtotalAndTax", event.target.value);
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeDetail.taxTitle || prevDetail?.taxTitle || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeDetail("taxTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeDetail.tax || ""}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeDetail("tax", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={
                  changeDetail.advancePaidTitle ||
                  prevDetail?.advancePaidTitle ||
                  ""
                }
                onChange={(event) => {
                  event.persist();
                  onChangeDetail("advancePaidTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeDetail.advancePaid || ""}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeDetail("advancePaid", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={
                  changeDetail.vehicleFeeTitle ||
                  prevDetail?.vehicleFeeTitle ||
                  ""
                }
                onChange={(event) => {
                  event.persist();
                  onChangeDetail("vehicleFeeTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeDetail.vehicleFee || ""}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeDetail("vehicleFee", event.target.value);
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={
                  changeDetail.betweenVehicleFeeAndTotalText1 ||
                  prevDetail?.betweenVehicleFeeAndTotalText1 ||
                  ""
                }
                onChange={(event) => {
                  event.persist();
                  onChangeDetail(
                    "betweenVehicleFeeAndTotalText1",
                    event.target.value
                  );
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <TextField
                size={"small"}
                variant={"standard"}
                type={"number"}
                value={changeDetail.betweenVehicleFeeAndTotal1 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeDetail(
                    "betweenVehicleFeeAndTotal1",
                    event.target.value
                  );
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={
                  changeDetail.betweenVehicleFeeAndTotalText2 ||
                  prevDetail?.betweenVehicleFeeAndTotalText2 ||
                  ""
                }
                onChange={(event) => {
                  event.persist();
                  onChangeDetail(
                    "betweenVehicleFeeAndTotalText2",
                    event.target.value
                  );
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <TextField
                size={"small"}
                variant={"standard"}
                type={"number"}
                value={changeDetail.betweenVehicleFeeAndTotal2 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeDetail(
                    "betweenVehicleFeeAndTotal2",
                    event.target.value
                  );
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={
                  changeDetail.betweenVehicleFeeAndTotalText3 ||
                  prevDetail?.betweenVehicleFeeAndTotalText3 ||
                  ""
                }
                onChange={(event) => {
                  event.persist();
                  onChangeDetail(
                    "betweenVehicleFeeAndTotalText3",
                    event.target.value
                  );
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <TextField
                size={"small"}
                variant={"standard"}
                type={"number"}
                value={changeDetail.betweenVehicleFeeAndTotal3 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeDetail(
                    "betweenVehicleFeeAndTotal3",
                    event.target.value
                  );
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeDetail.totalTitle || prevDetail?.totalTitle || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeDetail("totalTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeDetail.total || ""}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeDetail("total", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
      {errors.length ? (
        <Box
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          marginY={5}
        >
          {errors.map((error) => (
            <Typography color={"red"}>{error}</Typography>
          ))}
        </Box>
      ) : (
        <></>
      )}
      <Box display="flex" justifyContent="center" marginY={5}>
        <Button
          variant="contained"
          color={"inherit"}
          onClick={() => onClickReset()}
        >
          リセット
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{ marginLeft: "16px" }}
          onClick={() => onClickSave()}
        >
          保存
        </Button>
      </Box>
    </Box>
  );
};

type HeaderProps = {
  detail: DetailRes;
  targetMonth: Date;
  setTargetMonth: React.Dispatch<React.SetStateAction<Date>>;
  selectedDriverIds: string[];
  setSelectedDriverIds: React.Dispatch<React.SetStateAction<string[]>>;
  printDocument: () => void;
  isPdfRender: boolean;
  isSendMail: boolean;
  onClickSendMail: () => void;
};
const Header = ({
  detail,
  targetMonth,
  setTargetMonth,
  selectedDriverIds,
  setSelectedDriverIds,
  printDocument,
  isPdfRender,
  isSendMail,
  onClickSendMail,
}: HeaderProps) => {
  const [updateModal, setUpdateModal] = useModal("detailUpdate");
  const account = useAppSelector((state) => state.auth.staff.account);

  const driverIds = useAppSelector((state) => state.driver.result);
  const driverEntities = useAppSelector(
    (state) => state.driver.entities.drivers
  );
  const drivers = driverIds.map((id) => driverEntities[id]);

  useEffect(() => {
    setSelectedDriverIds([
      ...drivers.map((driver) => String(driver.id)),
      "すべて",
    ]);
  }, [driverIds]);

  return (
    <>
      <Box display="flex" flexDirection="column" margin={2}>
        <Box display="flex" alignItems={"flex-end"}>
          <Typography style={{ margin: "auto" }} variant={"h4"}>
            {detail.title}
          </Typography>
        </Box>
        <Box display={"flex"} margin={2} justifyContent={"space-between"}>
          <Typography>明細詳細</Typography>
          <Box
            display={"flex"}
            flexDirection="column"
            marginLeft={3}
            marginTop={8}
          >
            <Typography>
              {detail.driverPostCode || detail.driver.postCode
                ? `〒${detail.driverPostCode || detail.driver.postCode}`
                : ""}
            </Typography>
            <Typography>
              {detail.driverAddress || detail.driver.address}
            </Typography>
            <Typography>
              {detail.driver.name}
              {"　"}
              {EnumUtils.mapToEnum(HonorificTitle, detail.honorificTitle) ||
                "様"}
            </Typography>
            <Typography>
              登録番号：
              {detail.driverInvoiceNumber ||
                detail.driver?.invoiceNumber ||
                "未設定"}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection="column"
            marginLeft={3}
            marginTop={8}
          >
            <Typography>振込先</Typography>
            <Typography>
              名義　
              {detail.driverBankAccountName ||
                detail.driver.bankAccountName ||
                "未設定"}
            </Typography>
            <Typography>
              {detail.driverBankName || detail.driver.bankName || "未設定"}
            </Typography>
            <Typography>
              {EnumUtils.mapToEnum(
                BankAccountType,
                detail.driverBankAccountType || detail.driver.bankAccountType
              )}{" "}
              ){" "}
              {detail.driverBankAccountNumber ||
                detail.driver.bankAccountNumber ||
                "未設定"}
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"flex-end"}>
            <Box display={"flex"} flexDirection="column" marginLeft={3}>
              <Typography>支払い日</Typography>
              <Typography>{detail.payDate}</Typography>
              <Typography marginTop={2}>
                {detail.accountName || detail.account.name}
              </Typography>
              <Typography>
                {detail.accountPostCode || detail.account.postCode}
              </Typography>
              <Typography>
                {detail.accountAddress || detail.account.address}
              </Typography>
              <Typography>
                TEL：{detail.accountTel || detail.account.tel}　FAX：
                {detail.accountFax || detail.account.fax}
              </Typography>
            </Box>
            <Box marginLeft={1}>
              {account?.fileUrl && (
                <img
                  src={
                    `https://tar-zan-file-upload.s3.ap-northeast-1.amazonaws.com/${account.fileUrl}` ||
                    ""
                  }
                  alt={""}
                  width={100}
                  height={100}
                />
              )}
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection="column"
            marginLeft={3}
            marginTop={8}
          >
            <Button
              variant="contained"
              color="info"
              disabled={isSendMail}
              onClick={() => onClickSendMail()}
            >
              メール添付
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => printDocument()}
              disabled={isPdfRender}
              style={{ marginTop: "10px" }}
            >
              PDFダウンロード
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "10px" }}
              onClick={() => setUpdateModal(true, {})}
            >
              上部項目編集
            </Button>
          </Box>
        </Box>
      </Box>
      {updateModal.show && (
        <UpdateDetailModal
          updateModal={updateModal}
          setUpdateModal={setUpdateModal}
        />
      )}
    </>
  );
};
type ModalProps = {
  updateModal: { show: boolean; meta?: any };
  setUpdateModal: any;
};
const UpdateDetailModal = ({ updateModal, setUpdateModal }: ModalProps) => {
  const dispatch = useAppDispatch();
  const detailEntity = useAppSelector((state) => state.detail.entities.details);
  const detailId = useAppSelector((state) => state.detail.result[0]);
  const detail = detailEntity[detailId];
  const driverIds = useAppSelector((state) => state.driver.result);
  const driverEntities = useAppSelector(
    (state) => state.driver.entities.drivers
  );
  const drivers = driverIds.map((id) => driverEntities[id]);
  const [updateDetailReq, setUpdateDetail] = useState(detail);

  const onChangeUpdateDetail = (key: string, value: any) => {
    setUpdateDetail((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onClickUpdateDetail = () => {
    dispatch(updateDetail({ id: detailId, req: updateDetailReq }));
    setUpdateModal(false, {});
  };

  useEffect(() => {
    setUpdateDetail((prev) => ({
      ...prev,
      driverInvoiceNumber:
        prev.driverInvoiceNumber || prev.driver?.invoiceNumber || "",
      driverPostCode: prev.driverPostCode || prev.driver?.postCode || "",
      driverAddress: prev.driverAddress || prev.driver?.address || "",
      driverBankAccountName:
        prev.driverBankAccountName || prev.driver?.bankAccountName || "",
      driverBankName: prev.driverBankName || prev.driver?.bankName || "",
      driverBankAccountType:
        prev.driverBankAccountType || prev.driver?.bankAccountType,
      driverBankAccountNumber:
        prev.driverBankAccountNumber || prev.driver?.bankAccountNumber || "",
      accountName: prev.accountName || prev.account?.name || "",
      accountPostCode: prev.accountPostCode || prev.account?.postCode || "",
      accountAddress: prev.accountAddress || prev.account?.address || "",
      accountTel: prev.accountTel || prev.account?.tel || "",
      accountFax: prev.accountFax || prev.account?.fax || "",
    }));
  }, []);

  return (
    <Modal
      show={updateModal.show}
      setShow={setUpdateModal}
      title={"明細情報編集"}
    >
      <Box display={"flex"} flexDirection={"column"}>
        <Typography variant={"h5"}>明細宛先情報</Typography>
        <Autocomplete
          style={{ marginTop: "16px" }}
          options={drivers}
          value={updateDetailReq.driver}
          getOptionLabel={(option) => option.name || ""}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={"ドライバー名"} />
          )}
          onChange={(event, value) => {
            event.persist();
            onChangeUpdateDetail("driver", value);
            onChangeUpdateDetail("driverId", value?.id || "");
          }}
          noOptionsText="選択肢がありません"
        />
        <TextField
          select
          label="敬称"
          style={{ marginTop: "16px" }}
          value={updateDetailReq.honorificTitle || ""}
          onChange={(event) => {
            onChangeUpdateDetail("honorificTitle", event.target.value);
          }}
        >
          {Object.entries(HonorificTitle).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label={"登録番号"}
          style={{ marginTop: "16px" }}
          value={updateDetailReq.driverInvoiceNumber || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateDetail("driverInvoiceNumber", event.target.value);
          }}
        />
        <TextField
          label={"郵便番号"}
          style={{ marginTop: "16px" }}
          value={updateDetailReq.driverPostCode || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateDetail("driverPostCode", event.target.value);
          }}
        />
        <TextField
          label={"住所"}
          style={{ marginTop: "16px" }}
          value={updateDetailReq.driverAddress || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateDetail("driverAddress", event.target.value);
          }}
        />
        <TextField
          type={"date"}
          label={"お支払い日"}
          style={{ marginTop: "16px" }}
          value={updateDetailReq.payDate || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateDetail("payDate", event.target.value);
          }}
        />
        <TextField
          label={"振込先名義"}
          style={{ marginTop: "16px" }}
          value={updateDetailReq.driverBankAccountName || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateDetail("driverBankAccountName", event.target.value);
          }}
        />
        <TextField
          label={"振込先銀行"}
          style={{ marginTop: "16px" }}
          value={updateDetailReq.driverBankName || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateDetail("driverBankName", event.target.value);
          }}
        />
        <TextField
          select
          label="口座種別"
          style={{ marginTop: "16px" }}
          value={updateDetailReq.driverBankAccountType || ""}
          onChange={(event) => {
            onChangeUpdateDetail("driverBankAccountType", event.target.value);
          }}
        >
          {Object.entries(BankAccountType).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label={"口座番号"}
          style={{ marginTop: "16px" }}
          value={updateDetailReq.driverBankAccountNumber || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateDetail("driverBankAccountNumber", event.target.value);
          }}
        />
        <Typography variant={"h5"} style={{ marginTop: "32px" }}>
          払出し会社情報
        </Typography>
        <TextField
          label={"払出会社名"}
          style={{ marginTop: "16px" }}
          value={updateDetailReq.accountName || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateDetail("accountName", event.target.value);
          }}
        />
        <TextField
          label={"郵便番号"}
          style={{ marginTop: "16px" }}
          value={updateDetailReq.accountPostCode || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateDetail("accountPostCode", event.target.value);
          }}
        />
        <TextField
          label={"住所"}
          style={{ marginTop: "16px" }}
          value={updateDetailReq.accountAddress || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateDetail("accountAddress", event.target.value);
          }}
        />
        <TextField
          label={"電話番号"}
          style={{ marginTop: "16px" }}
          value={updateDetailReq.accountTel || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateDetail("accountTel", event.target.value);
          }}
        />
        <TextField
          label={"FAX"}
          style={{ marginTop: "16px" }}
          value={updateDetailReq.accountFax || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateDetail("accountFax", event.target.value);
          }}
        />
        <Button
          variant={"contained"}
          color={"primary"}
          style={{ marginTop: "16px" }}
          onClick={() => onClickUpdateDetail()}
        >
          保存
        </Button>
      </Box>
    </Modal>
  );
};
export default IndividualDetail;
