import SchemaUtils from "utils/SchemaUtils";
import AccountRes from "types/res/AccountRes";
import { normalize } from "normalizr";
import { createAsyncThunk } from "@reduxjs/toolkit";
import AccountApi from "api/AccountApi";
import AccountReq from "types/req/AccountReq";

const accountSchema = SchemaUtils.createEntity<AccountRes>("accounts");
type schemaType = { accounts: { [key: number]: AccountRes } };

export const fetchOneAccount = createAsyncThunk(
  "fetchOneAccount",
  async ({ id }: { id: number }) => {
    const data = await AccountApi.findOne(id);
    return normalize<undefined, schemaType, number>(data, accountSchema);
  }
);

export const updateAccount = createAsyncThunk(
  "updateAccount",
  async ({ id, req }: { id: number; req: AccountReq }) => {
    const data = await AccountApi.update(id, req);
    return normalize<undefined, schemaType, number>(data, accountSchema);
  }
);

export const fileUpload = createAsyncThunk(
  "fileUpload",
  async ({ id, body }: { id: number; body: { file: string } }) => {
    return await AccountApi.fileUpload(id, body);
  }
);
