import React, { useState } from "react";
import useModal from "hooks/useModal";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import FormModal from "components/FormModal";
import DeleteModal from "components/DeleteModal";
export type Constructor<T> = new (...args: any[]) => T;

export type CommonTableProps<T, S> = {
  title: string;
  rows: { key: keyof T; label: string }[];
  formId: string;
  forms: any[];
  data: T[];
  values: ((item: T) => string | number | React.JSX.Element)[];
  addFunc: (a: any) => any;
  updateFunc?: (a: any) => any;
  deleteFunc: (a: any) => any;
  addType?: Constructor<S>;
  fullWidth?: boolean;
  disablePagination?: boolean;
  defaultValue?: any;
  isLoading?: boolean;
  totalCount?: number;
  limit?: number;
  offset?: number;
  setLimit?: React.Dispatch<React.SetStateAction<number>>;
  setOffset?: React.Dispatch<React.SetStateAction<number>>;
  onClickCsvDownload?: () => any;
};
const CommonTable = <
  T extends {
    //  no
  },
  S extends {
    //no
  }
>({
  title,
  formId,
  forms = [],
  rows,
  data,
  values,
  addFunc,
  updateFunc,
  deleteFunc,
  addType,
  fullWidth,
  defaultValue,
  isLoading,
  totalCount,
  limit,
  offset,
  setLimit,
  setOffset,
  onClickCsvDownload,
}: CommonTableProps<T, S>): React.JSX.Element => {
  const [, setShowForm] = useModal(formId);
  const [, setDeleteShow] = useModal("deleteData");
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<keyof T>();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const createSortHandler =
    (property: keyof T) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const onClickAddButton = () => () => {
    setShowForm(true, { type: "addData", item: defaultValue });
  };

  const onClickEditButton = (type: string, item: any) => () => {
    setShowForm(true, { type: "editData", item });
  };

  const onClickDeleteButton = (type: string, item: any) => () => {
    setDeleteShow(true, { item });
  };

  function stableSort<V>(array: V[], comparator: (a: V, b: V) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [V, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator<Key extends keyof any>(
    order: "asc" | "desc",
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    if (setOffset) {
      setOffset(newPage);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (setLimit && setOffset) {
      setLimit(parseInt(event.target.value, 10));
      setOffset(0);
    }
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // @ts-ignore
  return (
    <>
      <Container
        // maxWidth={fullWidth ? false : "xl"}
        maxWidth={false}
        style={{ padding: "0 5px", marginTop: "20px" }}
      >
        <Box display="flex" alignItems="center">
          <Box p={1} flexGrow={1}>
            <Typography variant="h5">{title}一覧</Typography>
          </Box>
          {addType && (
            <Box p={1}>
              <Button
                variant="contained"
                color="secondary"
                onClick={onClickAddButton()}
              >
                追加
              </Button>
            </Box>
          )}
          {onClickCsvDownload && (
            <Box p={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => await onClickCsvDownload()}
              >
                CSVダウンロード
              </Button>
            </Box>
          )}
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {rows.map((row, i) => (
                  <TableCell
                    key={i}
                    sortDirection={orderBy === row.key ? order : false}
                    padding={"checkbox"}
                  >
                    <TableSortLabel
                      active={orderBy === row.key}
                      direction={orderBy === row.key ? order : "asc"}
                      onClick={createSortHandler(row.key)}
                    >
                      <Typography style={{ fontSize: "12px" }} noWrap>
                        {row.label}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                ))}
                {!!updateFunc && <TableCell padding="checkbox" />}
                <TableCell padding="checkbox" />
              </TableRow>
            </TableHead>
            {isLoading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <TableBody>
                {stableSort(data, getComparator(order, orderBy as string)).map(
                  (item: any, i: number) => {
                    return (
                      <TableRow key={i}>
                        {values.map((value, i) => (
                          <TableCell
                            key={i}
                            component="th"
                            scope="row"
                            padding={"checkbox"}
                          >
                            <Typography
                              style={{
                                fontSize: "12px",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {value(item)}
                            </Typography>
                          </TableCell>
                        ))}
                        {!!updateFunc && (
                          <TableCell padding="checkbox">
                            <IconButton
                              onClick={onClickEditButton("editData", item)}
                            >
                              <Edit />
                            </IconButton>
                          </TableCell>
                        )}
                        <TableCell padding="checkbox">
                          <IconButton
                            onClick={onClickDeleteButton("deleteData", item)}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[100, 300, 500]}
          component="div"
          count={totalCount || data.length}
          rowsPerPage={limit || rowsPerPage}
          page={offset || page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="ページ毎件数"
        />
      </Container>
      <FormModal<S>
        title={title + "追加"}
        formId={formId}
        forms={forms}
        addFunc={addFunc}
        updateFunc={updateFunc}
        addType={addType}
      />
      <DeleteModal deleteFunc={deleteFunc} />
    </>
  );
};

export default CommonTable;
