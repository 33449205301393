import DailyReportRes from "types/res/DailyReportRes";
import { createSlice } from "@reduxjs/toolkit";
import {
  createDailyReport,
  deleteDailyReport,
  fetchAllDailyReport,
  updateDailyReport,
} from "redux/actions/dailyReport";

export type DailyReportState = {
  entities: { dailyReports: { [key: number]: DailyReportRes } };
  result: number[];
};
const initialState: DailyReportState = {
  entities: { dailyReports: {} },
  result: [],
};

const dailyReportSlice = createSlice({
  name: "dailyReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllDailyReport.fulfilled, (_, { payload }) => ({
      entities: { dailyReports: { ...payload.entities.dailyReports } },
      result: payload.result,
    }));
    builder.addCase(createDailyReport.fulfilled, (state, { payload }) => ({
      entities: {
        dailyReports: {
          ...state.entities.dailyReports,
          ...payload.entities.dailyReports,
        },
      },
      result: [...state.result, payload.result],
    }));
    builder.addCase(updateDailyReport.fulfilled, (state, { payload }) => ({
      entities: {
        dailyReports: {
          ...state.entities.dailyReports,
          ...payload.entities.dailyReports,
        },
      },
      result: state.result,
    }));
    builder.addCase(deleteDailyReport.fulfilled, (state, { payload }) => {
      const entities = { ...state.entities.dailyReports };
      delete entities[payload];
      return {
        entities: {
          dailyReports: {
            ...entities,
          },
        },
        result: state.result.filter((id) => id !== payload),
      };
    });
  },
});

export default dailyReportSlice.reducer;
