import React, { JSX, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  Autocomplete,
  Box,
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { fetchAllSupplier } from "redux/actions/supplier";
import { useParams } from "react-router-dom";
import { fetchInvoice, updateInvoice } from "redux/actions/invoice";
import EnumUtils from "utils/EnumUtils";
import { styled } from "@mui/material/styles";
import { BankAccountType } from "types/enum/BankAccountType";
import InvoiceRes from "types/res/InvoiceRes";
import InvoiceItemApi from "api/InvoiceItemApi";
import InvoiceItemReq from "types/req/InvoiceItemReq";
import useModal from "hooks/useModal";
import Modal from "components/Modal";
import IndividualInvoicePdf from "pages/IndividualInvoice/pdf";
import IndividualInvoiceMail from "pages/IndividualInvoice/mail";
import { toast } from "react-toastify";
import { HonorificTitle } from "types/enum/HonorificTitle";
import InvoiceApi from "api/InvoiceApi";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
const IndividualInvoice = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [targetMonth, setTargetMonth] = useState(new Date());
  const [selectSupplierIds, setSelectSupplierIds] = useState<string[]>([]);
  const [isPdfRender, setIsPdfRender] = useState(false);
  const [isSendMail, setIsSendMail] = useState(false);
  const invoiceEntity = useAppSelector(
    (state) => state.invoice.entities.invoices
  );
  const invoiceId = useAppSelector((state) => state.invoice.result[0]);
  const invoice = invoiceEntity[invoiceId];
  const [prevInvoice, setPrevInvoice] = useState<InvoiceRes>();

  useEffect(() => {
    dispatch(fetchAllSupplier({ req: { limit: 1000, offset: 0 } }));
  }, [dispatch]);

  useEffect(() => {
    if (!id) return;
    dispatch(fetchInvoice({ id: Number(id) }));
  }, [id]);

  useEffect(() => {
    if (!invoice) return;
    const apiCall = async () => {
      const result = await InvoiceApi.findPrevData(
        invoice.supplierId,
        DateTime.fromFormat(invoice.targetMonth, FORMAT_TYPE.YEAR_DAY).toFormat(
          FORMAT_TYPE.YEAR_MONTH
        )
      );
      setPrevInvoice(result);
    };
    apiCall();
  }, [invoice]);

  if (!invoice) return <></>;

  const printDocument = () => {
    setIsPdfRender(true);
  };

  const onClickSendMail = async () => {
    setIsSendMail(true);
  };

  const TableComponent: { [key: string]: JSX.Element } = {
    car: <CarTable prevInvoice={prevInvoice} />,
    distance: <DistanceTable prevInvoice={prevInvoice} />,
    piece: <PieceTable prevInvoice={prevInvoice} />,
    location: <LocationTable prevInvoice={prevInvoice} />,
    free: <FreeTable prevInvoice={prevInvoice} />,
  };

  return (
    <Box display="flex" flexDirection="column">
      <Header
        invoice={invoice}
        targetMonth={targetMonth}
        setTargetMonth={setTargetMonth}
        selectedSupplierIds={selectSupplierIds}
        setSelectedSupplierIds={setSelectSupplierIds}
        printDocument={printDocument}
        isPdfRender={isPdfRender}
        isSendMail={isSendMail}
        onClickSendMail={onClickSendMail}
      />
      <Box margin={"0 auto"}>{TableComponent[invoice.type]}</Box>
      {isPdfRender && (
        <IndividualInvoicePdf setIsPdfRenderer={setIsPdfRender} />
      )}
      {isSendMail && <IndividualInvoiceMail setIsSendMail={setIsSendMail} />}
    </Box>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#DDDDDD",
    color: theme.palette.common.black,
    border: "1px solid #707070",
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.common.black,
    border: "1px solid #707070",
    padding: "8px",
  },
}));
const StyledTextFieldAtNumber = styled(TextField)(({ theme }) => ({
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
}));

type TableProp = {
  prevInvoice?: InvoiceRes;
};
const CarTable = ({ prevInvoice }: TableProp) => {
  const dispatch = useAppDispatch();
  const invoiceEntity = useAppSelector(
    (state) => state.invoice.entities.invoices
  );
  const invoiceId = useAppSelector((state) => state.invoice.result[0]);
  const invoice = invoiceEntity[invoiceId];
  const [changeInvoice, setChangeInvoice] = useState<InvoiceRes>(invoice);
  const [newInvoiceItems, setNewInvoiceItems] = useState<
    {
      text?: string;
      dateNum?: number;
      fare?: number;
      customize1?: number;
      customize2?: number;
      customize3?: number;
      total?: number;
    }[]
  >(
    [...Array(30 - invoice.invoiceItems.length)].map(() => ({
      text: undefined,
      dateNum: undefined,
      fare: undefined,
      customize1: undefined,
      customize2: undefined,
      customize3: undefined,
      total: undefined,
    }))
  );
  const [updateInvoiceItems, setUpdateInvoiceItems] = useState(
    invoice.invoiceItems
  );
  const [errors, setErrors] = useState<string[]>([]);
  useEffect(() => {
    setUpdateInvoiceItems(invoice.invoiceItems);
    setChangeInvoice(invoice);
  }, []);

  useEffect(() => {
    const subtotal =
      newInvoiceItems.reduce(
        (sum, item) => sum + (Number(item.total) || 0),
        0
      ) +
      updateInvoiceItems.reduce(
        (sum, item) => sum + (Number(item.total) || 0),
        0
      );
    setChangeInvoice((prev) => ({
      ...prev,
      subtotal: changeInvoice.subtotal ? changeInvoice.subtotal : subtotal,
      tax: changeInvoice.tax ? changeInvoice.tax : Math.trunc(subtotal * 0.1),
      total: changeInvoice.total
        ? changeInvoice.total
        : Math.trunc(subtotal * 1.1 + Number(prev.advancePaid || 0)),
    }));
  }, [newInvoiceItems, updateInvoiceItems, changeInvoice]);
  const onClickEditNewInvoiceItem = (
    index: number,
    key: string,
    value: any
  ) => {
    setNewInvoiceItems((prev) => {
      const newInvoiceItems: any = [...prev];
      newInvoiceItems[index][key] = value;
      return newInvoiceItems;
    });
  };

  const onClickEditUpdateInvoiceItem = (
    index: number,
    key: string,
    value: any
  ) => {
    setUpdateInvoiceItems((prev) => {
      const updateInvoiceItems: any = [...prev];
      updateInvoiceItems[index][key] = value;
      return updateInvoiceItems;
    });
  };

  const onClickReset = () => {
    setNewInvoiceItems(
      [...Array(30 - invoice.invoiceItems.length)].map(() => ({
        text: undefined,
        dateNum: undefined,
        fare: undefined,
        customize1: undefined,
        customize2: undefined,
        customize3: undefined,
        total: undefined,
      }))
    );
    setUpdateInvoiceItems(invoice.invoiceItems);
  };

  const checkDiff = () => {
    return (
      JSON.stringify(invoice) === JSON.stringify(changeInvoice) &&
      JSON.stringify(invoice.invoiceItems) ===
        JSON.stringify(updateInvoiceItems) &&
      newInvoiceItems.every((newInvoiceItem) =>
        Object.values(newInvoiceItem).every((value) => value === undefined)
      )
    );
  };

  const onClickSave = async () => {
    setErrors([]);
    updateInvoiceItems.forEach((updateInvoiceItem, index) => {
      const error = [];
      if (!updateInvoiceItem.dateNum) {
        error.push("日数");
      }
      if (!updateInvoiceItem.text) {
        error.push("項目");
      }
      if (!updateInvoiceItem.total) {
        error.push("金額");
      }
      if (error.length) {
        const text = `${index + 1}行目の${error.join("、")}が未入力です。`;
        setErrors((prev) => [...prev, text]);
      }
    });
    newInvoiceItems.forEach((newInvoiceItem, index) => {
      if (Object.values(newInvoiceItem).every((value) => value === undefined))
        return;
      const error = [];
      if (!newInvoiceItem.dateNum) {
        error.push("日数");
      }
      if (!newInvoiceItem.text) {
        error.push("項目");
      }
      if (!newInvoiceItem.total) {
        error.push("金額");
      }
      if (error.length) {
        const text = `${
          index + 1 + updateInvoiceItems.length
        }行目の${error.join("、")}が未入力です。`;
        setErrors((prev) => [...prev, text]);
      }
    });
    if (errors.length) return;
    const newReqs = newInvoiceItems
      .filter(
        (newInvoiceItem) =>
          newInvoiceItem.total && newInvoiceItem.text && newInvoiceItem.dateNum
      )
      .map(
        (newInvoiceItem) => new InvoiceItemReq({ ...newInvoiceItem, invoiceId })
      );
    const updateReqs = updateInvoiceItems.map(
      (updateInvoiceItem: any) => new InvoiceItemReq({ ...updateInvoiceItem })
    );
    await InvoiceItemApi.create(newReqs);
    await InvoiceItemApi.update(updateReqs);
    await dispatch(
      updateInvoice({
        id: invoiceId,
        req: {
          ...changeInvoice,
        },
      })
    );
    await dispatch(fetchInvoice({ id: Number(invoiceId) }));
    toast("保存しました");
  };

  const onChangeInvoice = (key: string, value: any) => {
    setChangeInvoice((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Box margin={2} display={"flex"} flexDirection={"column"}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell width={240} variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>項目</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>日数</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>運賃</Typography>
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize1 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize1", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize2 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize2", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize3 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize3", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>金額</Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {updateInvoiceItems.map((item, index) => (
            <TableRow key={item.id}>
              <StyledTableCell>
                <TextField
                  style={{ width: "220px" }}
                  size={"small"}
                  variant={"standard"}
                  value={item.text}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "text",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.dateNum || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "dateNum",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.fare || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "fare",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize2 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize3 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize3",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "160px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.total}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "total",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
            </TableRow>
          ))}
          {newInvoiceItems.map((newInvoiceItem, index) => (
            <TableRow key={index}>
              <StyledTableCell>
                <TextField
                  size={"small"}
                  variant={"standard"}
                  style={{ width: "220px" }}
                  value={newInvoiceItem.text || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "text",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.dateNum || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "dateNum",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.fare || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "fare",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize2 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize3 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize3",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "160px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.total || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "total",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
            </TableRow>
          ))}
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }} rowSpan={9}>
              備考
            </StyledTableCell>
            <StyledTableCell rowSpan={9} colSpan={4}>
              <TextareaAutosize
                minRows={25}
                style={{ width: "100%" }}
                value={changeInvoice.remarks || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("remarks", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={
                  changeInvoice.subtotalTitle ||
                  prevInvoice?.subtotalTitle ||
                  ""
                }
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("subtotalTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.subtotal}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("subtotal", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.taxTitle || prevInvoice?.taxTitle || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("taxTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.tax}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("tax", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={
                  changeInvoice.advancePaidTitle ||
                  prevInvoice?.advancePaidTitle ||
                  ""
                }
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("advancePaidTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.advancePaid || ""}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("advancePaid", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={
                  changeInvoice.totalTitle || prevInvoice?.totalTitle || ""
                }
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("totalTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.total}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("total", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
      {errors.length ? (
        <Box
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          marginY={5}
        >
          {errors.map((error) => (
            <Typography color={"red"}>{error}</Typography>
          ))}
        </Box>
      ) : (
        <></>
      )}
      <Box display="flex" justifyContent="center" marginY={5}>
        <Button
          variant="contained"
          color={"inherit"}
          onClick={() => onClickReset()}
        >
          リセット
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{ marginLeft: "16px" }}
          onClick={() => onClickSave()}
        >
          保存
        </Button>
      </Box>
    </Box>
  );
};

const DistanceTable = ({ prevInvoice }: TableProp) => {
  const dispatch = useAppDispatch();
  const invoiceEntity = useAppSelector(
    (state) => state.invoice.entities.invoices
  );
  const invoiceId = useAppSelector((state) => state.invoice.result[0]);
  const invoice = invoiceEntity[invoiceId];
  const [changeInvoice, setChangeInvoice] = useState<InvoiceRes>(invoice);

  const [newInvoiceItems, setNewInvoiceItems] = useState<
    {
      date?: string;
      text?: string;
      distance?: number;
      distanceUnitPrice?: number;
      distanceOverTime?: number;
      distanceOverTimeUnitPrice?: number;
      customize1?: number;
      customize2?: number;
      customize3?: number;
      total?: number;
    }[]
  >(
    [...Array(30 - invoice.invoiceItems.length)].map(() => ({
      date: undefined,
      text: undefined,
      distance: undefined,
      distanceUnitPrice: undefined,
      distanceOverTime: undefined,
      distanceOverTimeUnitPrice: undefined,
      customize1: undefined,
      customize2: undefined,
      customize3: undefined,
      total: undefined,
    }))
  );
  const [updateInvoiceItems, setUpdateInvoiceItems] = useState(
    invoice.invoiceItems
  );
  const [errors, setErrors] = useState<string[]>([]);
  useEffect(() => {
    setUpdateInvoiceItems(invoice.invoiceItems);
    setChangeInvoice(invoice);
  }, []);

  useEffect(() => {
    const subtotal =
      newInvoiceItems.reduce(
        (sum, item) => sum + (Number(item.total) || 0),
        0
      ) +
      updateInvoiceItems.reduce(
        (sum, item) => sum + (Number(item.total) || 0),
        0
      );
    setChangeInvoice((prev) => ({
      ...prev,
      subtotal,
      tax: Math.trunc(subtotal * 0.1),
      total: Math.trunc(subtotal * 1.1 + (prev.advancePaid || 0)),
    }));
  }, [newInvoiceItems, updateInvoiceItems, changeInvoice]);
  const onClickEditNewInvoiceItem = (
    index: number,
    key: string,
    value: any
  ) => {
    setNewInvoiceItems((prev) => {
      const newInvoiceItems: any = [...prev];
      newInvoiceItems[index][key] = value;
      return newInvoiceItems;
    });
  };

  const onClickEditUpdateInvoiceItem = (
    index: number,
    key: string,
    value: any
  ) => {
    setUpdateInvoiceItems((prev) => {
      const updateInvoiceItems: any = [...prev];
      updateInvoiceItems[index][key] = value;
      return updateInvoiceItems;
    });
  };

  const onClickReset = () => {
    setNewInvoiceItems(
      [...Array(30 - invoice.invoiceItems.length)].map(() => ({
        date: undefined,
        text: undefined,
        distance: undefined,
        distanceUnitPrice: undefined,
        distanceOverTime: undefined,
        distanceOverTimeUnitPrice: undefined,
        customize1: undefined,
        customize2: undefined,
        customize3: undefined,
        total: undefined,
      }))
    );
    setUpdateInvoiceItems(invoice.invoiceItems);
  };

  const checkDiff = () => {
    return (
      JSON.stringify(invoice) === JSON.stringify(changeInvoice) &&
      JSON.stringify(invoice.invoiceItems) ===
        JSON.stringify(updateInvoiceItems) &&
      newInvoiceItems.every((newInvoiceItem) =>
        Object.values(newInvoiceItem).every((value) => value === undefined)
      )
    );
  };

  const onClickSave = async () => {
    setErrors([]);
    updateInvoiceItems.forEach((updateInvoiceItem, index) => {
      const error = [];
      if (!updateInvoiceItem.date) {
        error.push("日付");
      }
      if (!updateInvoiceItem.text) {
        error.push("項目");
      }
      if (!updateInvoiceItem.total) {
        error.push("金額");
      }
      if (error.length) {
        const text = `${index + 1}行目の${error.join("、")}が未入力です。`;
        setErrors((prev) => [...prev, text]);
      }
    });
    newInvoiceItems.forEach((newInvoiceItem, index) => {
      if (Object.values(newInvoiceItem).every((value) => value === undefined))
        return;
      const error = [];
      if (!newInvoiceItem.date) {
        error.push("日付");
      }
      if (!newInvoiceItem.text) {
        error.push("項目");
      }
      if (!newInvoiceItem.total) {
        error.push("金額");
      }
      if (error.length) {
        const text = `${
          index + 1 + updateInvoiceItems.length
        }行目の${error.join("、")}が未入力です。`;
        setErrors((prev) => [...prev, text]);
      }
    });
    if (errors.length) return;
    const newReqs = newInvoiceItems
      .filter(
        (newInvoiceItem) =>
          newInvoiceItem.total && newInvoiceItem.text && newInvoiceItem.date
      )
      .map(
        (newInvoiceItem) => new InvoiceItemReq({ ...newInvoiceItem, invoiceId })
      );
    const updateReqs = updateInvoiceItems.map(
      (updateInvoiceItem: any) => new InvoiceItemReq({ ...updateInvoiceItem })
    );
    await InvoiceItemApi.create(newReqs);
    await InvoiceItemApi.update(updateReqs);
    await dispatch(
      updateInvoice({
        id: invoiceId,
        req: {
          ...changeInvoice,
        },
      })
    );
    await dispatch(fetchInvoice({ id: Number(invoiceId) }));
    toast("保存しました");
  };

  const onChangeInvoice = (key: string, value: any) => {
    setChangeInvoice((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Box margin={2} display={"flex"} flexDirection={"column"}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>日付</Typography>
            </StyledTableCell>
            <StyledTableCell width={240} variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>項目</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>距離</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>単価</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>距離増</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>距離増単価</Typography>
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize1 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize1", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize2 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize2", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize3 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize3", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>金額</Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {updateInvoiceItems.map((item, index) => (
            <TableRow key={item.id}>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="date"
                  size={"small"}
                  variant={"standard"}
                  value={item.date || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "date",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  style={{ width: "220px" }}
                  size={"small"}
                  variant={"standard"}
                  value={item.text || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "text",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.distance || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "distance",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.distanceUnitPrice || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "distanceUnitPrice",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.distanceOverTime || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "distanceOverTime",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.distanceOverTimeUnitPrice || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "distanceOverTimeUnitPrice",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize2 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize3 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize3",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "160px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.total}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "total",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
            </TableRow>
          ))}
          {newInvoiceItems.map((newInvoiceItem, index) => (
            <TableRow key={index}>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="date"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.date || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "dateNum",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  size={"small"}
                  variant={"standard"}
                  style={{ width: "220px" }}
                  value={newInvoiceItem.text || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "text",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.distance || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "distance",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.distanceUnitPrice || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "distanceUnitPrice",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.distanceOverTime || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "distanceOverTime",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.distanceOverTimeUnitPrice || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "distanceOverTimeUnitPrice",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize2 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize3 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize3",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "160px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.total || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "total",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
            </TableRow>
          ))}
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }} rowSpan={9}>
              備考
            </StyledTableCell>
            <StyledTableCell rowSpan={9} colSpan={7}>
              <TextareaAutosize
                minRows={25}
                style={{ width: "100%" }}
                value={changeInvoice.remarks || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("remarks", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              小計
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.subtotal}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("subtotal", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.taxTitle || prevInvoice?.taxTitle || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("taxTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.tax}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("tax", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={
                  changeInvoice.subtotalTitle ||
                  prevInvoice?.subtotalTitle ||
                  ""
                }
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("subtotalTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.advancePaid || ""}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("advancePaid", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={
                  changeInvoice.totalTitle || prevInvoice?.totalTitle || ""
                }
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("totalTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.total}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("total", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
      {errors.length ? (
        <Box
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          marginY={5}
        >
          {errors.map((error) => (
            <Typography color={"red"}>{error}</Typography>
          ))}
        </Box>
      ) : (
        <></>
      )}
      <Box display="flex" justifyContent="center" marginY={5}>
        <Button
          variant="contained"
          color={"inherit"}
          onClick={() => onClickReset()}
        >
          リセット
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{ marginLeft: "16px" }}
          onClick={() => onClickSave()}
        >
          保存
        </Button>
      </Box>
    </Box>
  );
};

const PieceTable = ({ prevInvoice }: TableProp) => {
  const dispatch = useAppDispatch();
  const invoiceEntity = useAppSelector(
    (state) => state.invoice.entities.invoices
  );
  const invoiceId = useAppSelector((state) => state.invoice.result[0]);
  const invoice = invoiceEntity[invoiceId];
  const [changeInvoice, setChangeInvoice] = useState<InvoiceRes>(invoice);
  const [newInvoiceItems, setNewInvoiceItems] = useState<
    {
      date?: string;
      text?: string;
      quantity1?: number;
      quantityUnitPrice1?: number;
      quantity2?: number;
      quantityUnitPrice2?: number;
      customize1?: number;
      customize2?: number;
      customize3?: number;
      total?: number;
    }[]
  >(
    [...Array(30 - invoice.invoiceItems.length)].map(() => ({
      date: undefined,
      text: undefined,
      quantity1: undefined,
      quantityUnitPrice1: undefined,
      quantity2: undefined,
      quantityUnitPrice2: undefined,
      customize1: undefined,
      customize2: undefined,
      customize3: undefined,
      total: undefined,
    }))
  );
  const [updateInvoiceItems, setUpdateInvoiceItems] = useState(
    invoice.invoiceItems
  );
  const [errors, setErrors] = useState<string[]>([]);
  useEffect(() => {
    setUpdateInvoiceItems(invoice.invoiceItems);
    setChangeInvoice(invoice);
  }, []);

  useEffect(() => {
    const subtotal =
      newInvoiceItems.reduce(
        (sum, item) => sum + (Number(item.total) || 0),
        0
      ) +
      updateInvoiceItems.reduce(
        (sum, item) => sum + (Number(item.total) || 0),
        0
      );
    setChangeInvoice((prev) => ({
      ...prev,
      subtotal,
      tax: Math.trunc(subtotal * 0.1),
      total: Math.trunc(subtotal * 1.1 + (prev.advancePaid || 0)),
    }));
  }, [newInvoiceItems, updateInvoiceItems, changeInvoice]);
  const onClickEditNewInvoiceItem = (
    index: number,
    key: string,
    value: any
  ) => {
    setNewInvoiceItems((prev) => {
      const newInvoiceItems: any = [...prev];
      newInvoiceItems[index][key] = value;
      return newInvoiceItems;
    });
  };

  const onClickEditUpdateInvoiceItem = (
    index: number,
    key: string,
    value: any
  ) => {
    setUpdateInvoiceItems((prev) => {
      const updateInvoiceItems: any = [...prev];
      updateInvoiceItems[index][key] = value;
      return updateInvoiceItems;
    });
  };

  const onClickReset = () => {
    setNewInvoiceItems(
      [...Array(30 - invoice.invoiceItems.length)].map(() => ({
        date: undefined,
        text: undefined,
        quantity1: undefined,
        quantityUnitPrice1: undefined,
        quantity2: undefined,
        quantityUnitPrice2: undefined,
        customize1: undefined,
        customize2: undefined,
        customize3: undefined,
        total: undefined,
      }))
    );
    setUpdateInvoiceItems(invoice.invoiceItems);
  };

  const checkDiff = () => {
    return (
      JSON.stringify(invoice) === JSON.stringify(changeInvoice) &&
      JSON.stringify(invoice.invoiceItems) ===
        JSON.stringify(updateInvoiceItems) &&
      newInvoiceItems.every((newInvoiceItem) =>
        Object.values(newInvoiceItem).every((value) => value === undefined)
      )
    );
  };

  const onClickSave = async () => {
    setErrors([]);
    updateInvoiceItems.forEach((updateInvoiceItem, index) => {
      const error = [];
      if (!updateInvoiceItem.date) {
        error.push("日付");
      }
      if (!updateInvoiceItem.text) {
        error.push("項目");
      }
      if (!updateInvoiceItem.total) {
        error.push("金額");
      }
      if (error.length) {
        const text = `${index + 1}行目の${error.join("、")}が未入力です。`;
        setErrors((prev) => [...prev, text]);
      }
    });
    newInvoiceItems.forEach((newInvoiceItem, index) => {
      if (Object.values(newInvoiceItem).every((value) => value === undefined))
        return;
      const error = [];
      if (!newInvoiceItem.date) {
        error.push("日付");
      }
      if (!newInvoiceItem.text) {
        error.push("項目");
      }
      if (!newInvoiceItem.total) {
        error.push("金額");
      }
      if (error.length) {
        const text = `${
          index + 1 + updateInvoiceItems.length
        }行目の${error.join("、")}が未入力です。`;
        setErrors((prev) => [...prev, text]);
      }
    });
    if (errors.length) return;
    const newReqs = newInvoiceItems
      .filter(
        (newInvoiceItem) =>
          newInvoiceItem.total && newInvoiceItem.text && newInvoiceItem.date
      )
      .map(
        (newInvoiceItem) => new InvoiceItemReq({ ...newInvoiceItem, invoiceId })
      );
    const updateReqs = updateInvoiceItems.map(
      (updateInvoiceItem: any) => new InvoiceItemReq({ ...updateInvoiceItem })
    );
    await InvoiceItemApi.create(newReqs);
    await InvoiceItemApi.update(updateReqs);
    await dispatch(
      updateInvoice({
        id: invoiceId,
        req: {
          ...changeInvoice,
        },
      })
    );
    await dispatch(fetchInvoice({ id: Number(invoiceId) }));
    toast("保存しました");
  };

  const onChangeInvoice = (key: string, value: any) => {
    setChangeInvoice((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Box margin={2} display={"flex"} flexDirection={"column"}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>日付</Typography>
            </StyledTableCell>
            <StyledTableCell width={240} variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>項目</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>個数①</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>単価①</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>個数②</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>単価②</Typography>
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize1 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize1", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize2 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize2", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize3 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize3", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>金額</Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {updateInvoiceItems.map((item, index) => (
            <TableRow key={item.id}>
              <StyledTableCell>
                <TextField
                  type="month"
                  size={"small"}
                  variant={"standard"}
                  value={item.date || ""}
                  onChange={(event) => {
                    event.persist();
                    console.log(event.target.value);
                    onClickEditUpdateInvoiceItem(
                      index,
                      "date",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  style={{ width: "220px" }}
                  size={"small"}
                  variant={"standard"}
                  value={item.text || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "text",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.quantity1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "quantity1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.quantityUnitPrice1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "quantityUnitPrice1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.quantity2 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "quantity2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.quantityUnitPrice2 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "quantityUnitPrice2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize2 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize3 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize3",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "160px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.total}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "total",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
            </TableRow>
          ))}
          {newInvoiceItems.map((newInvoiceItem, index) => (
            <TableRow key={index}>
              <StyledTableCell>
                <TextField
                  type="month"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.date || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "date",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  size={"small"}
                  variant={"standard"}
                  style={{ width: "220px" }}
                  value={newInvoiceItem.text || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "text",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.quantity1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "quantity1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.quantityUnitPrice1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "quantityUnitPrice1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.quantity2 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "quantity2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.quantityUnitPrice2 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "quantityUnitPrice2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize2 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize3 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize3",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "160px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.total || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "total",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
            </TableRow>
          ))}
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }} rowSpan={9}>
              備考
            </StyledTableCell>
            <StyledTableCell rowSpan={9} colSpan={7}>
              <TextareaAutosize
                minRows={25}
                style={{ width: "100%" }}
                value={changeInvoice.remarks || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("remarks", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              小計
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.subtotal}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("subtotal", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.taxTitle || prevInvoice?.taxTitle || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("taxTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.tax}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("tax", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={
                  changeInvoice.advancePaidTitle ||
                  prevInvoice?.advancePaidTitle ||
                  ""
                }
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("advancePaidTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.advancePaid || ""}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("advancePaid", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={
                  changeInvoice.totalTitle || prevInvoice?.totalTitle || ""
                }
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("totalTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.total}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("total", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
      {errors.length ? (
        <Box
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          marginY={5}
        >
          {errors.map((error) => (
            <Typography color={"red"}>{error}</Typography>
          ))}
        </Box>
      ) : (
        <></>
      )}
      <Box display="flex" justifyContent="center" marginY={5}>
        <Button
          variant="contained"
          color={"inherit"}
          onClick={() => onClickReset()}
        >
          リセット
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{ marginLeft: "16px" }}
          onClick={() => onClickSave()}
        >
          保存
        </Button>
      </Box>
    </Box>
  );
};

const LocationTable = ({ prevInvoice }: TableProp) => {
  const dispatch = useAppDispatch();
  const invoiceEntity = useAppSelector(
    (state) => state.invoice.entities.invoices
  );
  const invoiceId = useAppSelector((state) => state.invoice.result[0]);
  const invoice = invoiceEntity[invoiceId];
  const [changeInvoice, setChangeInvoice] = useState<InvoiceRes>(invoice);
  const [newInvoiceItems, setNewInvoiceItems] = useState<
    {
      date?: string;
      text?: string;
      location?: number;
      locationUnitPrice?: number;
      customize1?: number;
      customize2?: number;
      customize3?: number;
      total?: number;
    }[]
  >(
    [...Array(30 - invoice.invoiceItems.length)].map(() => ({
      date: undefined,
      text: undefined,
      location: undefined,
      locationUnitPrice: undefined,
      customize1: undefined,
      customize2: undefined,
      customize3: undefined,
      total: undefined,
    }))
  );
  const [updateInvoiceItems, setUpdateInvoiceItems] = useState(
    invoice.invoiceItems
  );
  const [errors, setErrors] = useState<string[]>([]);
  useEffect(() => {
    setUpdateInvoiceItems(invoice.invoiceItems);
    setChangeInvoice(invoice);
  }, []);

  useEffect(() => {
    const subtotal =
      newInvoiceItems.reduce(
        (sum, item) => sum + (Number(item.total) || 0),
        0
      ) +
      updateInvoiceItems.reduce(
        (sum, item) => sum + (Number(item.total) || 0),
        0
      );
    setChangeInvoice((prev) => ({
      ...prev,
      subtotal,
      tax: Math.trunc(subtotal * 0.1),
      total: Math.trunc(subtotal * 1.1 + (prev.advancePaid || 0)),
    }));
  }, [newInvoiceItems, updateInvoiceItems, changeInvoice]);
  const onClickEditNewInvoiceItem = (
    index: number,
    key: string,
    value: any
  ) => {
    setNewInvoiceItems((prev) => {
      const newInvoiceItems: any = [...prev];
      newInvoiceItems[index][key] = value;
      return newInvoiceItems;
    });
  };

  const onClickEditUpdateInvoiceItem = (
    index: number,
    key: string,
    value: any
  ) => {
    setUpdateInvoiceItems((prev) => {
      const updateInvoiceItems: any = [...prev];
      updateInvoiceItems[index][key] = value;
      return updateInvoiceItems;
    });
  };

  const onClickReset = () => {
    setNewInvoiceItems(
      [...Array(30 - invoice.invoiceItems.length)].map(() => ({
        date: undefined,
        text: undefined,
        location: undefined,
        locationUnitPrice: undefined,
        customize1: undefined,
        customize2: undefined,
        customize3: undefined,
        total: undefined,
      }))
    );
    setUpdateInvoiceItems(invoice.invoiceItems);
  };

  const checkDiff = () => {
    return (
      JSON.stringify(invoice) === JSON.stringify(changeInvoice) &&
      JSON.stringify(invoice.invoiceItems) ===
        JSON.stringify(updateInvoiceItems) &&
      newInvoiceItems.every((newInvoiceItem) =>
        Object.values(newInvoiceItem).every((value) => value === undefined)
      )
    );
  };

  const onClickSave = async () => {
    setErrors([]);
    updateInvoiceItems.forEach((updateInvoiceItem, index) => {
      const error = [];
      if (!updateInvoiceItem.date) {
        error.push("日付");
      }
      if (!updateInvoiceItem.text) {
        error.push("項目");
      }
      if (!updateInvoiceItem.total) {
        error.push("金額");
      }
      if (error.length) {
        const text = `${index + 1}行目の${error.join("、")}が未入力です。`;
        setErrors((prev) => [...prev, text]);
      }
    });
    newInvoiceItems.forEach((newInvoiceItem, index) => {
      if (Object.values(newInvoiceItem).every((value) => value === undefined))
        return;
      const error = [];
      if (!newInvoiceItem.date) {
        error.push("日付");
      }
      if (!newInvoiceItem.text) {
        error.push("項目");
      }
      if (!newInvoiceItem.total) {
        error.push("金額");
      }
      if (error.length) {
        const text = `${
          index + 1 + updateInvoiceItems.length
        }行目の${error.join("、")}が未入力です。`;
        setErrors((prev) => [...prev, text]);
      }
    });
    if (errors.length) return;
    const newReqs = newInvoiceItems
      .filter(
        (newInvoiceItem) =>
          newInvoiceItem.total && newInvoiceItem.text && newInvoiceItem.date
      )
      .map(
        (newInvoiceItem) => new InvoiceItemReq({ ...newInvoiceItem, invoiceId })
      );
    const updateReqs = updateInvoiceItems.map(
      (updateInvoiceItem: any) => new InvoiceItemReq({ ...updateInvoiceItem })
    );
    await InvoiceItemApi.create(newReqs);
    await InvoiceItemApi.update(updateReqs);
    await dispatch(
      updateInvoice({
        id: invoiceId,
        req: {
          ...changeInvoice,
        },
      })
    );
    await dispatch(fetchInvoice({ id: Number(invoiceId) }));
    toast("保存しました");
  };

  const onChangeInvoice = (key: string, value: any) => {
    setChangeInvoice((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Box margin={2} display={"flex"} flexDirection={"column"}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>日付</Typography>
            </StyledTableCell>
            <StyledTableCell width={240} variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>項目</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>件数</Typography>
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>単価</Typography>
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize1 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize1", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize2 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize2", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize3 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize3", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>金額</Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {updateInvoiceItems.map((item, index) => (
            <TableRow key={item.id}>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="date"
                  size={"small"}
                  variant={"standard"}
                  value={item.date || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "date",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  style={{ width: "220px" }}
                  size={"small"}
                  variant={"standard"}
                  value={item.text || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "text",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.location || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "location",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.locationUnitPrice || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "locationUnitPrice",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize2 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize3 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize3",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "160px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.total}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "total",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
            </TableRow>
          ))}
          {newInvoiceItems.map((newInvoiceItem, index) => (
            <TableRow key={index}>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="date"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.date || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "dateNum",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  size={"small"}
                  variant={"standard"}
                  style={{ width: "220px" }}
                  value={newInvoiceItem.text || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "text",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.location || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "location",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.locationUnitPrice || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "locationUnitPrice",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize2 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize3 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize3",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "160px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.total || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "total",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
            </TableRow>
          ))}
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }} rowSpan={9}>
              備考
            </StyledTableCell>
            <StyledTableCell rowSpan={9} colSpan={5}>
              <TextareaAutosize
                minRows={25}
                style={{ width: "100%" }}
                value={changeInvoice.remarks || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("remarks", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              小計
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.subtotal}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("subtotal", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.taxTitle || prevInvoice?.taxTitle || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("taxTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.tax}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("tax", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={
                  changeInvoice.subtotalTitle ||
                  prevInvoice?.subtotalTitle ||
                  ""
                }
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("subtotalTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.advancePaid || ""}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("advancePaid", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={
                  changeInvoice.totalTitle || prevInvoice?.totalTitle || ""
                }
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("totalTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.total}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("tax", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
      {errors.length ? (
        <Box
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          marginY={5}
        >
          {errors.map((error) => (
            <Typography color={"red"}>{error}</Typography>
          ))}
        </Box>
      ) : (
        <></>
      )}
      <Box display="flex" justifyContent="center" marginY={5}>
        <Button
          variant="contained"
          color={"inherit"}
          onClick={() => onClickReset()}
        >
          リセット
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{ marginLeft: "16px" }}
          onClick={() => onClickSave()}
        >
          保存
        </Button>
      </Box>
    </Box>
  );
};

const FreeTable = ({ prevInvoice }: TableProp) => {
  const dispatch = useAppDispatch();
  const invoiceEntity = useAppSelector(
    (state) => state.invoice.entities.invoices
  );
  const invoiceId = useAppSelector((state) => state.invoice.result[0]);
  const invoice = invoiceEntity[invoiceId];
  const [changeInvoice, setChangeInvoice] = useState<InvoiceRes>(invoice);
  const [newInvoiceItems, setNewInvoiceItems] = useState<
    {
      date?: string;
      text?: string;
      customize1?: number;
      customize2?: number;
      customize3?: number;
      customize4?: number;
      customize5?: number;
      customize6?: number;
      customize7?: number;
      customize8?: number;
      customize9?: number;
      customize10?: number;
      total?: number;
    }[]
  >(
    [...Array(30 - invoice.invoiceItems.length)].map(() => ({
      date: undefined,
      text: undefined,
      customize1: undefined,
      customize2: undefined,
      customize3: undefined,
      customize4: undefined,
      customize5: undefined,
      customize6: undefined,
      customize7: undefined,
      customize8: undefined,
      customize9: undefined,
      customize10: undefined,
      total: undefined,
    }))
  );
  const [updateInvoiceItems, setUpdateInvoiceItems] = useState(
    invoice.invoiceItems
  );
  const [errors, setErrors] = useState<string[]>([]);
  useEffect(() => {
    setUpdateInvoiceItems(invoice.invoiceItems);
    setChangeInvoice(invoice);
  }, []);

  useEffect(() => {
    const subtotal =
      newInvoiceItems.reduce(
        (sum, item) => sum + (Number(item.total) || 0),
        0
      ) +
      updateInvoiceItems.reduce(
        (sum, item) => sum + (Number(item.total) || 0),
        0
      );
    setChangeInvoice((prev) => ({
      ...prev,
      subtotal,
      tax: Math.trunc(subtotal * 0.1),
      total: Math.trunc(subtotal * 1.1 + (prev.advancePaid || 0)),
    }));
  }, [newInvoiceItems, updateInvoiceItems, changeInvoice]);
  const onClickEditNewInvoiceItem = (
    index: number,
    key: string,
    value: any
  ) => {
    setNewInvoiceItems((prev) => {
      const newInvoiceItems: any = [...prev];
      newInvoiceItems[index][key] = value;
      return newInvoiceItems;
    });
  };

  const onClickEditUpdateInvoiceItem = (
    index: number,
    key: string,
    value: any
  ) => {
    setUpdateInvoiceItems((prev) => {
      const updateInvoiceItems: any = [...prev];
      updateInvoiceItems[index][key] = value;
      return updateInvoiceItems;
    });
  };

  const onClickReset = () => {
    setNewInvoiceItems(
      [...Array(30 - invoice.invoiceItems.length)].map(() => ({
        date: undefined,
        text: undefined,
        customize1: undefined,
        customize2: undefined,
        customize3: undefined,
        customize4: undefined,
        customize5: undefined,
        customize6: undefined,
        customize7: undefined,
        customize8: undefined,
        customize9: undefined,
        customize10: undefined,
        total: undefined,
      }))
    );
    setUpdateInvoiceItems(invoice.invoiceItems);
  };

  const checkDiff = () => {
    return (
      JSON.stringify(invoice) === JSON.stringify(changeInvoice) &&
      JSON.stringify(invoice.invoiceItems) ===
        JSON.stringify(updateInvoiceItems) &&
      newInvoiceItems.every((newInvoiceItem) =>
        Object.values(newInvoiceItem).every((value) => value === undefined)
      )
    );
  };

  const onClickSave = async () => {
    setErrors([]);
    updateInvoiceItems.forEach((updateInvoiceItem, index) => {
      const error = [];
      if (!updateInvoiceItem.date) {
        error.push("日付");
      }
      if (!updateInvoiceItem.text) {
        error.push("項目");
      }
      if (!updateInvoiceItem.total) {
        error.push("金額");
      }
      if (error.length) {
        const text = `${index + 1}行目の${error.join("、")}が未入力です。`;
        setErrors((prev) => [...prev, text]);
      }
    });
    newInvoiceItems.forEach((newInvoiceItem, index) => {
      if (Object.values(newInvoiceItem).every((value) => value === undefined))
        return;
      const error = [];
      if (!newInvoiceItem.date) {
        error.push("日付");
      }
      if (!newInvoiceItem.text) {
        error.push("項目");
      }
      if (!newInvoiceItem.total) {
        error.push("金額");
      }
      if (error.length) {
        const text = `${
          index + 1 + updateInvoiceItems.length
        }行目の${error.join("、")}が未入力です。`;
        setErrors((prev) => [...prev, text]);
      }
    });
    if (errors.length) return;
    const newReqs = newInvoiceItems
      .filter(
        (newInvoiceItem) =>
          newInvoiceItem.total && newInvoiceItem.text && newInvoiceItem.date
      )
      .map(
        (newInvoiceItem) => new InvoiceItemReq({ ...newInvoiceItem, invoiceId })
      );
    const updateReqs = updateInvoiceItems.map(
      (updateInvoiceItem: any) => new InvoiceItemReq({ ...updateInvoiceItem })
    );
    await InvoiceItemApi.create(newReqs);
    await InvoiceItemApi.update(updateReqs);
    await dispatch(
      updateInvoice({
        id: invoiceId,
        req: {
          ...changeInvoice,
        },
      })
    );
    await dispatch(fetchInvoice({ id: Number(invoiceId) }));
    toast("保存しました");
  };

  const onChangeInvoice = (key: string, value: any) => {
    setChangeInvoice((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Box margin={2} display={"flex"} flexDirection={"column"}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>日付</Typography>
            </StyledTableCell>
            <StyledTableCell width={240} variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>項目</Typography>
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize1 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize1", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize2 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize2", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize3 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize3", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize4 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize4", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize5 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize5", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize6 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize6", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize7 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize7", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize8 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize8", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize9 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize9", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align={"center"} variant={"head"}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.customize10 || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("customize10", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell variant={"head"}>
              <Typography style={{ fontSize: "14px" }}>金額</Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {updateInvoiceItems.map((item, index) => (
            <TableRow key={item.id}>
              <StyledTableCell>
                <StyledTableCell>
                  <StyledTextFieldAtNumber
                    style={{ width: "80px" }}
                    type="date"
                    size={"small"}
                    variant={"standard"}
                    value={item.date || ""}
                    onChange={(event) => {
                      event.persist();
                      onClickEditUpdateInvoiceItem(
                        index,
                        "date",
                        event.target.value
                      );
                    }}
                  />
                </StyledTableCell>
                <TextField
                  style={{ width: "220px" }}
                  size={"small"}
                  variant={"standard"}
                  value={item.text}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "text",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize2 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize3 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize3",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize4 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize4",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize5 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize5",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize6 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize6",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize7 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize7",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize8 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize8",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize9 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize9",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.customize10 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "customize10",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "160px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={item.total}
                  onChange={(event) => {
                    event.persist();
                    onClickEditUpdateInvoiceItem(
                      index,
                      "total",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
            </TableRow>
          ))}
          {newInvoiceItems.map((newInvoiceItem, index) => (
            <TableRow key={index}>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="date"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.date || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "dateNum",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  size={"small"}
                  variant={"standard"}
                  style={{ width: "220px" }}
                  value={newInvoiceItem.text || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "text",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize1 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize1",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize2 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize2",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize3 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize3",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize4 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize4",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize5 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize5",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize6 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize6",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize7 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize7",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize8 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize8",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize9 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize9",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "80px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.customize10 || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "customize10",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextFieldAtNumber
                  style={{ width: "160px" }}
                  type="number"
                  size={"small"}
                  variant={"standard"}
                  value={newInvoiceItem.total || ""}
                  onChange={(event) => {
                    event.persist();
                    onClickEditNewInvoiceItem(
                      index,
                      "total",
                      event.target.value
                    );
                  }}
                />
              </StyledTableCell>
            </TableRow>
          ))}
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }} rowSpan={9}>
              備考
            </StyledTableCell>
            <StyledTableCell rowSpan={9} colSpan={10}>
              <TextareaAutosize
                minRows={25}
                style={{ width: "100%" }}
                value={changeInvoice.remarks || ""}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("remarks", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.subtotalTitle}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("subtotalTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.subtotal}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("subtotal", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.taxTitle}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("taxTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.tax}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("tax", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.advancePaidTitle}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("advancePaidTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.advancePaid || ""}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("advancePaid", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
              <TextField
                size={"small"}
                variant={"standard"}
                value={changeInvoice.totalTitle}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("totalTitle", event.target.value);
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <StyledTextFieldAtNumber
                value={changeInvoice.total}
                type={"number"}
                size={"small"}
                variant={"standard"}
                onChange={(event) => {
                  event.persist();
                  onChangeInvoice("tax", Number(event.target.value));
                }}
              />
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
      {errors.length ? (
        <Box
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          marginY={5}
        >
          {errors.map((error) => (
            <Typography color={"red"}>{error}</Typography>
          ))}
        </Box>
      ) : (
        <></>
      )}
      <Box display="flex" justifyContent="center" marginY={5}>
        <Button
          variant="contained"
          color={"inherit"}
          onClick={() => onClickReset()}
        >
          リセット
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{ marginLeft: "16px" }}
          onClick={() => onClickSave()}
        >
          保存
        </Button>
      </Box>
    </Box>
  );
};

type HeaderProps = {
  invoice: InvoiceRes;
  targetMonth: Date;
  setTargetMonth: React.Dispatch<React.SetStateAction<Date>>;
  selectedSupplierIds: string[];
  setSelectedSupplierIds: React.Dispatch<React.SetStateAction<string[]>>;
  printDocument: () => void;
  isPdfRender: boolean;
  isSendMail: boolean;
  onClickSendMail: () => void;
};
const Header = ({
  invoice,
  targetMonth,
  setTargetMonth,
  selectedSupplierIds,
  setSelectedSupplierIds,
  printDocument,
  isPdfRender,
  isSendMail,
  onClickSendMail,
}: HeaderProps) => {
  const [updateModal, setUpdateModal] = useModal("invoiceUpdate");
  const supplierIds = useAppSelector((state) => state.supplier.result);
  const supplierEntities = useAppSelector(
    (state) => state.supplier.entities.suppliers
  );
  const suppliers = supplierIds.map((id) => supplierEntities[id]);
  const account = useAppSelector((state) => state.auth.staff.account);

  useEffect(() => {
    setSelectedSupplierIds([
      ...suppliers.map((supplier) => String(supplier.id)),
      "すべて",
    ]);
  }, [supplierIds]);

  return (
    <>
      <Box display="flex" flexDirection="column" margin={2}>
        <Box display="flex" alignItems={"flex-end"}>
          <Typography style={{ margin: "auto" }} variant={"h4"}>
            {invoice.title}
          </Typography>
        </Box>
        <Box display={"flex"} margin={2} justifyContent={"space-between"}>
          <Typography>請求詳細</Typography>
          <Box
            display={"flex"}
            flexDirection="column"
            marginLeft={3}
            marginTop={8}
          >
            <Typography>
              {invoice.supplier.name}
              {"　"}
              {EnumUtils.mapToEnum(HonorificTitle, invoice.honorificTitle) ||
                "御中"}
            </Typography>
            <Typography>
              〒{invoice.supplierPostCode || invoice.supplier.postCode}
            </Typography>
            <Typography>
              {invoice.supplierAddress || invoice.supplier.address}
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"flex-end"}>
            <Box
              display={"flex"}
              flexDirection="column"
              marginLeft={3}
              marginTop={8}
            >
              <Typography>
                {invoice.accountName || invoice.account.name}
              </Typography>
              <Typography>
                〒{invoice.accountPostCode || invoice.account.postCode}
              </Typography>
              <Typography>
                {invoice.accountAddress || invoice.account.address}
              </Typography>
              <Typography>
                TEL:{invoice.accountTel || invoice.account.tel} FAX:
                {invoice.accountFax || invoice.account.fax}
              </Typography>
              <Typography>登録番号:{invoice.account.invoiceNumber}</Typography>
            </Box>
            <Box marginLeft={1}>
              {account?.fileUrl && (
                <img
                  src={
                    `https://tar-zan-file-upload.s3.ap-northeast-1.amazonaws.com/${account.fileUrl}` ||
                    ""
                  }
                  alt={""}
                  width={100}
                  height={100}
                />
              )}
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="column" marginLeft={3}>
            <Typography>ご請求日</Typography>
            <Typography>{invoice.payDate}</Typography>
            <Typography marginTop={2}>振込先</Typography>
            <Typography>
              名義
              {invoice.accountBankAccountName ||
                invoice.account.bankAccountName1}
            </Typography>
            <Typography>
              {invoice.accountBankName || invoice.account.bankName1}
            </Typography>
            <Typography>
              {EnumUtils.mapToEnum(
                BankAccountType,
                invoice.accountBankAccountType ||
                  invoice.account.bankAccountType1
              )}{" "}
              ){" "}
              {invoice.accountBankAccountNumber ||
                invoice.account.bankAccountNumber1}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection="column"
            marginLeft={3}
            marginTop={8}
          >
            <Button
              variant="contained"
              color="info"
              disabled={isSendMail}
              onClick={() => onClickSendMail()}
            >
              メール添付
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => printDocument()}
              disabled={isPdfRender}
              style={{ marginTop: "10px" }}
            >
              PDFダウンロード
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "10px" }}
              onClick={() => setUpdateModal(true, {})}
            >
              上部項目編集
            </Button>
          </Box>
        </Box>
      </Box>
      {updateModal.show && (
        <UpdateInvoiceModal
          updateModal={updateModal}
          setUpdateModal={setUpdateModal}
        />
      )}
    </>
  );
};
type ModalProps = {
  updateModal: { show: boolean; meta?: any };
  setUpdateModal: any;
};
const UpdateInvoiceModal = ({ updateModal, setUpdateModal }: ModalProps) => {
  const dispatch = useAppDispatch();
  const invoiceEntity = useAppSelector(
    (state) => state.invoice.entities.invoices
  );
  const invoiceId = useAppSelector((state) => state.invoice.result[0]);
  const invoice = invoiceEntity[invoiceId];
  const supplierIds = useAppSelector((state) => state.supplier.result);
  const supplierEntities = useAppSelector(
    (state) => state.supplier.entities.suppliers
  );
  const suppliers = supplierIds.map((id) => supplierEntities[id]);
  const [selectBankAccount, setSelectBankAccount] = useState(1);
  const [updateInvoiceReq, setUpdateInvoice] = useState(invoice);

  const onChangeUpdateInvoice = (key: string, value: any) => {
    setUpdateInvoice((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const accountBankList = [
    invoice.account.bankName1,
    invoice.account.bankName2,
    invoice.account.bankName3,
  ].filter(Boolean);

  const onClickUpdateInvoice = () => {
    dispatch(updateInvoice({ id: invoiceId, req: updateInvoiceReq }));
    setUpdateModal(false, {});
  };

  const onChangeSelectBankAccount = (value: any) => {
    setSelectBankAccount(value);
    onChangeUpdateInvoice(
      "accountBankName",
      (invoice.account as any)[`bankName${value}`]
    );
    onChangeUpdateInvoice(
      "accountBankAccountName",
      (invoice.account as any)[`bankAccountName${value}`]
    );
    onChangeUpdateInvoice(
      "accountBankAccountType",
      (invoice.account as any)[`bankAccountType${value}`]
    );
    onChangeUpdateInvoice(
      "accountBankAccountNumber",
      (invoice.account as any)[`bankAccountNumber${value}`]
    );
  };

  useEffect(() => {
    setUpdateInvoice((prev) => ({
      ...prev,
      supplierPostCode: invoice.supplier.postCode,
      supplierAddress: invoice.supplier.address,
      accountName: invoice.account.name,
      accountPostCode: invoice.account.postCode,
      accountAddress: invoice.account.address,
      accountTel: invoice.account.tel,
      accountFax: invoice.account.fax,
      accountBankAccountName: invoice.account.bankAccountName1,
      accountBankName: invoice.account.bankName1,
      accountBankAccountType: invoice.account.bankAccountType1,
      accountBankAccountNumber: invoice.account.bankAccountNumber1,
    }));
  }, []);

  return (
    <Modal
      show={updateModal.show}
      setShow={setUpdateModal}
      title={"請求情報編集"}
    >
      <Box display={"flex"} flexDirection={"column"}>
        <Typography variant={"h5"}>請求宛先情報</Typography>
        <Autocomplete
          style={{ marginTop: "16px" }}
          options={suppliers}
          value={updateInvoiceReq.supplier}
          getOptionLabel={(option) => option.name || ""}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={"取引先名"} />
          )}
          onChange={(event, value) => {
            event.persist();
            onChangeUpdateInvoice("driver", value);
            onChangeUpdateInvoice("driverId", value?.id || "");
          }}
          noOptionsText="選択肢がありません"
        />
        <TextField
          select
          label="口座種別"
          style={{ marginTop: "16px" }}
          value={updateInvoiceReq.honorificTitle || ""}
          onChange={(event) => {
            onChangeUpdateInvoice("honorificTitle", event.target.value);
          }}
        >
          {Object.entries(HonorificTitle).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label={"郵便番号"}
          style={{ marginTop: "16px" }}
          value={updateInvoiceReq.supplierPostCode || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateInvoice("supplierPostCode", event.target.value);
          }}
        />
        <TextField
          label={"住所"}
          style={{ marginTop: "16px" }}
          value={updateInvoiceReq.supplierAddress || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateInvoice("supplierAddress", event.target.value);
          }}
        />
        <TextField
          type={"date"}
          label={"お支払い日"}
          style={{ marginTop: "16px" }}
          value={updateInvoiceReq.payDate || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateInvoice("payDate", event.target.value);
          }}
        />

        <Typography variant={"h5"} style={{ marginTop: "32px" }}>
          払出し会社情報
        </Typography>
        <TextField
          label={"払出会社名"}
          style={{ marginTop: "16px" }}
          value={updateInvoiceReq.accountName || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateInvoice("accountName", event.target.value);
          }}
        />
        <TextField
          label={"郵便番号"}
          style={{ marginTop: "16px" }}
          value={updateInvoiceReq.accountPostCode || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateInvoice("accountPostCode", event.target.value);
          }}
        />
        <TextField
          label={"住所"}
          style={{ marginTop: "16px" }}
          value={updateInvoiceReq.accountAddress || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateInvoice("accountAddress", event.target.value);
          }}
        />
        <TextField
          label={"電話番号"}
          style={{ marginTop: "16px" }}
          value={updateInvoiceReq.accountTel || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateInvoice("accountTel", event.target.value);
          }}
        />
        <TextField
          label={"FAX"}
          style={{ marginTop: "16px" }}
          value={updateInvoiceReq.accountFax || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateInvoice("accountFax", event.target.value);
          }}
        />
        <TextField
          select
          label={"振込先銀行"}
          value={selectBankAccount}
          style={{ marginTop: "16px" }}
          onChange={(event) => {
            onChangeSelectBankAccount(event.target.value);
          }}
        >
          {accountBankList.map((accountBank, index) => (
            <MenuItem key={index} value={index + 1}>
              {accountBank}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label={"銀行名"}
          style={{ marginTop: "16px" }}
          value={updateInvoiceReq.accountBankName || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateInvoice("accountBankName", event.target.value);
          }}
        />
        <TextField
          label={"口座名義"}
          style={{ marginTop: "16px" }}
          value={updateInvoiceReq.accountBankAccountName || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateInvoice("accountBankAccountName", event.target.value);
          }}
        />
        <TextField
          select
          label={"口座種別"}
          style={{ marginTop: "16px" }}
          value={updateInvoiceReq.accountBankAccountType || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateInvoice("accountBankAccountType", event.target.value);
          }}
        >
          {Object.entries(BankAccountType).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label={"口座番号"}
          style={{ marginTop: "16px" }}
          value={updateInvoiceReq.accountBankAccountNumber || ""}
          onChange={(event) => {
            event.persist();
            onChangeUpdateInvoice(
              "accountBankAccountNumber",
              event.target.value
            );
          }}
        />
        <Button
          variant={"contained"}
          color={"primary"}
          style={{ marginTop: "16px" }}
          onClick={() => onClickUpdateInvoice()}
        >
          保存
        </Button>
      </Box>
    </Modal>
  );
};
export default IndividualInvoice;
