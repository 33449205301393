import StaffRes from "types/res/StaffRes";
import { createSlice } from "@reduxjs/toolkit";
import {
  createStaff,
  deleteStaff,
  fetchAllStaff,
  updateStaff,
} from "redux/actions/staff";

export type StaffState = {
  entities: { staffs: { [key: number]: StaffRes } };
  result: number[];
};
const initialState: StaffState = { entities: { staffs: {} }, result: [] };

const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllStaff.fulfilled, (_, { payload }) => ({
      entities: { staffs: { ...payload.entities.staffs } },
      result: payload.result,
    }));
    builder.addCase(createStaff.fulfilled, (state, { payload }) => ({
      entities: {
        staffs: { ...state.entities.staffs, ...payload.entities.staffs },
      },
      result: [...state.result, payload.result],
    }));
    builder.addCase(updateStaff.fulfilled, (state, { payload }) => ({
      entities: {
        staffs: {
          ...state.entities.staffs,
          ...payload.entities.staffs,
        },
      },
      result: state.result,
    }));
    builder.addCase(deleteStaff.fulfilled, (state, { payload }) => {
      const entities = { ...state.entities.staffs };
      delete entities[payload];
      return {
        entities: {
          staffs: {
            ...entities,
          },
        },
        result: state.result.filter((id) => id !== payload),
      };
    });
  },
});

export default staffSlice.reducer;
