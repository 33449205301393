import { IsNotEmpty, IsNumber, IsOptional, IsString } from "class-validator";
import { InvoiceType } from "types/enum/InvoiceType";
import { HonorificTitle } from "types/enum/HonorificTitle";

export default class InvoiceReq {
  @IsNotEmpty({
    message: "未入力です",
  })
  @IsNumber()
  supplierId!: number;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsString()
  targetMonth!: string;

  @IsNotEmpty({
    message: "未入力です",
  })
  type!: InvoiceType;

  @IsOptional()
  @IsString()
  title?: string;

  @IsOptional()
  @IsString()
  payDate?: string;

  @IsOptional()
  @IsString()
  honorificTitle?: HonorificTitle;

  @IsOptional()
  @IsString()
  supplierInvoiceNumber?: string;

  @IsOptional()
  @IsString()
  supplierPostCode?: string;

  @IsOptional()
  @IsString()
  supplierAddress?: string;

  @IsOptional()
  @IsString()
  accountName?: string;

  @IsOptional()
  @IsString()
  accountPostCode?: string;

  @IsOptional()
  @IsString()
  accountAddress?: string;

  @IsOptional()
  @IsString()
  accountTel?: string;

  @IsOptional()
  @IsString()
  accountFax?: string;

  @IsOptional()
  @IsString()
  accountBankAccountName?: string;

  @IsOptional()
  @IsString()
  accountBankName?: string;

  @IsOptional()
  @IsString()
  accountBankAccountType?: string;

  @IsOptional()
  @IsString()
  accountBankAccountNumber?: string;

  @IsOptional()
  @IsNumber()
  subtotal?: number;

  @IsOptional()
  @IsNumber()
  tax?: number;

  @IsOptional()
  @IsNumber()
  advancePaid?: number;

  @IsOptional()
  @IsNumber()
  total?: number;

  @IsOptional()
  @IsString()
  remark?: string;

  @IsOptional()
  @IsString()
  customize1?: string;

  @IsOptional()
  @IsString()
  customize2?: string;

  @IsOptional()
  @IsString()
  customize3?: string;

  @IsOptional()
  @IsString()
  customize4?: string;

  @IsOptional()
  @IsString()
  customize5?: string;

  @IsOptional()
  @IsString()
  customize6?: string;

  @IsOptional()
  @IsString()
  customize7?: string;

  @IsOptional()
  @IsString()
  customize8?: string;

  @IsOptional()
  @IsString()
  customize9?: string;

  @IsOptional()
  @IsString()
  customize10?: string;

  @IsOptional()
  @IsNumber()
  subtotalTitle?: string;

  @IsOptional()
  @IsString()
  taxTitle?: string;

  @IsOptional()
  @IsString()
  advancePaidTitle?: string;

  @IsOptional()
  @IsString()
  totalTitle?: string;

  constructor(arg: Partial<InvoiceReq>) {
    Object.assign(this, arg);
  }
}
