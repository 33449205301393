import SchemaUtils from "utils/SchemaUtils";
import InvoiceRes from "types/res/InvoiceRes";
import { normalize } from "normalizr";
import { createAsyncThunk } from "@reduxjs/toolkit";
import InvoiceApi from "api/InvoiceApi";
import InvoiceReq from "types/req/InvoiceReq";
import SearchInvoiceReq from "types/req/search/SearchInvoiceReq";

const invoiceSchema = SchemaUtils.createEntity<InvoiceRes>("invoices");
type schemaType = { invoices: { [key: number]: InvoiceRes } };

export const fetchInvoice = createAsyncThunk(
  "fetchInvoice",
  async ({ id }: { id: number }) => {
    const data = await InvoiceApi.find(id);
    return normalize<undefined, schemaType, number>(data, invoiceSchema);
  }
);

export const fetchAllInvoice = createAsyncThunk(
  "fetchAllInvoice",
  async ({ req }: { req: SearchInvoiceReq }) => {
    const data = await InvoiceApi.findAll(req);
    return normalize<undefined, schemaType, number[]>(data, [invoiceSchema]);
  }
);

export const createInvoice = createAsyncThunk(
  "createInvoice",
  async ({ req }: { req: InvoiceReq }) => {
    const data = await InvoiceApi.create(req);
    return normalize<undefined, schemaType, number>(data, invoiceSchema);
  }
);

export const updateInvoice = createAsyncThunk(
  "updateInvoice",
  async ({ id, req }: { id: number; req: InvoiceReq }) => {
    const data = await InvoiceApi.update(id, req);
    return normalize<undefined, schemaType, number>(data, invoiceSchema);
  }
);

export const deleteInvoice = createAsyncThunk(
  "deleteInvoice",
  async ({ id }: { id: number }) => {
    await InvoiceApi.delete(id);
    return id;
  }
);
