import SchemaUtils from "utils/SchemaUtils";
import DriverRes from "types/res/DriverRes";
import { normalize } from "normalizr";
import { createAsyncThunk } from "@reduxjs/toolkit";
import DriverApi from "api/DriverApi";
import DriverReq from "types/req/DriverReq";
import SearchDriverReq from "types/req/search/SearchDriverReq";

const driverSchema = SchemaUtils.createEntity<DriverRes>("drivers");
type schemaType = { drivers: { [key: number]: DriverRes } };

export const fetchAllDriver = createAsyncThunk(
  "fetchAllDriver",
  async ({ req }: { req: SearchDriverReq }) => {
    const data = await DriverApi.findAll(req);
    return normalize<undefined, schemaType, number[]>(data, [driverSchema]);
  }
);

export const createDriver = createAsyncThunk(
  "createDriver",
  async ({ req }: { req: DriverReq }) => {
    const data = await DriverApi.create(req);
    return normalize<undefined, schemaType, number>(data, driverSchema);
  }
);

export const updateDriver = createAsyncThunk(
  "updateDriver",
  async ({ id, req }: { id: number; req: DriverReq }) => {
    const data = await DriverApi.update(id, req);
    return normalize<undefined, schemaType, number>(data, driverSchema);
  }
);

export const deleteDriver = createAsyncThunk(
  "deleteDriver",
  async ({ id }: { id: number }) => {
    await DriverApi.delete(id);
    return id;
  }
);
