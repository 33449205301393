import SupplierRes from "types/res/SupplierRes";
import { createSlice } from "@reduxjs/toolkit";
import {
  createSupplier,
  deleteSupplier,
  fetchAllSupplier,
  fetchAllSupplierWithSelect,
  updateSupplier,
} from "redux/actions/supplier";

export type SupplierState = {
  entities: { suppliers: { [key: number]: SupplierRes } };
  result: number[];
};
const initialState: SupplierState = { entities: { suppliers: {} }, result: [] };

const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllSupplier.fulfilled, (_, { payload }) => ({
      entities: { suppliers: { ...payload.entities.suppliers } },
      result: payload.result,
    }));
    builder.addCase(
      fetchAllSupplierWithSelect.fulfilled,
      (state, { payload }) => ({
        entities: {
          suppliers: {
            ...payload.entities.suppliers,
            ...state.entities.suppliers,
          },
        },
        result: [...payload.result],
      })
    );
    builder.addCase(createSupplier.fulfilled, (state, { payload }) => ({
      entities: {
        suppliers: {
          ...state.entities.suppliers,
          ...payload.entities.suppliers,
        },
      },
      result: [...state.result, payload.result],
    }));
    builder.addCase(updateSupplier.fulfilled, (state, { payload }) => ({
      entities: {
        suppliers: {
          ...state.entities.suppliers,
          ...payload.entities.suppliers,
        },
      },
      result: state.result,
    }));
    builder.addCase(deleteSupplier.fulfilled, (state, { payload }) => {
      const entities = { ...state.entities.suppliers };
      delete entities[payload];
      return {
        entities: {
          suppliers: {
            ...entities,
          },
        },
        result: state.result.filter((id) => id !== payload),
      };
    });
  },
});

export default supplierSlice.reducer;
