import React from "react";
import styled from "styled-components";
import {
  IconButton,
  Typography,
  Box,
  Dialog,
  DialogContent,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

type DialogTitleProps = {
  children: React.ReactNode;
  onClose: () => void;
};

const DialogTitle: React.FC<DialogTitleProps> = ({ children, onClose }) => {
  return (
    <Box
      display="flex"
      width={"100%"}
      justifyContent={"space-between"}
      padding={2}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose && (
        <CloseButton aria-label="close" onClick={onClose}>
          <Close />
        </CloseButton>
      )}
    </Box>
  );
};

type Props = {
  title?: string;
  children: React.ReactNode;
  show: boolean;
  setShow: (show: boolean) => void;
  maxWidth?: boolean;
};

const Modal: React.FC<Props> = ({
  children,
  title,
  show = false,
  setShow,
  maxWidth,
}) => {
  const handleClose = (
    event: React.MouseEvent<HTMLInputElement>,
    reason: "backdropClick"
  ) => {
    if (reason === "backdropClick") return;
    setShow(false);
  };

  const handleDialogClose = () => {
    setShow(false);
  };
  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth={maxWidth === undefined ? "md" : false}
    >
      <DialogTitle onClose={handleDialogClose}>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
