import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import EnumUtils from "utils/EnumUtils";
import { BankAccountType } from "types/enum/BankAccountType";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useParams } from "react-router-dom";
import { fetchInvoice } from "redux/actions/invoice";
import { HonorificTitle } from "types/enum/HonorificTitle";
import ImageUtils from "utils/ImageUtils";

type Props = {
  setIsPdfRenderer: React.Dispatch<React.SetStateAction<boolean>>;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#DDDDDD",
    color: theme.palette.common.black,
    border: "1px solid #707070",
    padding: "8px",
    fontSize: "24px",
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.common.black,
    border: "1px solid #707070",
    padding: "8px",
    fontSize: "24px",
  },
}));
const StyledTypography = styled(Typography)`
  font-size: 24px;
`;

const IndividualInvoicePdf = ({ setIsPdfRenderer }: Props) => {
  const dispatch = useAppDispatch();
  const invoiceEntity = useAppSelector(
    (state) => state.invoice.entities.invoices
  );
  const invoiceId = useAppSelector((state) => state.invoice.result[0]);
  const invoice = invoiceEntity[invoiceId];
  const account = useAppSelector((state) => state.auth.staff.account);
  const [image, setImage] = useState<string>("");
  const { id } = useParams();

  useEffect(() => {
    const timer = setTimeout(() => {
      pdfDocument();
    }, 3 * 1000);

    //クリーンアップ
    return () => {
      clearTimeout(timer);
    };
  });

  useEffect(() => {
    if (!account?.fileUrl) return;
    const apiCall = async () => {
      const result = await ImageUtils.toBase64ImageUrl(
        `https://tar-zan-file-upload.s3.ap-northeast-1.amazonaws.com/${account.fileUrl}` ||
          ""
      );
      setImage(result);
    };
    apiCall();
  }, []);

  useEffect(() => {
    dispatch(fetchInvoice({ id: Number(id) }));
  }, [id]);
  const pdfDocument = () => {
    const input = document.getElementById("invoice-pdf");
    if (!input) return;
    const doc = new jsPDF({
      orientation: "p",
      format: "a4",
    });
    html2canvas(input, { scale: 2 }).then(function (canvas) {
      const dataURI = canvas.toDataURL("image/jpeg");
      const width = doc.internal.pageSize.width;
      doc.addImage(dataURI, "JPEG", 0, 0, width, 0);
      doc.save("download.pdf");
    });
    setIsPdfRenderer(false);
  };
  const subtotal =
    invoice.subtotal ||
    invoice.invoiceItems.reduce((sum, item) => sum + item.total, 0);
  const tax =
    invoice.tax ||
    invoice.invoiceItems.reduce((sum, item) => sum + item.total, 0) * 0.1;
  const total = invoice.total || subtotal - tax + (invoice.advancePaid || 0);

  const TableComponent: { [key: string]: JSX.Element } = {
    car: <InvoicePdfCarTable />,
    distance: <InvoicePdfDistanceTable />,
    piece: <InvoicePdfPieceTable />,
    location: <InvoicePdfLocationTable />,
    free: <InvoicePdfFreeTable />,
  };
  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box
        padding={8}
        display={"flex"}
        flexDirection={"column"}
        id={"invoice-pdf"}
      >
        <Box display="flex" flexDirection="column" margin={2}>
          <Box display="flex" justifyContent={"center"}>
            <Typography
              style={{ margin: "auto", fontSize: "48px", fontWeight: "bold" }}
            >
              {invoice.title}
            </Typography>
          </Box>
          <Box display={"flex"} marginX={2} marginTop={1}>
            <StyledTypography
              style={{ fontSize: "48px", borderBottom: "1px solid" }}
            >
              ご請求金額{total.toLocaleString()}円
            </StyledTypography>
          </Box>
          <Box display={"flex"} margin={2} justifyContent={"space-between"}>
            <Box
              display={"flex"}
              flexDirection="column"
              marginLeft={3}
              marginTop={8}
            >
              <StyledTypography>
                {invoice.supplier.name}
                {"　"}
                {EnumUtils.mapToEnum(HonorificTitle, invoice.honorificTitle) ||
                  "御中"}
              </StyledTypography>
              <StyledTypography>
                〒{invoice.supplierPostCode || invoice.supplier.postCode}
              </StyledTypography>
              <StyledTypography>
                {invoice.supplierAddress || invoice.supplier.address}
              </StyledTypography>
            </Box>
            <Box display={"flex"} alignItems={"flex-end"}>
              <Box
                display={"flex"}
                flexDirection="column"
                marginLeft={3}
                marginTop={8}
              >
                <StyledTypography>
                  {invoice.accountName || invoice.account.name}
                </StyledTypography>
                <StyledTypography>
                  〒{invoice.accountPostCode || invoice.account.postCode}
                </StyledTypography>
                <StyledTypography>
                  {invoice.accountAddress || invoice.account.address}
                </StyledTypography>
                <StyledTypography>
                  TEL:{invoice.accountTel || invoice.account.tel} FAX:
                  {invoice.accountFax || invoice.account.fax}
                </StyledTypography>
                <StyledTypography>
                  登録番号:{invoice.account.invoiceNumber}
                </StyledTypography>
              </Box>
              <Box marginLeft={1}>
                {account?.fileUrl && (
                  <img src={image} alt={""} width={100} height={100} />
                )}
              </Box>
            </Box>
            <Box display={"flex"} flexDirection="column" marginLeft={3}>
              <StyledTypography>ご請求日</StyledTypography>
              <StyledTypography>{invoice.payDate}</StyledTypography>
              <StyledTypography marginTop={2}>振込先</StyledTypography>
              <StyledTypography>
                名義
                {invoice.accountBankAccountName ||
                  invoice.account.bankAccountName1}
              </StyledTypography>
              <StyledTypography>
                {invoice.accountBankName || invoice.account.bankName1}
              </StyledTypography>
              <StyledTypography>
                {EnumUtils.mapToEnum(
                  BankAccountType,
                  invoice.accountBankAccountType ||
                    invoice.account.bankAccountType1
                )}{" "}
                ){" "}
                {invoice.accountBankAccountNumber ||
                  invoice.account.bankAccountNumber1}
              </StyledTypography>
            </Box>
          </Box>
        </Box>
        {TableComponent[invoice.type]}
      </Box>
    </Box>
  );
};

export const InvoicePdfCarTable = () => {
  const invoiceEntity = useAppSelector(
    (state) => state.invoice.entities.invoices
  );
  const invoiceId = useAppSelector((state) => state.invoice.result[0]);
  const invoice = invoiceEntity[invoiceId];

  const rowsSpan = 4;

  const columnsSpan =
    1 +
    (invoice.customize1 ? 1 : 0) +
    (invoice.customize2 ? 1 : 0) +
    (invoice.customize3 ? 1 : 0);
  const subtotal =
    invoice.subtotal ||
    invoice.invoiceItems.reduce((sum, item) => sum + item.total, 0);
  const tax =
    invoice.tax ||
    invoice.invoiceItems.reduce((sum, item) => sum + item.total, 0) * 0.1;
  const total = invoice.total || subtotal - tax + (invoice.advancePaid || 0);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>項目</StyledTypography>
          </StyledTableCell>
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>日数</StyledTypography>
          </StyledTableCell>
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>運賃</StyledTypography>
          </StyledTableCell>
          {invoice.customize1 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize1}</StyledTypography>
            </StyledTableCell>
          )}
          {invoice.customize2 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize2}</StyledTypography>
            </StyledTableCell>
          )}
          {invoice.customize3 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize3}</StyledTypography>
            </StyledTableCell>
          )}
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>金額</StyledTypography>
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {invoice.invoiceItems.map((item, index) => (
          <TableRow key={item.id}>
            <StyledTableCell>
              <StyledTypography>{item.text}</StyledTypography>
            </StyledTableCell>
            <StyledTableCell align={"center"}>
              <StyledTypography>{item.dateNum}</StyledTypography>
            </StyledTableCell>
            <StyledTableCell align={"right"}>
              <StyledTypography>{item.fare?.toLocaleString()}</StyledTypography>
            </StyledTableCell>
            {invoice.customize1 && (
              <StyledTableCell>
                <StyledTypography>{item.customize1 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            {invoice.customize2 && (
              <StyledTableCell>
                <StyledTypography>{item.customize2 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            {invoice.customize3 && (
              <StyledTableCell>
                <StyledTypography>{item.customize3 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            <StyledTableCell align={"right"}>
              <StyledTypography>
                {item.total?.toLocaleString()}
              </StyledTypography>
            </StyledTableCell>
          </TableRow>
        ))}
        <TableRow>
          <StyledTableCell
            style={{ backgroundColor: "#DDDDDD" }}
            rowSpan={rowsSpan}
          >
            備考
          </StyledTableCell>
          <StyledTableCell rowSpan={rowsSpan} colSpan={columnsSpan}>
            <StyledTypography>{invoice.remarks}</StyledTypography>
          </StyledTableCell>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.subtotalTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>{subtotal?.toLocaleString()}</StyledTypography>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.taxTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>{tax?.toLocaleString()}</StyledTypography>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.advancePaidTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>
              {invoice.advancePaid?.toLocaleString()}
            </StyledTypography>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.totalTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>{total?.toLocaleString()}</StyledTypography>
          </StyledTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const InvoicePdfDistanceTable = () => {
  const invoiceEntity = useAppSelector(
    (state) => state.invoice.entities.invoices
  );
  const invoiceId = useAppSelector((state) => state.invoice.result[0]);
  const invoice = invoiceEntity[invoiceId];

  const rowsSpan = 4;

  const columnsSpan =
    1 +
    (invoice.customize1 ? 1 : 0) +
    (invoice.customize2 ? 1 : 0) +
    (invoice.customize3 ? 1 : 0);
  const subtotal =
    invoice.subtotal ||
    invoice.invoiceItems.reduce((sum, item) => sum + item.total, 0);
  const tax =
    invoice.tax ||
    invoice.invoiceItems.reduce((sum, item) => sum + item.total, 0) * 0.1;
  const total = invoice.total || subtotal - tax + (invoice.advancePaid || 0);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>日付</StyledTypography>
          </StyledTableCell>
          <StyledTableCell width={240} variant={"head"} align={"center"}>
            <StyledTypography>項目</StyledTypography>
          </StyledTableCell>
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>距離</StyledTypography>
          </StyledTableCell>
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>単価</StyledTypography>
          </StyledTableCell>
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>距離増</StyledTypography>
          </StyledTableCell>
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>距離増単価</StyledTypography>
          </StyledTableCell>
          {invoice.customize1 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize1}</StyledTypography>
            </StyledTableCell>
          )}
          {invoice.customize2 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize2}</StyledTypography>
            </StyledTableCell>
          )}
          {invoice.customize3 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize3}</StyledTypography>
            </StyledTableCell>
          )}
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>金額</StyledTypography>
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {invoice.invoiceItems.map((item, index) => (
          <TableRow key={item.id}>
            <StyledTableCell>
              <StyledTypography>{item.date}</StyledTypography>
            </StyledTableCell>
            <StyledTableCell>
              <StyledTypography>{item.text}</StyledTypography>
            </StyledTableCell>
            <StyledTableCell align={"right"}>
              <StyledTypography>{item.distance}</StyledTypography>
            </StyledTableCell>
            <StyledTableCell align={"right"}>
              <StyledTypography>
                {item.distanceUnitPrice?.toLocaleString()}
              </StyledTypography>
            </StyledTableCell>
            <StyledTableCell align={"right"}>
              <StyledTypography>{item.distanceOverTime}</StyledTypography>
            </StyledTableCell>
            <StyledTableCell align={"right"}>
              <StyledTypography>
                {item.distanceOverTimeUnitPrice?.toLocaleString()}
              </StyledTypography>
            </StyledTableCell>
            {invoice.customize1 && (
              <StyledTableCell>
                <StyledTypography>{item.customize1 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            {invoice.customize2 && (
              <StyledTableCell>
                <StyledTypography>{item.customize2 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            {invoice.customize3 && (
              <StyledTableCell>
                <StyledTypography>{item.customize3 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            <StyledTableCell align={"right"}>
              <StyledTypography>
                {item.total?.toLocaleString()}
              </StyledTypography>
            </StyledTableCell>
          </TableRow>
        ))}
        <TableRow>
          <StyledTableCell
            style={{ backgroundColor: "#DDDDDD" }}
            rowSpan={rowsSpan}
          >
            備考
          </StyledTableCell>
          <StyledTableCell rowSpan={rowsSpan} colSpan={columnsSpan}>
            <StyledTypography>{invoice.remarks}</StyledTypography>
          </StyledTableCell>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.subtotalTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>{subtotal?.toLocaleString()}</StyledTypography>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.taxTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>{tax?.toLocaleString()}</StyledTypography>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.advancePaidTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>
              {invoice.advancePaid?.toLocaleString()}
            </StyledTypography>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.totalTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>{total?.toLocaleString()}</StyledTypography>
          </StyledTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const InvoicePdfLocationTable = () => {
  const invoiceEntity = useAppSelector(
    (state) => state.invoice.entities.invoices
  );
  const invoiceId = useAppSelector((state) => state.invoice.result[0]);
  const invoice = invoiceEntity[invoiceId];

  const rowsSpan = 5;
  const columnsSpan =
    2 +
    (invoice.customize1 ? 1 : 0) +
    (invoice.customize2 ? 1 : 0) +
    (invoice.customize3 ? 1 : 0);
  const subtotal =
    invoice.subtotal ||
    invoice.invoiceItems.reduce((sum, item) => sum + item.total, 0);
  const tax =
    invoice.tax ||
    invoice.invoiceItems.reduce((sum, item) => sum + item.total, 0) * 0.1;
  const total = invoice.total || subtotal - tax + (invoice.advancePaid || 0);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>日付</StyledTypography>
          </StyledTableCell>
          <StyledTableCell width={240} variant={"head"} align={"center"}>
            <StyledTypography>項目</StyledTypography>
          </StyledTableCell>
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>件数</StyledTypography>
          </StyledTableCell>
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>単価</StyledTypography>
          </StyledTableCell>
          {invoice.customize1 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize1}</StyledTypography>
            </StyledTableCell>
          )}
          {invoice.customize2 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize2}</StyledTypography>
            </StyledTableCell>
          )}
          {invoice.customize3 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize3}</StyledTypography>
            </StyledTableCell>
          )}
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>金額</StyledTypography>
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {invoice.invoiceItems.map((item, index) => (
          <TableRow key={item.id}>
            <StyledTableCell>
              <StyledTypography>{item.date}</StyledTypography>
            </StyledTableCell>
            <StyledTableCell>
              <StyledTypography>{item.text}</StyledTypography>
            </StyledTableCell>
            <StyledTableCell align={"right"}>
              <StyledTypography>{item.location}</StyledTypography>
            </StyledTableCell>
            <StyledTableCell align={"right"}>
              <StyledTypography>
                {item.locationUnitPrice?.toLocaleString()}
              </StyledTypography>
            </StyledTableCell>
            {invoice.customize1 && (
              <StyledTableCell>
                <StyledTypography>{item.customize1 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            {invoice.customize2 && (
              <StyledTableCell>
                <StyledTypography>{item.customize2 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            {invoice.customize3 && (
              <StyledTableCell>
                <StyledTypography>{item.customize3 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            <StyledTableCell align={"right"}>
              <StyledTypography>
                {item.total?.toLocaleString()}
              </StyledTypography>
            </StyledTableCell>
          </TableRow>
        ))}
        <TableRow>
          <StyledTableCell
            style={{ backgroundColor: "#DDDDDD" }}
            rowSpan={rowsSpan}
          >
            備考
          </StyledTableCell>
          <StyledTableCell rowSpan={rowsSpan} colSpan={columnsSpan}>
            <StyledTypography>{invoice.remarks}</StyledTypography>
          </StyledTableCell>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.subtotalTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>{subtotal?.toLocaleString()}</StyledTypography>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.taxTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>{tax?.toLocaleString()}</StyledTypography>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.advancePaidTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>
              {invoice.advancePaid?.toLocaleString()}
            </StyledTypography>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.totalTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>{total?.toLocaleString()}</StyledTypography>
          </StyledTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const InvoicePdfPieceTable = () => {
  const invoiceEntity = useAppSelector(
    (state) => state.invoice.entities.invoices
  );
  const invoiceId = useAppSelector((state) => state.invoice.result[0]);
  const invoice = invoiceEntity[invoiceId];

  const rowsSpan = 4;

  const columnsSpan =
    2 +
    (invoice.customize1 ? 1 : 0) +
    (invoice.customize2 ? 1 : 0) +
    (invoice.customize3 ? 1 : 0);
  const subtotal =
    invoice.subtotal ||
    invoice.invoiceItems.reduce((sum, item) => sum + item.total, 0);
  const tax =
    invoice.tax ||
    invoice.invoiceItems.reduce((sum, item) => sum + item.total, 0) * 0.1;
  const total = invoice.total || subtotal - tax + (invoice.advancePaid || 0);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>日付</StyledTypography>
          </StyledTableCell>
          <StyledTableCell width={240} variant={"head"} align={"center"}>
            <StyledTypography>項目</StyledTypography>
          </StyledTableCell>
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>個数①</StyledTypography>
          </StyledTableCell>
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>単価①</StyledTypography>
          </StyledTableCell>
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>個数②</StyledTypography>
          </StyledTableCell>
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>単価②</StyledTypography>
          </StyledTableCell>
          {invoice.customize1 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize1}</StyledTypography>
            </StyledTableCell>
          )}
          {invoice.customize2 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize2}</StyledTypography>
            </StyledTableCell>
          )}
          {invoice.customize3 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize3}</StyledTypography>
            </StyledTableCell>
          )}
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>金額</StyledTypography>
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {invoice.invoiceItems.map((item, index) => (
          <TableRow key={item.id}>
            <StyledTableCell>
              <StyledTypography>{item.date}</StyledTypography>
            </StyledTableCell>
            <StyledTableCell>
              <StyledTypography>{item.text}</StyledTypography>
            </StyledTableCell>
            <StyledTableCell align={"right"}>
              <StyledTypography>{item.quantity1}</StyledTypography>
            </StyledTableCell>
            <StyledTableCell align={"right"}>
              <StyledTypography>
                {item.quantityUnitPrice1?.toLocaleString()}
              </StyledTypography>
            </StyledTableCell>
            <StyledTableCell align={"right"}>
              <StyledTypography>{item.quantity2}</StyledTypography>
            </StyledTableCell>
            <StyledTableCell align={"right"}>
              <StyledTypography>
                {item.quantityUnitPrice2?.toLocaleString()}
              </StyledTypography>
            </StyledTableCell>
            {invoice.customize1 && (
              <StyledTableCell>
                <StyledTypography>{item.customize1 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            {invoice.customize2 && (
              <StyledTableCell>
                <StyledTypography>{item.customize2 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            {invoice.customize3 && (
              <StyledTableCell>
                <StyledTypography>{item.customize3 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            <StyledTableCell align={"right"}>
              <StyledTypography>
                {item.total?.toLocaleString()}
              </StyledTypography>
            </StyledTableCell>
          </TableRow>
        ))}
        <TableRow>
          <StyledTableCell
            style={{ backgroundColor: "#DDDDDD" }}
            rowSpan={rowsSpan}
          >
            備考
          </StyledTableCell>
          <StyledTableCell rowSpan={rowsSpan} colSpan={columnsSpan}>
            <StyledTypography>{invoice.remarks}</StyledTypography>
          </StyledTableCell>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.subtotalTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>{subtotal?.toLocaleString()}</StyledTypography>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.taxTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>{tax?.toLocaleString()}</StyledTypography>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.advancePaidTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>
              {invoice.advancePaid?.toLocaleString()}
            </StyledTypography>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.totalTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>{total?.toLocaleString()}</StyledTypography>
          </StyledTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const InvoicePdfFreeTable = () => {
  const invoiceEntity = useAppSelector(
    (state) => state.invoice.entities.invoices
  );
  const invoiceId = useAppSelector((state) => state.invoice.result[0]);
  const invoice = invoiceEntity[invoiceId];

  const rowsSpan = 4;

  const columnsSpan =
    (invoice.customize1 ? 1 : 0) +
    (invoice.customize2 ? 1 : 0) +
    (invoice.customize3 ? 1 : 0) +
    (invoice.customize4 ? 1 : 0) +
    (invoice.customize5 ? 1 : 0) +
    (invoice.customize6 ? 1 : 0) +
    (invoice.customize7 ? 1 : 0) +
    (invoice.customize8 ? 1 : 0) +
    (invoice.customize9 ? 1 : 0) +
    (invoice.customize10 ? 1 : 0);
  const subtotal =
    invoice.subtotal ||
    invoice.invoiceItems.reduce((sum, item) => sum + item.total, 0);
  const tax =
    invoice.tax ||
    invoice.invoiceItems.reduce((sum, item) => sum + item.total, 0) * 0.1;
  const total = invoice.total || subtotal - tax + (invoice.advancePaid || 0);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>日付</StyledTypography>
          </StyledTableCell>
          <StyledTableCell width={240} variant={"head"} align={"center"}>
            <StyledTypography>項目</StyledTypography>
          </StyledTableCell>
          {invoice.customize1 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize1}</StyledTypography>
            </StyledTableCell>
          )}
          {invoice.customize2 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize2}</StyledTypography>
            </StyledTableCell>
          )}
          {invoice.customize3 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize3}</StyledTypography>
            </StyledTableCell>
          )}
          {invoice.customize4 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize4}</StyledTypography>
            </StyledTableCell>
          )}
          {invoice.customize5 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize5}</StyledTypography>
            </StyledTableCell>
          )}
          {invoice.customize6 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize6}</StyledTypography>
            </StyledTableCell>
          )}
          {invoice.customize7 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize7}</StyledTypography>
            </StyledTableCell>
          )}
          {invoice.customize8 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize8}</StyledTypography>
            </StyledTableCell>
          )}
          {invoice.customize9 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize9}</StyledTypography>
            </StyledTableCell>
          )}
          {invoice.customize10 && (
            <StyledTableCell align={"center"} variant={"head"}>
              <StyledTypography>{invoice.customize10}</StyledTypography>
            </StyledTableCell>
          )}
          <StyledTableCell variant={"head"} align={"center"}>
            <StyledTypography>金額</StyledTypography>
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {invoice.invoiceItems.map((item, index) => (
          <TableRow key={item.id}>
            <StyledTableCell>
              <StyledTypography>{item.date}</StyledTypography>
            </StyledTableCell>
            <StyledTableCell>
              <StyledTypography>{item.text}</StyledTypography>
            </StyledTableCell>
            {invoice.customize1 && (
              <StyledTableCell>
                <StyledTypography>{item.customize1 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            {invoice.customize2 && (
              <StyledTableCell>
                <StyledTypography>{item.customize2 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            {invoice.customize3 && (
              <StyledTableCell>
                <StyledTypography>{item.customize3 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            {invoice.customize4 && (
              <StyledTableCell>
                <StyledTypography>{item.customize4 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            {invoice.customize5 && (
              <StyledTableCell>
                <StyledTypography>{item.customize5 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            {invoice.customize6 && (
              <StyledTableCell>
                <StyledTypography>{item.customize6 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            {invoice.customize7 && (
              <StyledTableCell>
                <StyledTypography>{item.customize7 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            {invoice.customize8 && (
              <StyledTableCell>
                <StyledTypography>{item.customize8 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            {invoice.customize9 && (
              <StyledTableCell>
                <StyledTypography>{item.customize9 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            {invoice.customize10 && (
              <StyledTableCell>
                <StyledTypography>{item.customize10 || ""}</StyledTypography>
              </StyledTableCell>
            )}
            <StyledTableCell align={"right"}>
              <StyledTypography>
                {item.total?.toLocaleString()}
              </StyledTypography>
            </StyledTableCell>
          </TableRow>
        ))}
        <TableRow>
          <StyledTableCell
            style={{ backgroundColor: "#DDDDDD" }}
            rowSpan={rowsSpan}
          >
            備考
          </StyledTableCell>
          <StyledTableCell rowSpan={rowsSpan} colSpan={columnsSpan}>
            <StyledTypography>{invoice.remarks}</StyledTypography>
          </StyledTableCell>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.subtotalTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>{subtotal?.toLocaleString()}</StyledTypography>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.taxTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>{tax?.toLocaleString()}</StyledTypography>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.advancePaidTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>
              {invoice.advancePaid?.toLocaleString()}
            </StyledTypography>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell style={{ backgroundColor: "#DDDDDD" }}>
            {invoice.totalTitle}
          </StyledTableCell>
          <StyledTableCell align={"right"}>
            <StyledTypography>{total?.toLocaleString()}</StyledTypography>
          </StyledTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default IndividualInvoicePdf;
