import DetailRes from "types/res/DetailRes";
import { createSlice } from "@reduxjs/toolkit";
import {
  createDetail,
  deleteDetail,
  fetchAllDetail,
  fetchDetail,
  updateDetail,
} from "redux/actions/detail";

export type DetailState = {
  entities: { details: { [key: number]: DetailRes } };
  result: number[];
};
const initialState: DetailState = { entities: { details: {} }, result: [] };

const detailSlice = createSlice({
  name: "detail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllDetail.fulfilled, (_, { payload }) => ({
      entities: { details: { ...payload.entities.details } },
      result: payload.result,
    }));
    builder.addCase(fetchDetail.fulfilled, (_, { payload }) => ({
      entities: { details: { ...payload.entities.details } },
      result: [payload.result],
    }));
    builder.addCase(createDetail.fulfilled, (state, { payload }) => ({
      entities: {
        details: { ...state.entities.details, ...payload.entities.details },
      },
      result: [...state.result, payload.result],
    }));
    builder.addCase(updateDetail.fulfilled, (state, { payload }) => ({
      entities: {
        details: {
          ...state.entities.details,
          ...payload.entities.details,
        },
      },
      result: state.result,
    }));
    builder.addCase(deleteDetail.fulfilled, (state, { payload }) => {
      const entities = { ...state.entities.details };
      delete entities[payload];
      return {
        entities: {
          details: {
            ...entities,
          },
        },
        result: state.result.filter((id) => id !== payload),
      };
    });
  },
});

export default detailSlice.reducer;
