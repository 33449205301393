import { IsNotEmpty, IsOptional, IsString, MaxLength } from "class-validator";
import Spec from "constant/Spec";

export default class SupplierReq {
  @IsNotEmpty({
    message: "未入力です",
  })
  @MaxLength(Spec.maxLength.supplier.name, {
    message: "最大文字数を超えています",
  })
  @IsString()
  name!: string;

  @IsNotEmpty({
    message: "未入力です",
  })
  @MaxLength(Spec.maxLength.supplier.kana, {
    message: "最大文字数を超えています",
  })
  @IsString()
  kana!: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.supplier.invoiceNumber, {
    message: "最大文字数を超えています",
  })
  @IsString()
  invoiceNumber?: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.supplier.tel, {
    message: "最大文字数を超えています",
  })
  @IsString()
  tel?: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.supplier.email, {
    message: "最大文字数を超えています",
  })
  @IsString()
  email?: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.supplier.postCode, {
    message: "最大文字数を超えています",
  })
  @IsString()
  postCode?: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.supplier.address, {
    message: "最大文字数を超えています",
  })
  @IsString()
  address?: string;

  @IsOptional()
  registeredAt?: Date;

  @IsOptional()
  @MaxLength(Spec.maxLength.supplier.remarks, {
    message: "最大文字数を超えています",
  })
  @IsString()
  remarks?: string;

  constructor(arg: Partial<SupplierReq>) {
    Object.assign(this, arg);
  }
}
