import RequestUtils from "utils/RequestUtils";

export default class ImageUtils {
  public static async toBase64ImageUrl(imgUrl: string): Promise<string> {
    const fetchImageUrl = await RequestUtils.getBlob(imgUrl, false);
    return (await this.blobToBase64(fetchImageUrl)) as string;
  }

  public static async blobToBase64(blob: Blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
}
