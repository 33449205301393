import { IsNotEmpty, IsOptional, IsString, MaxLength } from "class-validator";
import { BankAccountType } from "types/enum/BankAccountType";
import Spec from "constant/Spec";

export default class AccountReq {
  @IsNotEmpty({
    message: "未入力です",
  })
  @IsString()
  @MaxLength(Spec.maxLength.account.name, {
    message: `最大文字数は${Spec.maxLength.account.name}文字です`,
  })
  name!: string;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsString()
  @MaxLength(Spec.maxLength.account.postCode, {
    message: `最大文字数は${Spec.maxLength.account.postCode}文字です`,
  })
  postCode!: string;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsString()
  @MaxLength(Spec.maxLength.account.address, {
    message: `最大文字数は${Spec.maxLength.account.address}文字です`,
  })
  address!: string;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsString()
  @MaxLength(Spec.maxLength.account.email, {
    message: `最大文字数は${Spec.maxLength.account.email}文字です`,
  })
  email!: string;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsString()
  @MaxLength(Spec.maxLength.account.invoiceNumber, {
    message: `最大文字数は${Spec.maxLength.account.invoiceNumber}文字です`,
  })
  invoiceNumber?: string;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsString()
  @MaxLength(Spec.maxLength.account.tel, {
    message: `最大文字数は${Spec.maxLength.account.tel}文字です`,
  })
  tel!: string;

  @IsOptional()
  @IsString()
  @MaxLength(Spec.maxLength.account.fax, {
    message: `最大文字数は${Spec.maxLength.account.fax}文字です`,
  })
  fax?: string;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsString()
  @MaxLength(Spec.maxLength.account.bankName, {
    message: `最大文字数は${Spec.maxLength.account.bankName}文字です`,
  })
  bankName1!: string;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsString()
  @MaxLength(Spec.maxLength.account.bankAccountName, {
    message: `最大文字数は${Spec.maxLength.account.bankAccountName}文字です`,
  })
  bankAccountName1!: string;

  @IsNotEmpty({
    message: "未入力です",
  })
  bankAccountType1!: BankAccountType;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsString()
  @MaxLength(Spec.maxLength.account.bankAccountNumber, {
    message: `最大文字数は${Spec.maxLength.account.bankAccountNumber}文字です`,
  })
  bankAccountNumber1!: string;

  @IsOptional()
  @IsString()
  @MaxLength(Spec.maxLength.account.bankName, {
    message: `最大文字数は${Spec.maxLength.account.bankName}文字です`,
  })
  bankName2?: string;

  @IsOptional()
  @IsString()
  @MaxLength(Spec.maxLength.account.bankAccountName, {
    message: `最大文字数は${Spec.maxLength.account.bankAccountName}文字です`,
  })
  bankAccountName2?: string;

  @IsOptional()
  bankAccountType2?: BankAccountType;

  @IsOptional()
  @IsString()
  @MaxLength(Spec.maxLength.account.bankAccountNumber, {
    message: `最大文字数は${Spec.maxLength.account.bankAccountNumber}文字です`,
  })
  bankAccountNumber2?: string;

  @IsOptional()
  @IsString()
  @MaxLength(Spec.maxLength.account.bankName, {
    message: `最大文字数は${Spec.maxLength.account.bankName}文字です`,
  })
  bankName3?: string;

  @IsOptional()
  @IsString()
  @MaxLength(Spec.maxLength.account.bankAccountName, {
    message: `最大文字数は${Spec.maxLength.account.bankAccountName}文字です`,
  })
  bankAccountName3?: string;

  @IsOptional()
  bankAccountType3?: BankAccountType;

  @IsOptional()
  @IsString()
  @MaxLength(Spec.maxLength.account.bankAccountNumber, {
    message: `最大文字数は${Spec.maxLength.account.bankAccountNumber}文字です`,
  })
  bankAccountNumber3?: string;

  constructor(arg: {
    name: string;
    postCode: string;
    address: string;
    email: string;
    invoiceNumber?: string;
    tel: string;
    fax?: string;
    bankName1: string;
    bankAccountName1: string;
    bankAccountType1: BankAccountType;
    bankAccountNumber1: string;
    bankName2?: string;
    bankAccountName2?: string;
    bankAccountType2?: BankAccountType;
    bankAccountNumber2?: string;
    bankName3?: string;
    bankAccountName3?: string;
    bankAccountType3?: BankAccountType;
    bankAccountNumber3?: string;
  }) {
    arg = arg || {};
    this.name = arg.name;
    this.postCode = arg.postCode;
    this.address = arg.address;
    this.email = arg.email;
    this.invoiceNumber = arg.invoiceNumber;
    this.tel = arg.tel;
    this.fax = arg.fax;
    this.bankName1 = arg.bankName1;
    this.bankAccountName1 = arg.bankAccountName1;
    this.bankAccountType1 = arg.bankAccountType1;
    this.bankAccountNumber1 = arg.bankAccountNumber1;
    this.bankName2 = arg.bankName2;
    this.bankAccountName2 = arg.bankAccountName2;
    this.bankAccountType2 = arg.bankAccountType2;
    this.bankAccountNumber2 = arg.bankAccountNumber2;
    this.bankName3 = arg.bankName3;
    this.bankAccountName3 = arg.bankAccountName3;
    this.bankAccountType3 = arg.bankAccountType3;
    this.bankAccountNumber3 = arg.bankAccountNumber3;
  }
}
