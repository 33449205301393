import { IsNotEmpty, IsNumber, IsOptional, IsString } from "class-validator";

export default class InvoiceItemReq {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsNumber()
  invoiceId!: number;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsNumber()
  total!: number;

  @IsOptional()
  @IsString()
  text?: string;

  @IsOptional()
  @IsString()
  date?: string;

  @IsOptional()
  @IsNumber()
  dateNum?: number;

  @IsOptional()
  @IsNumber()
  fare?: number;

  @IsOptional()
  @IsNumber()
  distance?: number;

  @IsOptional()
  @IsNumber()
  distanceUnitPrice?: number;

  @IsOptional()
  @IsNumber()
  distanceOverTime?: number;

  @IsOptional()
  @IsNumber()
  distanceOverTimeUnitPrice?: number;

  @IsOptional()
  @IsNumber()
  location?: number;

  @IsOptional()
  @IsNumber()
  locationUnitPrice?: number;

  @IsOptional()
  @IsNumber()
  quantity1?: number;

  @IsOptional()
  @IsNumber()
  quantity2?: number;

  @IsOptional()
  @IsNumber()
  quantityUnitPrice1?: number;

  @IsOptional()
  @IsNumber()
  quantityUnitPrice2?: number;

  @IsOptional()
  @IsNumber()
  customize1?: number;

  @IsOptional()
  @IsNumber()
  customize2?: number;

  @IsOptional()
  @IsNumber()
  customize3?: number;

  @IsOptional()
  @IsNumber()
  customize4?: number;

  @IsOptional()
  @IsNumber()
  customize5?: number;

  @IsOptional()
  @IsNumber()
  customize6?: number;

  @IsOptional()
  @IsNumber()
  customize7?: number;

  @IsOptional()
  @IsNumber()
  customize8?: number;

  @IsOptional()
  @IsNumber()
  customize9?: number;

  @IsOptional()
  @IsNumber()
  customize10?: number;

  constructor(arg: Partial<InvoiceItemReq>) {
    Object.assign(this, arg);
  }
}
