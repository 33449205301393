import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  createDetail,
  deleteDetail,
  fetchAllDetail,
  updateDetail,
} from "redux/actions/detail";
import { fetchAllSupplier } from "redux/actions/supplier";
import DetailRes from "types/res/DetailRes";
import { FormType } from "components/FormField";
import { Box, Button, TextField } from "@mui/material";
import DetailReq from "types/req/DetailReq";
import MultipleSelect from "components/MultipleSelect";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import { fetchAllDriver } from "redux/actions/driver";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import EnumUtils from "utils/EnumUtils";
import { RewardType } from "types/enum/RewardType";
import { useNavigate } from "react-router-dom";
import DetailApi from "api/DetailApi";
const Detail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [targetMonth, setTargetMonth] = useState(new Date());
  const [selectDriverIds, setSelectDriverIds] = useState<string[]>([]);
  const driverIds = useAppSelector((state) => state.driver.result);
  const driverEntities = useAppSelector(
    (state) => state.driver.entities.drivers
  );
  const drivers = driverIds.map((id) => driverEntities[id]);

  const detailIds = useAppSelector((state) => state.detail.result);
  const detailEntities = useAppSelector(
    (state) => state.detail.entities.details
  );
  const details = detailIds.map((id) => detailEntities[id]);
  useEffect(() => {
    dispatch(fetchAllDriver({ req: { limit: 100, offset: 0 } }));
    dispatch(fetchAllSupplier({ req: { limit: 1000, offset: 0 } }));
    const apiCall = async () => {
      const result = await DetailApi.count({
        limit: 0,
        offset: 0,
        targetMonth: DateTime.fromJSDate(targetMonth).toFormat(
          FORMAT_TYPE.YEAR_DAY
        ),
      });
      setTotalCount(result);
    };
    apiCall();
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchAllDetail({
        req: {
          limit,
          offset,
          targetMonth: DateTime.fromJSDate(targetMonth).toFormat(
            FORMAT_TYPE.YEAR_DAY
          ),
        },
      })
    );
  }, [limit, offset]);
  const headers: { key: keyof DetailRes; label: string }[] = [
    { key: "driverId", label: "ドライバー" },
    { key: "targetMonth", label: "対象月" },
    { key: "detailItems", label: "勤務日数" },
    { key: "detailItems", label: "車建" },
    { key: "detailItems", label: "件建" },
    { key: "detailItems", label: "個建" },
    { key: "detailItems", label: "距離" },
    { key: "detailItems", label: "車建合計" },
    { key: "detailItems", label: "件建合計" },
    { key: "detailItems", label: "個建合計" },
    { key: "detailItems", label: "距離合計" },
    { key: "subtotal", label: "小計" },
    { key: "tax", label: "消費税" },
    { key: "vehicleFee", label: "車両代(税込)" },
    { key: "advancePaid", label: "経費(税込)" },
    { key: "total", label: "合計" },
    { key: "driver", label: "" },
    { key: "driver", label: "" },
  ];

  const forms = [
    {
      label: "ドライバー名",
      key: "driverId",
      type: FormType.AutoCompleteSelect,
      options: drivers,
    },
    {
      label: "対象月",
      key: "targetMonth",
      type: FormType.DateString,
    },
  ];

  const onClickSearch = async () => {
    dispatch(
      fetchAllDetail({
        req: {
          limit,
          offset: 0,
          targetMonth: DateTime.fromJSDate(targetMonth).toFormat(
            FORMAT_TYPE.YEAR_DAY
          ),
          driverIds: selectDriverIds.includes("すべて")
            ? undefined
            : selectDriverIds.map((id) => Number(id)),
        },
      })
    );
    const result = await DetailApi.count({
      limit: 0,
      offset: 0,
      targetMonth: DateTime.fromJSDate(targetMonth).toFormat(
        FORMAT_TYPE.YEAR_DAY
      ),
      driverIds: selectDriverIds.includes("すべて")
        ? undefined
        : selectDriverIds.map((id) => Number(id)),
    });
    setTotalCount(result);
  };
  return (
    <Box display="flex" flexDirection="column">
      <Header
        targetMonth={targetMonth}
        setTargetMonth={setTargetMonth}
        selectedDriverIds={selectDriverIds}
        setSelectedDriverIds={setSelectDriverIds}
        onClickSearch={onClickSearch}
      />
      <DetailTable
        formId={"detail"}
        data={details}
        fullWidth
        addFunc={(formData) => createDetail({ req: formData })}
        addType={DetailReq}
        totalCount={totalCount}
        values={[
          (s) => s.driver.name,
          (s) =>
            DateTime.fromFormat(s.targetMonth, FORMAT_TYPE.YEAR_DAY).toFormat(
              FORMAT_TYPE.YEAR_MONTH
            ),
          (s) => s.detailItems.length,
          (s) =>
            s.detailItems.filter(
              (detailItem) =>
                EnumUtils.mapToEnum(
                  RewardType,
                  detailItem.dailyReport?.deal.rewardType
                ) === RewardType.car
            ).length,
          (s) =>
            s.detailItems.filter(
              (detailItem) =>
                EnumUtils.mapToEnum(
                  RewardType,
                  detailItem.dailyReport?.deal.rewardType
                ) === RewardType.location
            ).length,
          (s) =>
            s.detailItems.filter(
              (detailItem) =>
                EnumUtils.mapToEnum(
                  RewardType,
                  detailItem.dailyReport?.deal.rewardType
                ) === RewardType.piece
            ).length,
          (s) =>
            s.detailItems.filter(
              (detailItem) =>
                EnumUtils.mapToEnum(
                  RewardType,
                  detailItem.dailyReport?.deal.rewardType
                ) === RewardType.distance
            ).length,
          (s) =>
            s.detailItems
              .filter(
                (detailItem) =>
                  EnumUtils.mapToEnum(
                    RewardType,
                    detailItem.dailyReport?.deal.rewardType
                  ) === RewardType.car
              )
              .reduce((sum, detailItem) => sum + detailItem.total, 0),
          (s) =>
            s.detailItems
              .filter(
                (detailItem) =>
                  EnumUtils.mapToEnum(
                    RewardType,
                    detailItem.dailyReport?.deal.rewardType
                  ) === RewardType.location
              )
              .reduce((sum, detailItem) => sum + detailItem.total, 0),

          (s) =>
            s.detailItems
              .filter(
                (detailItem) =>
                  EnumUtils.mapToEnum(
                    RewardType,
                    detailItem.dailyReport?.deal.rewardType
                  ) === RewardType.piece
              )
              .reduce((sum, detailItem) => sum + detailItem.total, 0),

          (s) =>
            s.detailItems
              .filter(
                (detailItem) =>
                  EnumUtils.mapToEnum(
                    RewardType,
                    detailItem.dailyReport?.deal.rewardType
                  ) === RewardType.distance
              )
              .reduce((sum, detailItem) => sum + detailItem.total, 0),
          (s) =>
            Math.trunc(
              s.subtotal ||
                s.detailItems.reduce(
                  (sum, detailItem) => sum + detailItem.total,
                  0
                )
            ),
          (s) =>
            Math.trunc(
              s.tax ||
                s.detailItems.reduce(
                  (sum, detailItem) => sum + detailItem.total,
                  0
                ) * 0.1
            ),
          (s) => s.vehicleFee || "",
          (s) =>
            s.advancePaid ||
            s.detailItems.reduce((sum, item) => sum + (item.expenses || 0), 0),
          (s) =>
            Math.trunc(
              s.total ||
                s.detailItems.reduce(
                  (sum, detailItem) => sum + detailItem.total,
                  0
                ) * 1.1
            ),
          (s) => (
            <Button
              variant="contained"
              color={s.isNotify ? "inherit" : "secondary"}
              disabled={s.isNotify}
              onClick={() => {
                dispatch(
                  updateDetail({ id: s.id, req: { ...s, isNotify: true } })
                );
              }}
            >
              通知
            </Button>
          ),
          (s) => (
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(`/detail/${s.id}`)}
            >
              詳細
            </Button>
          ),
        ]}
        limit={limit}
        setLimit={setLimit}
        offset={offset}
        setOffset={setOffset}
        deleteFunc={(formData) => deleteDetail({ id: formData.id })}
        title={"明細"}
        rows={headers}
        forms={forms}
      />
    </Box>
  );
};

type HeaderProps = {
  targetMonth: Date;
  setTargetMonth: React.Dispatch<React.SetStateAction<Date>>;
  selectedDriverIds: string[];
  setSelectedDriverIds: React.Dispatch<React.SetStateAction<string[]>>;
  onClickSearch: () => Promise<void>;
};
const Header = ({
  targetMonth,
  setTargetMonth,
  selectedDriverIds,
  setSelectedDriverIds,
  onClickSearch,
}: HeaderProps) => {
  const driverIds = useAppSelector((state) => state.driver.result);
  const driverEntities = useAppSelector(
    (state) => state.driver.entities.drivers
  );
  const drivers = driverIds.map((id) => driverEntities[id]);

  useEffect(() => {
    setSelectedDriverIds([
      ...drivers.map((driver) => String(driver.id)),
      "すべて",
    ]);
  }, [driverIds]);

  return (
    <Box display="flex" margin={2} alignItems={"flex-end"}>
      <Box marginLeft={1}>
        <TextField
          label={"対象月"}
          type={"month"}
          lang={"jaJP"}
          value={DateTime.fromJSDate(targetMonth).toFormat(
            FORMAT_TYPE.YEAR_MONTH
          )}
          onChange={(e) => {
            setTargetMonth(
              DateTime.fromFormat(
                e.target.value,
                FORMAT_TYPE.YEAR_MONTH
              ).toJSDate()
            );
          }}
        />
      </Box>
      <Box marginLeft={1}>
        <MultipleSelect
          selected={selectedDriverIds}
          setSelected={setSelectedDriverIds}
          id={"select-multiple-driver"}
          options={drivers}
          label={"ドライバー"}
        />
      </Box>
      <Box marginLeft={1}>
        <Button variant="contained" color="secondary" onClick={onClickSearch}>
          検索
        </Button>
      </Box>
    </Box>
  );
};

const DetailTable = styled<React.FC<CommonTableProps<DetailRes, DetailReq>>>(
  CommonTable
)`
  margin-top: 24px;
`;

export default Detail;
