import BaseRes from "types/res/BaseRes";
import { Expose, Type } from "class-transformer";
import SupplierRes from "types/res/SupplierRes";
import { RewardType } from "types/enum/RewardType";
import DriverRes from "types/res/DriverRes";

export default class DealRes extends BaseRes {
  @Expose() readonly supplierId!: number;
  @Type(() => SupplierRes)
  @Expose()
  readonly supplier!: SupplierRes;
  @Type(() => DriverRes)
  @Expose()
  readonly drivers!: DriverRes[];
  @Expose() readonly name!: string;
  @Expose() readonly rewardType!: RewardType;
  @Expose() readonly remarks?: string;
  @Expose() readonly companyProfit1?: number;
  @Expose() readonly driverSalary1?: number;
  @Expose() readonly billingAmount1?: number;
  @Expose() readonly companyProfit2?: number;
  @Expose() readonly driverSalary2?: number;
  @Expose() readonly billingAmount2?: number;
  @Expose() readonly overtimeMoneyCompanyProfit?: number;
  @Expose() readonly overtimeMoneyDriverSalary?: number;
  @Expose() readonly overtimeMoneyBillingAmount?: number;
  @Expose() readonly distanceMoneyCompanyProfit?: number;
  @Expose() readonly distanceMoneyDriverSalary?: number;
  @Expose() readonly distanceMoneyBillingAmount?: number;
}
