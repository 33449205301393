import modalActions from "redux/actions/modals";
import { useAppDispatch, useAppSelector } from "redux/hooks";

const useModal = (
  id: string
): [{ show: boolean; meta?: any }, (show: boolean, meta?: any) => void] => {
  const dispatch = useAppDispatch();
  const modal = useAppSelector((state) => state.modals[id]);
  const setShow = (show: boolean, meta?: any) =>
    dispatch(modalActions.setModalShow({ id, show, meta }));

  return [modal, setShow];
};

export default useModal;
