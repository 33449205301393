import React, { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";

export enum FormType {
  Text,
  TextArea,
  Number,
  Date,
  DateString,
  DateTime,
  MonthString,
  Time,
  Boolean,
  Select,
  MultiOptions,
  AutoCompleteMultiOptions,
  AutoCompleteSelect,
  None,
}

type FormProps = {
  formKey: string;
  formType: FormType;
  label: string;
  options?: any[];
  disabled?: boolean;
  unSelectOptions?: any[];
  formData: any;
  setFormData: (a: any) => any;
  loadMoreResults?: any;
  error?: string[];
  optionFilterKey?: string;
  optionFilterFunc?: (a: any, input: any) => any;
};

const FormField = ({
  formKey,
  formType,
  label,
  options,
  disabled,
  unSelectOptions,
  formData,
  setFormData,
  loadMoreResults,
  error,
  optionFilterFunc,
  optionFilterKey,
}: FormProps) => {
  const [open, setOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);
  const textFieldRef = useRef<any>();
  useEffect(() => {
    setFilteredOptions(options || []);
  }, [options]);
  useEffect(() => {
    if (!optionFilterKey || !optionFilterFunc || !formData?.[optionFilterKey])
      return;
    setFilteredOptions(
      options?.filter((option) =>
        optionFilterFunc(option, formData[optionFilterKey])
      ) || []
    );
  }, [optionFilterKey, optionFilterFunc, formData]);
  useEffect(() => {
    const handleWheel = (e: any) => e.preventDefault();
    if (!textFieldRef.current) return;
    textFieldRef.current.addEventListener("wheel", handleWheel);

    return () => {
      if (!textFieldRef.current) return;
      textFieldRef.current.removeEventListener("wheel", handleWheel);
    };
  }, []);

  const onChangeForm =
    (key: string, handler: (value: any) => any = (value: any) => value) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      event.persist();
      setFormData((prev: any) => ({
        ...prev,
        [key]: handler(event.target.value),
      }));
    };

  const onChangeSelect =
    (key: string) => (event: SelectChangeEvent<number>) => {
      setFormData((prev: any) => ({
        ...prev,
        [key]: event.target.value,
      }));
    };

  const onChangeMultiOptions =
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!options) return;
      event.persist();
      if (event.target.checked) {
        setFormData((prev: any) => {
          const current = prev[key] || [];
          return {
            ...prev,
            [key]: [...current, Number(event.target.id)],
          };
        });
      } else {
        setFormData((prev: any) => ({
          ...prev,
          [key]: prev[key].filter(
            (id: number) => id !== Number(event.target.id)
          ),
        }));
      }
    };

  const onChangeAutoCompleteMultiOptions =
    (key: string) => (event: React.ChangeEvent<{}>, value: any) => {
      event.persist();
      setFormData((prev: any) => ({
        ...prev,
        [key]: value.map((t: any) => t.id),
      }));
    };

  const onChangeAutoCompleteSelect =
    (key: string) => (event: React.ChangeEvent<{}>, value: any) => {
      event.persist();
      setFormData((prev: any) => ({
        ...prev,
        [key]: value?.id,
      }));
    };

  const onBlurTel =
    (key: string) =>
    (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | { value: unknown }
      >
    ) => {
      if (key !== "tel") return;
      event.persist();
      setFormData((prev: any) => ({
        ...prev,
        tel: (event.target.value as string).replace(/[-‐－―ー−]/g, ""),
      }));
    };

  switch (formType) {
    case FormType.Text:
      return (
        <TextField
          error={Boolean(error)}
          label={label}
          variant="outlined"
          value={formData[formKey] || ""}
          fullWidth
          onChange={onChangeForm(formKey)}
          onBlur={onBlurTel(formKey)}
          helperText={error && error.join("")}
        />
      );
    case FormType.Number:
      return (
        <TextField
          error={Boolean(error)}
          label={label}
          type="number"
          ref={textFieldRef}
          variant="outlined"
          value={formData[formKey] || null}
          fullWidth
          onChange={onChangeForm(formKey, (value) =>
            value === null || undefined || "" ? null : Number(value)
          )}
          helperText={error && error.join("")}
        />
      );
    case FormType.Date:
      return (
        <TextField
          error={Boolean(error)}
          label={label}
          type="date"
          variant="outlined"
          value={DateTimeUtils.toFormatAsLocalTimezone(
            formData[formKey],
            FORMAT_TYPE.YEAR_DAY
          )}
          InputLabelProps={{ shrink: true }}
          fullWidth
          onChange={onChangeForm(formKey, (value) => new Date(value))}
          helperText={error && error.join("")}
        />
      );
    case FormType.DateString:
      return (
        <TextField
          error={Boolean(error)}
          label={label}
          type="date"
          variant="outlined"
          value={formData[formKey]}
          InputLabelProps={{ shrink: true }}
          fullWidth
          onChange={onChangeForm(formKey, (value) =>
            DateTime.fromJSDate(new Date(value)).toFormat(FORMAT_TYPE.YEAR_DAY)
          )}
          helperText={error && error.join("")}
        />
      );
    case FormType.DateTime:
      return (
        <TextField
          label={label}
          type="datetime-local"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          defaultValue={
            formData[formKey]
              ? DateTime.fromJSDate(formData[formKey]).toISO({
                  includeOffset: false,
                })
              : ""
          }
          fullWidth
          onChange={onChangeForm(formKey, (value) => new Date(value))}
          error={Boolean(error)}
          helperText={error && error.join("")}
        />
      );
    case FormType.MonthString:
      return (
        <TextField
          error={Boolean(error)}
          label={label}
          type="month"
          variant="outlined"
          value={formData[formKey]}
          InputLabelProps={{ shrink: true }}
          fullWidth
          onChange={onChangeForm(formKey, (value) =>
            DateTime.fromJSDate(new Date(value)).toFormat(
              FORMAT_TYPE.YEAR_MONTH
            )
          )}
          helperText={error && error.join("")}
        />
      );
    case FormType.Time:
      return (
        <TextField
          label={label}
          type="time"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={formData[formKey]}
          fullWidth
          onChange={onChangeForm(formKey)}
          error={Boolean(error)}
          helperText={error && error.join("")}
        />
      );

    case FormType.TextArea:
      return (
        <TextField
          label={label}
          type="textarea"
          variant="outlined"
          defaultValue={formData[formKey] || ""}
          fullWidth
          multiline
          rows={3}
          onChange={onChangeForm(formKey)}
          error={Boolean(error)}
          helperText={error && error.join("")}
        />
      );
    case FormType.Boolean:
      return (
        <>
          <FormLabel error={Boolean(error)} component="legend">
            {label}
          </FormLabel>
          <RadioGroup
            row
            aria-label="editCallCenterContract"
            name="editCallCenterContract"
            onChange={onChangeForm(formKey, (value) => value === "1")}
            value={formData[formKey]}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label={filteredOptions?.[1]?.name}
              checked={formData[formKey] === true}
            />
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label={filteredOptions?.[0]?.name}
              checked={formData[formKey] === false}
            />
          </RadioGroup>
          {error && <FormHelperText error>{error.join("")}</FormHelperText>}
        </>
      );
    case FormType.Select:
      return (
        <>
          <InputLabel error={Boolean(error)} htmlFor={formKey}>
            {label}
          </InputLabel>
          <Select
            error={Boolean(error)}
            onChange={onChangeSelect(formKey)}
            inputProps={{
              name: formKey,
              id: formKey,
            }}
            fullWidth
            value={formData[formKey] || ""}
          >
            <MenuItem value={""}>
              <Typography color="textSecondary">選択を外す</Typography>
            </MenuItem>
            {filteredOptions?.map((option) => (
              <MenuItem
                value={option.id}
                key={option.id}
                disabled={unSelectOptions?.includes(option.id)}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText error>{error.join("")}</FormHelperText>}
        </>
      );

    case FormType.MultiOptions:
      return (
        <>
          <InputLabel error={Boolean(error)} htmlFor={formKey}>
            {label}
          </InputLabel>
          {filteredOptions?.map((option) => (
            <FormControlLabel
              control={
                <Checkbox
                  name={option.name}
                  onChange={onChangeMultiOptions(formKey)}
                  checked={formData[formKey]?.includes(option.id) ?? false}
                  id={`${option.id}`}
                />
              }
              disabled={disabled}
              key={option.id}
              label={option.name}
            />
          ))}
          {error && <FormHelperText error>{error.join("")}</FormHelperText>}
        </>
      );
    case FormType.AutoCompleteMultiOptions: {
      const allSelected =
        filteredOptions?.length ===
        filteredOptions?.filter((option) =>
          formData[formKey]?.includes(option?.id)
        ).length;

      const checkAllChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        key: string
      ) => {
        event.persist();
        if (event.target.checked) {
          setFormData((prev: any) => ({
            ...prev,
            [key]: filteredOptions?.map((t: any) => t.id) || [],
          }));
        } else {
          setFormData((prev: any) => ({
            ...prev,
            [key]: [],
          }));
        }
      };

      return (
        <Autocomplete
          multiple
          options={filteredOptions || []}
          getOptionLabel={(option) => option.name || ""}
          value={filteredOptions?.filter((option) =>
            formData[formKey]?.includes(option?.id)
          )}
          filterSelectedOptions
          onChange={onChangeAutoCompleteMultiOptions(formKey)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={label} />
          )}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={(event, reason) => {
            if (reason === "escape" || reason === "blur") {
              setOpen(false);
            }
          }}
          noOptionsText="選択肢がありません"
        />
      );
    }
    case FormType.AutoCompleteSelect: {
      return (
        <Autocomplete
          options={filteredOptions || []}
          getOptionLabel={(option) => option.name || ""}
          value={
            filteredOptions?.find(
              (option) => formData[formKey] === option?.id
            ) || ""
          }
          filterOptions={(filterOptions, state) =>
            filterOptions.filter((o) => o.name.includes(state.inputValue))
          }
          onChange={onChangeAutoCompleteSelect(formKey)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={label} />
          )}
          onInputChange={async (event, value) => {
            event?.persist();
            if (loadMoreResults) {
              await loadMoreResults(value);
            }
          }}
          open={open}
          ListboxProps={{
            onScroll: (event: React.SyntheticEvent) => {
              const listBoxNode = event.currentTarget;
              if (
                listBoxNode.scrollTop + listBoxNode.clientHeight >
                  listBoxNode.scrollHeight - 1 &&
                loadMoreResults
              ) {
                loadMoreResults();
              }
            },
          }}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          noOptionsText="選択肢がありません"
        />
      );
    }
    case FormType.None:
      return null;
    default: {
      const _: never = formType;
      throw new Error(_);
    }
  }
};

export default FormField;
