import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
} from "class-validator";
import { BankAccountType } from "types/enum/BankAccountType";
import { HonorificTitle } from "types/enum/HonorificTitle";

export default class DetailReq {
  @IsNotEmpty({
    message: "未入力です",
  })
  @IsNumber()
  driverId!: number;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsString()
  targetMonth!: string;

  @IsOptional()
  @IsString()
  title?: string;

  @IsOptional()
  @IsString()
  payDate?: string;

  @IsOptional()
  @IsString()
  honorificTitle?: HonorificTitle;

  @IsOptional()
  @IsString()
  driverInvoiceNumber?: string;

  @IsOptional()
  @IsString()
  driverPostCode?: string;

  @IsOptional()
  @IsString()
  driverAddress?: string;

  @IsOptional()
  @IsString()
  driverBankName?: string;

  @IsOptional()
  @IsString()
  driverBankAccountName?: string;

  @IsOptional()
  @IsString()
  driverBankAccountType?: BankAccountType;

  @IsOptional()
  @IsString()
  driverBankAccountNumber?: string;

  @IsOptional()
  @IsString()
  accountName?: string;

  @IsOptional()
  @IsString()
  accountPostCode?: string;

  @IsOptional()
  @IsString()
  accountAddress?: string;

  @IsOptional()
  @IsString()
  accountTel?: string;

  @IsOptional()
  @IsString()
  accountFax?: string;

  @IsOptional()
  @IsNumber()
  subtotal?: number;

  @IsOptional()
  @IsString()
  betweenSubtotalAndTaxText?: string;

  @IsOptional()
  @IsNumber()
  betweenSubtotalAndTax?: number;

  @IsOptional()
  @IsNumber()
  tax?: number;

  @IsOptional()
  @IsNumber()
  advancePaid?: number;

  @IsOptional()
  @IsNumber()
  vehicleFee?: number;

  @IsOptional()
  @IsString()
  betweenVehicleFeeAndTotalText1?: string;

  @IsOptional()
  @IsString()
  betweenVehicleFeeAndTotalText2?: string;

  @IsOptional()
  @IsString()
  betweenVehicleFeeAndTotalText3?: string;

  @IsOptional()
  @IsNumber()
  betweenVehicleFeeAndTotal1?: number;

  @IsOptional()
  @IsNumber()
  betweenVehicleFeeAndTotal2?: number;

  @IsOptional()
  @IsNumber()
  betweenVehicleFeeAndTotal3?: number;

  @IsOptional()
  @IsNumber()
  total?: number;

  @IsOptional()
  @IsBoolean()
  isNotify?: boolean;

  @IsOptional()
  @IsBoolean()
  isRead?: boolean;

  @IsOptional()
  @IsString()
  remarks?: string;

  @IsOptional()
  @IsString()
  customize1?: string;

  @IsOptional()
  @IsString()
  customize2?: string;

  @IsOptional()
  @IsString()
  subtotalTitle?: string;

  @IsOptional()
  @IsString()
  taxTitle?: string;

  @IsOptional()
  @IsString()
  advancePaidTitle?: string;

  @IsOptional()
  @IsString()
  vehicleFeeTitle?: string;

  @IsOptional()
  @IsString()
  totalTitle?: string;

  constructor(arg: Partial<DetailReq>) {
    Object.assign(this, arg);
  }
}
