import RequestUtils from "utils/RequestUtils";
import InvoiceItemReq from "types/req/InvoiceItemReq";
export default class InvoiceItemApi {
  static async create(req: InvoiceItemReq[]) {
    return await RequestUtils.postVoid(`/management/invoice-item`, req);
  }

  static async update(req: InvoiceItemReq[]) {
    return await RequestUtils.putVoid(`/management/invoice-item`, req);
  }

  static async delete(id: number) {
    return await RequestUtils.delete(`/management/invoice-item/${id}`);
  }
}
