export default class EnumUtils {
  private constructor() {}
  public static mapToEnum<T>(
    enumObject: T,
    value: any
  ): T[keyof T] | undefined {
    if (typeof enumObject === "object") {
      for (const key in enumObject) {
        if (key === value) {
          return enumObject[key];
        }
      }
    } else if (enumObject instanceof Array) {
      return enumObject.find(value);
    }
  }
}
