import { IsNotEmpty, IsNumber, IsOptional, IsString } from "class-validator";

export default class DetailItemReq {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsNumber()
  detailId!: number;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsNumber()
  dealId!: number;

  @IsOptional()
  @IsNumber()
  dailyReportId?: number;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsString()
  date!: string;

  @IsOptional()
  @IsNumber()
  distance?: number;

  @IsOptional()
  @IsNumber()
  bringOut1?: number;

  @IsOptional()
  @IsNumber()
  bringOut2?: number;

  @IsOptional()
  @IsNumber()
  deliveryComplete1?: number;

  @IsOptional()
  @IsNumber()
  deliveryComplete2?: number;

  @IsOptional()
  @IsNumber()
  bringOutLocation?: number;

  @IsOptional()
  @IsNumber()
  deliveryCompleteLocation?: number;

  @IsOptional()
  @IsNumber()
  overTime?: number;

  @IsOptional()
  @IsNumber()
  expenses?: number;

  @IsNotEmpty({
    message: "未入力です",
  })
  @IsNumber()
  total!: number;

  @IsOptional()
  @IsNumber()
  customize1?: number;

  @IsOptional()
  @IsString()
  customize2?: string;

  constructor(arg: Partial<DetailItemReq>) {
    Object.assign(this, arg);
  }
}
