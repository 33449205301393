import { BaseError } from "errors/BaseError";

export default class HttpRequestError extends Error implements BaseError {
  public statusCode: number;

  constructor(m: string, statusCode: number) {
    super(m);
    this.statusCode = statusCode;
  }

  needAlert(): boolean {
    return true;
  }

  getMessage(): string {
    return this.getErrorDefaultText()
      ? this.getErrorDefaultText()
      : this.message;
  }

  private getErrorDefaultText() {
    switch (this.statusCode) {
      case 401:
        return "認証エラーです。権限がありません。";
      case 404:
        return "お探しのページが見つかりません。";
      case 500:
        return "システムエラーが発生しました。";
      case 400:
      default:
        return "ブラウザ上のデータが一部古い可能性があります。ブラウザをリロードまたキャッシュをクリアしてください。";
    }
  }
}
