import {
  IsArray,
  IsDate,
  IsNotEmpty,
  IsOptional,
  IsString,
  MaxLength,
} from "class-validator";
import Spec from "constant/Spec";
import { BankAccountType } from "types/enum/BankAccountType";

export default class DriverReq {
  @IsNotEmpty({
    message: "未入力です",
  })
  @MaxLength(Spec.maxLength.driver.name, {
    message: "最大文字数を超えています",
  })
  @IsString()
  name!: string;

  @IsNotEmpty({
    message: "未入力です",
  })
  @MaxLength(Spec.maxLength.driver.kana, {
    message: "最大文字数を超えています",
  })
  @IsString()
  kana!: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.driver.invoiceNumber, {
    message: "最大文字数を超えています",
  })
  @IsString()
  invoiceNumber?: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.driver.tel, {
    message: "最大文字数を超えています",
  })
  @IsString()
  tel?: string;

  @IsNotEmpty({
    message: "未入力です",
  })
  @MaxLength(Spec.maxLength.driver.email, {
    message: "最大文字数を超えています",
  })
  @IsString()
  email!: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.driver.postCode, {
    message: "最大文字数を超えています",
  })
  @IsString()
  postCode?: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.driver.address, {
    message: "最大文字数を超えています",
  })
  @IsString()
  address?: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.driver.remarks, {
    message: "最大文字数を超えています",
  })
  @IsString()
  remarks?: string;

  @IsOptional()
  @IsDate()
  registeredAt?: Date;

  @IsOptional()
  @IsArray()
  suppliers?: number[];

  @IsOptional()
  @MaxLength(Spec.maxLength.driver.bankName, {
    message: "最大文字数を超えています",
  })
  @IsString()
  bankName?: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.driver.bankAccountName, {
    message: "最大文字数を超えています",
  })
  @IsString()
  bankAccountName?: string;

  @IsOptional()
  bankAccountType?: BankAccountType;

  @IsOptional()
  @MaxLength(Spec.maxLength.driver.bankAccountNumber, {
    message: "最大文字数を超えています",
  })
  @IsString()
  bankAccountNumber?: string;

  constructor(arg: Partial<DriverReq>) {
    Object.assign(this, arg);
  }
}
