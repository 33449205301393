import { store } from "index";
import { combineReducers } from "@reduxjs/toolkit";
import auth from "./reducer/auth";
import account from "redux/reducer/account";
import staff from "redux/reducer/staff";
import modals from "redux/reducer/modals";
import driver from "redux/reducer/driver";
import supplier from "redux/reducer/supplier";
import deal from "./reducer/deal";
import dailyReport from "./reducer/dailyReport";
import detail from "redux/reducer/detail";
import invoice from "./reducer/invoice";
export const rootReducer = combineReducers({
  auth,
  account,
  dailyReport,
  deal,
  detail,
  driver,
  modals,
  invoice,
  staff,
  supplier,
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
