import { AppBar, Box, Button, IconButton, Link, Toolbar } from "@mui/material";
import React from "react";
import styled from "styled-components";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useNavigate } from "react-router-dom";
import { logout } from "redux/actions/auth";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
const Container = styled.div`
  height: 100vh;
`;

type Props = {
  children: React.ReactElement;
};
const Layout = ({ children }: Props) => {
  const isLogin = useAppSelector((state) => state.auth.accessToken);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleAuth = () => {
    if (isLogin) {
      dispatch(logout());
    }
    navigate("/login");
  };

  const menuItems = [
    {
      href: "/dailyReport",
      title: "日報管理",
    },
    {
      href: "/departure",
      title: "出発確認",
    },
    {
      href: "/detail",
      title: "明細管理",
    },
    {
      href: "/invoice",
      title: "請求管理",
    },
    {
      href: "/deal",
      title: "現場管理",
    },
    {
      href: "/supplier",
      title: "取引先管理",
    },
    {
      href: "/driver",
      title: "ドライバー管理",
    },
    {
      href: "/staff",
      title: "スタッフ管理",
    },
    {
      href: "/account",
      title: "アカウント管理",
    },
  ];
  return (
    <Container>
      <AppBar position="static">
        <Toolbar>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flex={1}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <LocalShippingIcon />
            </IconButton>
            {isLogin
              ? menuItems.map((item) => (
                  <Box display="flex">
                    <Link href={item.href} color={"#fff"}>
                      {item.title}
                    </Link>
                  </Box>
                ))
              : null}
            <Button color="inherit" onClick={() => handleAuth()}>
              {isLogin ? "ログアウト" : "ログイン"}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <>{children}</>
      <ToastContainer />
    </Container>
  );
};
export default Layout;
