import React, { useState } from "react";
import StaffApi from "api/StaffApi";
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";

const ResetPasswordSent = () => {
  const [email, setEmail] = useState("");
  const onChangeEmail = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmail(event.target.value);
  };
  const [loading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const onClickPostButton = async () => {
    if (!email) return;

    setLoading(true);
    await StaffApi.resetPasswordSendEmail(email);
    setLoading(false);
    setIsSent(true);
  };
  return (
    <Container style={{ marginTop: "40px" }}>
      <Typography>
        初回ログインの方またはパスワードを忘れた方は下記のフォームにメールアドレスを入力し、送信してください。
      </Typography>
      <Typography>
        アカウント登録時にご登録して頂いたメールアドレスにパスワード再発行手続きのメールをお送りします。
      </Typography>
      <TextField
        style={{ marginTop: "10px" }}
        required
        label="メールアドレス"
        variant="outlined"
        autoComplete="email"
        fullWidth
        onChange={onChangeEmail}
      />
      <Button
        style={{ marginTop: "10px" }}
        variant="contained"
        color="primary"
        fullWidth
        disabled={loading}
        onClick={onClickPostButton}
      >
        {loading ? <CircularProgress color="inherit" size={24} /> : "送信"}
      </Button>
      {isSent && (
        <Alert severity="success" style={{ marginTop: "10px" }}>
          <AlertTitle>メールを送信しました</AlertTitle>
        </Alert>
      )}
    </Container>
  );
};
export default ResetPasswordSent;
