import BaseRes from "types/res/BaseRes";
import { Expose } from "class-transformer";
import { BankAccountType } from "types/enum/BankAccountType";

export default class AccountRes extends BaseRes {
  @Expose() readonly name!: string;
  @Expose() readonly postCode!: string;
  @Expose() readonly address!: string;
  @Expose() readonly email!: string;
  @Expose() readonly invoiceNumber?: string;
  @Expose() readonly tel!: string;
  @Expose() readonly fax?: string;
  @Expose() readonly bankName1!: string;
  @Expose() readonly bankAccountName1!: string;
  @Expose() readonly bankAccountType1!: BankAccountType;
  @Expose() readonly bankAccountNumber1!: string;
  @Expose() readonly bankName2?: string;
  @Expose() readonly bankAccountName2?: string;
  @Expose() readonly bankAccountType2?: BankAccountType;
  @Expose() readonly bankAccountNumber2?: string;
  @Expose() readonly bankName3?: string;
  @Expose() readonly bankAccountName3?: string;
  @Expose() readonly bankAccountType3?: BankAccountType;
  @Expose() readonly bankAccountNumber3?: string;
  @Expose() readonly fileUrl?: string;
}
