import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "components/Modal";
import useModal from "hooks/useModal";
import { Box, Button, CircularProgress, Typography } from "@mui/material";

const DeleteModal = ({ deleteFunc }: { deleteFunc: (a: any) => any }) => {
  const [deleteModal, setDeleteShow] = useModal("deleteData");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const onClickDeleteButton = async () => {
    setLoading(true);
    await dispatch(deleteFunc(deleteModal.meta?.item));
    setLoading(false);
    setDeleteShow(false);
  };
  return (
    <Modal show={deleteModal.show} setShow={setDeleteShow} title="削除">
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="center">
          <Box display="flex" flexDirection="column">
            <Typography>本当に削除しますか？</Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          onClick={onClickDeleteButton}
          style={{ marginTop: "8px" }}
        >
          {loading ? <CircularProgress color="inherit" size={24} /> : "決定"}
        </Button>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
