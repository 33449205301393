import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { fetchOneAccount, updateAccount } from "redux/actions/account";
import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import AccountRes from "types/res/AccountRes";
import { BankAccountType } from "types/enum/BankAccountType";
import { toast } from "react-toastify";
import { validate, ValidationError } from "class-validator";
import AccountReq from "types/req/AccountReq";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styled from "styled-components";
import AccountApi from "api/AccountApi";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Account = () => {
  const dispatch = useAppDispatch();
  const accountId = useAppSelector((state) => state.auth.staff.accountId);
  const account = useAppSelector(
    (state) => state.account.entities.accounts?.[accountId]
  );
  const [accountReq, setAccountReq] = useState<AccountRes>({} as AccountRes);
  const [file, setFile] = useState("");
  const [errors, setErrors] = useState<ValidationError[]>([]);
  useEffect(() => {
    if (!accountId) return;
    dispatch(fetchOneAccount({ id: accountId }));
  }, [accountId]);

  useEffect(() => {
    if (!account) return;
    setAccountReq(account);
    setFile(
      `https://tar-zan-file-upload.s3.ap-northeast-1.amazonaws.com/${account?.fileUrl}` ||
        ""
    );
  }, [account]);

  const onClickReset = () => {
    setAccountReq(account);
  };

  const onClickSubmit = async () => {
    const data = new AccountReq(accountReq);
    const errors = await validate(data);
    setErrors(errors);
    if (errors.length > 0) {
      toast.error("入力内容に誤りがあります");
      return;
    }
    dispatch(updateAccount({ id: accountId, req: accountReq }));
    toast.success("保存しました");
  };

  const isError = (property: string) => {
    return Boolean(errors.find((error) => error.property === property));
  };

  const errorText = (property: string) => {
    return (
      <Box display={"flex"} flexDirection={"column"}>
        {Object.values(
          errors.find((error) => error.property === property)?.constraints || {}
        ).map((text) => (
          <Typography>{text}</Typography>
        ))}
      </Box>
    );
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} padding={2}>
        <Box>
          <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
            アカウント設定
          </Typography>
        </Box>
        <Box display="flex" paddingTop={2} justifyContent={"space-between"}>
          <Box display="flex" flexDirection="column" flexBasis={"25%"}>
            <TextField
              label="払出会社名"
              value={accountReq?.name || ""}
              onChange={(event) => {
                event.persist();
                setAccountReq((prev) => ({
                  ...prev,
                  name: event.target.value,
                }));
              }}
              error={isError("name")}
              helperText={errorText("name")}
            />
            <TextField
              label="登録番号"
              style={{ marginTop: "20px" }}
              value={accountReq?.invoiceNumber || ""}
              onChange={(event) => {
                event.persist();
                setAccountReq((prev) => ({
                  ...prev,
                  invoiceNumber: event.target.value,
                }));
              }}
              error={isError("invoiceNumber")}
              helperText={errorText("invoiceNumber")}
            />
          </Box>
          <Box display="flex" flexDirection="column" flexBasis={"70%"}>
            <Box display="flex">
              <TextField
                label="郵便番号"
                prefix={"〒"}
                value={accountReq?.postCode || ""}
                onChange={(event) => {
                  event.persist();
                  let value = event.target.value;
                  if (
                    event.target.value.length > 3 &&
                    !event.target.value.includes("-")
                  ) {
                    value =
                      event.target.value.slice(0, 3) +
                      "-" +
                      event.target.value.slice(3);
                  }
                  setAccountReq((prev) => ({
                    ...prev,
                    postCode: value,
                  }));
                }}
                error={isError("postCode")}
                helperText={errorText("postCode")}
              />
              <TextField
                label="住所"
                style={{ marginLeft: "10px", width: "70%" }}
                value={accountReq?.address || ""}
                onChange={(event) => {
                  event.persist();
                  setAccountReq((prev) => ({
                    ...prev,
                    address: event.target.value,
                  }));
                }}
                error={isError("address")}
                helperText={errorText("address")}
              />
            </Box>
            <Box display="flex" style={{ marginTop: "20px" }}>
              <TextField
                label="メールアドレス"
                value={accountReq?.email || ""}
                style={{ width: "30%" }}
                onChange={(event) => {
                  event.persist();
                  setAccountReq((prev) => ({
                    ...prev,
                    email: event.target.value,
                  }));
                }}
                error={isError("email")}
                helperText={errorText("email")}
              />
              <TextField
                label="電話番号"
                style={{ marginLeft: "30px" }}
                value={accountReq?.tel || ""}
                onChange={(event) => {
                  event.persist();
                  setAccountReq((prev) => ({
                    ...prev,
                    tel: event.target.value,
                  }));
                }}
                error={isError("tel")}
                helperText={errorText("tel")}
              />
              <TextField
                label="FAX"
                style={{ marginLeft: "10px" }}
                value={accountReq?.fax || ""}
                onChange={(event) => {
                  event.persist();
                  setAccountReq((prev) => ({
                    ...prev,
                    fax: event.target.value,
                  }));
                }}
                error={isError("fax")}
                helperText={errorText("fax")}
              />
            </Box>
          </Box>
        </Box>
        <Box display={"flex"} style={{ marginTop: "20px" }}>
          <Box display={"flex"} flexDirection={"column"}>
            <Box>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginTop: "20px",
                }}
              >
                口座情報
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginTop: "20px" }}
            >
              <Box>
                <Typography>第一口座</Typography>
              </Box>
              <Box display="flex" style={{ marginTop: "20px" }}>
                <TextField
                  label="口座名義"
                  value={accountReq?.bankAccountName1 || ""}
                  onChange={(event) => {
                    event.persist();
                    setAccountReq((prev) => ({
                      ...prev,
                      bankAccountName1: event.target.value,
                    }));
                  }}
                  error={isError("bankAccountName1")}
                  helperText={errorText("bankAccountName1")}
                />
                <TextField
                  label="取引銀行・支店名"
                  style={{ marginLeft: "10px" }}
                  value={accountReq?.bankName1 || ""}
                  onChange={(event) => {
                    event.persist();
                    setAccountReq((prev) => ({
                      ...prev,
                      bankName1: event.target.value,
                    }));
                  }}
                  error={isError("bankName1")}
                  helperText={errorText("bankName1")}
                />
                <TextField
                  select
                  label="口座種別"
                  style={{ marginLeft: "10px", width: "150px" }}
                  value={accountReq?.bankAccountType1 || ""}
                  onChange={(event) => {
                    setAccountReq((prev) => ({
                      ...prev,
                      bankAccountType1: event.target.value as BankAccountType,
                    }));
                  }}
                  error={isError("bankAccountType1")}
                  helperText={errorText("bankAccountType1")}
                >
                  {Object.entries(BankAccountType).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="口座番号"
                  style={{ marginLeft: "10px" }}
                  value={accountReq?.bankAccountNumber1 || ""}
                  onChange={(event) => {
                    event.persist();
                    setAccountReq((prev) => ({
                      ...prev,
                      bankAccountNumber1: event.target.value,
                    }));
                  }}
                  error={isError("bankAccountNumber1")}
                  helperText={errorText("bankAccountNumber1")}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginTop: "20px" }}
            >
              <Box>
                <Typography>第二口座</Typography>
              </Box>
              <Box display="flex" style={{ marginTop: "20px" }}>
                <TextField
                  label="口座名義"
                  value={accountReq?.bankAccountName2 || ""}
                  onChange={(event) => {
                    event.persist();
                    setAccountReq((prev) => ({
                      ...prev,
                      bankAccountName2: event.target.value,
                    }));
                  }}
                  error={isError("bankAccountName2")}
                  helperText={errorText("bankAccountName2")}
                />
                <TextField
                  label="取引銀行・支店名"
                  style={{ marginLeft: "10px" }}
                  value={accountReq?.bankName2 || ""}
                  onChange={(event) => {
                    event.persist();
                    setAccountReq((prev) => ({
                      ...prev,
                      bankName2: event.target.value,
                    }));
                  }}
                  error={isError("bankName2")}
                  helperText={errorText("bankName2")}
                />
                <TextField
                  select
                  label="口座種別"
                  style={{ marginLeft: "10px", width: "150px" }}
                  value={accountReq?.bankAccountType2 || ""}
                  onChange={(event) => {
                    setAccountReq((prev) => ({
                      ...prev,
                      bankAccountType2: event.target.value as BankAccountType,
                    }));
                  }}
                  error={isError("bankAccountType2")}
                  helperText={errorText("bankAccountType2")}
                >
                  {Object.entries(BankAccountType).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="口座番号"
                  style={{ marginLeft: "10px" }}
                  value={accountReq?.bankAccountNumber2 || ""}
                  onChange={(event) => {
                    event.persist();
                    setAccountReq((prev) => ({
                      ...prev,
                      bankAccountNumber2: event.target.value,
                    }));
                  }}
                  error={isError("bankAccountNumber2")}
                  helperText={errorText("bankAccountNumber2")}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginTop: "20px" }}
            >
              <Box>
                <Typography>第三口座</Typography>
              </Box>
              <Box display="flex" style={{ marginTop: "20px" }}>
                <TextField
                  label="口座名義"
                  value={accountReq?.bankAccountName3 || ""}
                  onChange={(event) => {
                    event.persist();
                    setAccountReq((prev) => ({
                      ...prev,
                      bankAccountName3: event.target.value,
                    }));
                  }}
                  error={isError("bankAccountName3")}
                  helperText={errorText("bankAccountName3")}
                />
                <TextField
                  label="取引銀行・支店名"
                  style={{ marginLeft: "10px" }}
                  value={accountReq?.bankName3 || ""}
                  onChange={(event) => {
                    event.persist();
                    setAccountReq((prev) => ({
                      ...prev,
                      bankName3: event.target.value,
                    }));
                  }}
                  error={isError("bankName3")}
                  helperText={errorText("bankName3")}
                />
                <TextField
                  select
                  label="口座種別"
                  style={{ marginLeft: "10px", width: "150px" }}
                  value={accountReq?.bankAccountType3 || ""}
                  onChange={(event) => {
                    setAccountReq((prev) => ({
                      ...prev,
                      bankAccountType3: event.target.value as BankAccountType,
                    }));
                  }}
                  error={isError("bankAccountType3")}
                  helperText={errorText("bankAccountType3")}
                >
                  {Object.entries(BankAccountType).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="口座番号"
                  style={{ marginLeft: "10px" }}
                  value={accountReq?.bankAccountNumber3 || ""}
                  onChange={(event) => {
                    event.persist();
                    setAccountReq((prev) => ({
                      ...prev,
                      bankAccountNumber3: event.target.value,
                    }));
                  }}
                  error={isError("bankAccountNumber3")}
                  helperText={errorText("bankAccountNumber3")}
                />
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"column"} marginLeft={4}>
            <Box>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginTop: "20px",
                }}
              >
                角印アップロード
              </Typography>
            </Box>
            <Box display={"flex"} marginTop={2}>
              <Box>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  ファイルを選択
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const files = event.currentTarget.files;
                      if (!files || files?.length === 0) return;
                      const firstFile = files[0];
                      const reader = new FileReader();
                      reader.readAsDataURL(firstFile);
                      reader.addEventListener("load", () => {
                        setFile(
                          typeof reader.result === "string" ? reader.result : ""
                        );
                      });
                    }}
                  />
                </Button>
              </Box>
              {file && (
                <Box display={"flex"} alignItems={"flex-end"}>
                  <Box>
                    <img
                      src={file}
                      alt={""}
                      width={"300px"}
                      height={"300px"}
                      style={{ marginLeft: "16px" }}
                    />
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginLeft: "16px" }}
                      disabled={!file}
                      onClick={() => {
                        setFile("");
                      }}
                    >
                      削除
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      style={{ marginLeft: "8px" }}
                      disabled={
                        file ===
                        `https://tar-zan-file-upload.s3.ap-northeast-1.amazonaws.com/${account.fileUrl}`
                      }
                      onClick={async () => {
                        const res = await AccountApi.fileUpload(accountId, {
                          file,
                        });
                      }}
                    >
                      アップロード
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent={"center"}
          style={{ marginTop: "48px" }}
        >
          <Button
            variant="contained"
            color={"secondary"}
            onClick={onClickReset}
          >
            リセット
          </Button>
          <Button
            variant="contained"
            color={"primary"}
            onClick={onClickSubmit}
            style={{ marginLeft: "16px" }}
          >
            送信
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default Account;
