import React, { useEffect } from "react";
import { Box } from "@mui/material";
import StaffRes from "types/res/StaffRes";
import StaffReq from "types/req/StaffReq";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  createStaff,
  deleteStaff,
  fetchAllStaff,
  updateStaff,
} from "redux/actions/staff";
import { FormType } from "components/FormField";
import { StaffRole } from "types/enum/StaffRole";
import EnumUtils from "utils/EnumUtils";

const Staff = () => {
  const dispatch = useAppDispatch();
  const staffIds = useAppSelector((state) => state.staff.result);
  const staffEntities = useAppSelector((state) => state.staff.entities.staffs);
  const staffs = staffIds.map((id) => staffEntities[id]);
  const headers: { key: keyof StaffRes; label: string }[] = [
    { key: "name", label: "名前" },
    { key: "email", label: "メールアドレス" },
    { key: "tel", label: "電話番号" },
    { key: "role", label: "役割" },
  ];

  const forms: any = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "メールアドレス",
      key: "email",
      type: FormType.Text,
    },
    {
      label: "電話番号",
      key: "tel",
      type: FormType.Text,
    },
    {
      label: "役割",
      key: "role",
      type: FormType.Select,
      options: Object.entries(StaffRole).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
  ];

  useEffect(() => {
    dispatch(fetchAllStaff());
  }, []);

  return (
    <Box display="flex">
      <StaffTable
        formId={"staff"}
        data={staffs}
        addType={StaffReq}
        addFunc={(formData) => createStaff({ req: formData })}
        title={"スタッフ"}
        rows={headers}
        updateFunc={(formData) =>
          updateStaff({ id: formData.id, req: formData })
        }
        deleteFunc={(formData) => deleteStaff({ id: formData.id })}
        forms={forms}
        values={[
          (v) => v.name || "未設定",
          (v) => v.email || "未設定",
          (v) => v.tel || "未設定",
          (v) => EnumUtils.mapToEnum(StaffRole, v.role) || "未設定",
        ]}
      />
    </Box>
  );
};

const StaffTable = styled<React.FC<CommonTableProps<StaffRes, StaffReq>>>(
  CommonTable
)`
  margin-top: 24px;
`;
export default Staff;
