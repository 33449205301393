import BaseRes from "types/res/BaseRes";
import { Expose, Type } from "class-transformer";
import DriverRes from "types/res/DriverRes";
import { BankAccountType } from "types/enum/BankAccountType";
import DetailItemRes from "types/res/DetailItemRes";
import AccountRes from "types/res/AccountRes";
import { HonorificTitle } from "types/enum/HonorificTitle";

export default class DetailRes extends BaseRes {
  @Type(() => AccountRes)
  @Expose()
  readonly account!: AccountRes;
  @Expose() readonly driverId!: number;
  @Type(() => DriverRes)
  @Expose()
  readonly driver!: DriverRes;
  @Expose() readonly targetMonth!: string;
  @Expose() readonly title!: string;
  @Expose() readonly payDate!: string;
  @Expose() readonly honorificTitle!: HonorificTitle;
  @Expose() readonly driverInvoiceNumber?: string;
  @Expose() readonly driverPostCode?: string;
  @Expose() readonly driverAddress?: string;
  @Expose() readonly driverBankName?: string;
  @Expose() readonly driverBankAccountName?: string;
  @Expose() readonly driverBankAccountType?: BankAccountType;
  @Expose() readonly driverBankAccountNumber?: string;
  @Expose() readonly accountName?: string;
  @Expose() readonly accountPostCode?: string;
  @Expose() readonly accountAddress?: string;
  @Expose() readonly accountTel?: string;
  @Expose() readonly accountFax?: string;
  @Expose() readonly subtotal!: number;
  @Expose() readonly betweenSubtotalAndTaxText?: string;
  @Expose() readonly betweenSubtotalAndTax?: number;
  @Expose() readonly tax!: number;
  @Expose() readonly advancePaid?: number;
  @Expose() readonly vehicleFee?: number;
  @Expose() readonly betweenVehicleFeeAndTotalText1?: string;
  @Expose() readonly betweenVehicleFeeAndTotalText2?: string;
  @Expose() readonly betweenVehicleFeeAndTotalText3?: string;
  @Expose() readonly betweenVehicleFeeAndTotal1?: number;
  @Expose() readonly betweenVehicleFeeAndTotal2?: number;
  @Expose() readonly betweenVehicleFeeAndTotal3?: number;
  @Expose() readonly total!: number;
  @Expose() readonly isNotify!: boolean;
  @Expose() readonly isRead!: boolean;
  @Expose() readonly remarks?: string;
  @Expose() readonly customize1?: string;
  @Expose() readonly customize2?: string;
  @Expose() readonly subtotalTitle?: string;
  @Expose() readonly taxTitle?: string;
  @Expose() readonly advancePaidTitle?: string;
  @Expose() readonly vehicleFeeTitle?: string;
  @Expose() readonly totalTitle?: string;
  @Type(() => DetailItemRes)
  @Expose()
  readonly detailItems!: DetailItemRes[];
}
