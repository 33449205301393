import SchemaUtils from "utils/SchemaUtils";
import StaffRes from "types/res/StaffRes";
import { normalize } from "normalizr";
import { createAsyncThunk } from "@reduxjs/toolkit";
import StaffApi from "api/StaffApi";
import StaffReq from "types/req/StaffReq";

const staffSchema = SchemaUtils.createEntity<StaffRes>("staffs");
type schemaType = { staffs: { [key: number]: StaffRes } };

export const fetchAllStaff = createAsyncThunk("fetchAllStaff", async () => {
  const data = await StaffApi.findAll();
  return normalize<undefined, schemaType, number[]>(data, [staffSchema]);
});

export const createStaff = createAsyncThunk(
  "createStaff",
  async ({ req }: { req: StaffReq }) => {
    const data = await StaffApi.create(req);
    return normalize<undefined, schemaType, number>(data, staffSchema);
  }
);

export const updateStaff = createAsyncThunk(
  "updateStaff",
  async ({ id, req }: { id: number; req: StaffReq }) => {
    const data = await StaffApi.update(id, req);
    return normalize<undefined, schemaType, number>(data, staffSchema);
  }
);

export const deleteStaff = createAsyncThunk(
  "deleteStaff",
  async ({ id }: { id: number }) => {
    await StaffApi.delete(id);
    return id;
  }
);
