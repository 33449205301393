import BaseRes from "types/res/BaseRes";
import { Exclude, Expose, Type } from "class-transformer";
import { StaffRole } from "types/enum/StaffRole";
import AccountRes from "types/res/AccountRes";

export default class StaffRes extends BaseRes {
  @Expose() readonly name!: string;
  @Expose() readonly email!: string;
  @Exclude()
  @Expose()
  readonly password!: string;
  @Expose() readonly tel!: string;
  @Expose()
  readonly role!: StaffRole;
  @Expose()
  readonly accountId!: number;
  @Expose()
  @Type(() => AccountRes)
  readonly account!: AccountRes;
}
