import SchemaUtils from "utils/SchemaUtils";
import DailyReportRes from "types/res/DailyReportRes";
import { normalize } from "normalizr";
import { createAsyncThunk } from "@reduxjs/toolkit";
import DailyReportApi from "api/DailyReportApi";
import DailyReportReq from "types/req/DailyReportReq";
import SearchDailyReportReq from "types/req/search/SearchDailyReportReq";

const dailyReportSchema =
  SchemaUtils.createEntity<DailyReportRes>("dailyReports");
type schemaType = { dailyReports: { [key: number]: DailyReportRes } };

export const fetchAllDailyReport = createAsyncThunk(
  "fetchAllDailyReport",
  async ({ req }: { req: SearchDailyReportReq }) => {
    const data = await DailyReportApi.findAll(req);
    return normalize<undefined, schemaType, number[]>(data, [
      dailyReportSchema,
    ]);
  }
);

export const createDailyReport = createAsyncThunk(
  "createDailyReport",
  async ({ req }: { req: DailyReportReq }) => {
    const data = await DailyReportApi.create(req);
    return normalize<undefined, schemaType, number>(data, dailyReportSchema);
  }
);

export const updateDailyReport = createAsyncThunk(
  "updateDailyReport",
  async ({ id, req }: { id: number; req: DailyReportReq }) => {
    const data = await DailyReportApi.update(id, req);
    return normalize<undefined, schemaType, number>(data, dailyReportSchema);
  }
);

export const deleteDailyReport = createAsyncThunk(
  "deleteDailyReport",
  async ({ id }: { id: number }) => {
    await DailyReportApi.delete(id);
    return id;
  }
);
