import { useAppDispatch, useAppSelector } from "redux/hooks";
import React, { useEffect, useState } from "react";
import {
  createDeal,
  deleteDeal,
  fetchAllDeal,
  updateDeal,
} from "redux/actions/deal";
import { fetchAllSupplier } from "redux/actions/supplier";
import { Box, Button } from "@mui/material";
import MultipleSelect from "components/MultipleSelect";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import DealRes from "types/res/DealRes";
import DealReq from "types/req/DealReq";
import { FormType } from "components/FormField";
import EnumUtils from "utils/EnumUtils";
import { RewardType } from "types/enum/RewardType";
import DealApi from "api/DealApi";

const Deal = () => {
  const dispatch = useAppDispatch();
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectSupplierIds, setSelectSupplierIds] = useState<string[]>([]);
  const supplierIds = useAppSelector((state) => state.supplier.result);
  const supplierEntities = useAppSelector(
    (state) => state.supplier.entities.suppliers
  );
  const suppliers = supplierIds.map((id) => supplierEntities[id]);
  const dealIds = useAppSelector((state) => state.deal.result);
  const dealEntities = useAppSelector((state) => state.deal.entities.deals);
  const deals = dealIds.map((id) => dealEntities[id]);
  useEffect(() => {
    dispatch(fetchAllDeal({ req: { limit: 100, offset: 0 } }));
    dispatch(fetchAllSupplier({ req: { limit: 1000, offset: 0 } }));
    const apiCall = async () => {
      const result = await DealApi.count({
        limit: 0,
        offset: 0,
      });
      setTotalCount(result);
    };
    apiCall();
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchAllDeal({
        req: {
          limit,
          offset,
        },
      })
    );
  }, [limit, offset]);

  const headers: { key: keyof DealRes; label: string }[] = [
    { key: "name", label: "現場名" },
    { key: "supplierId", label: "取引先名" },
    { key: "supplierId", label: "取引先かな" },
    { key: "rewardType", label: "報酬区分" },
    { key: "remarks", label: "備考" },
    { key: "companyProfit1", label: "会社粗利1" },
    { key: "driverSalary1", label: "ドライバー運賃1" },
    { key: "billingAmount1", label: "請求額1" },
    { key: "companyProfit2", label: "会社粗利2" },
    { key: "driverSalary2", label: "ドライバー運賃2" },
    { key: "billingAmount2", label: "請求額2" },
    { key: "overtimeMoneyCompanyProfit", label: "残業代会社粗利" },
    { key: "overtimeMoneyDriverSalary", label: "残業代ドライバー運賃" },
    { key: "overtimeMoneyBillingAmount", label: "残業代請求額" },
    { key: "distanceMoneyCompanyProfit", label: "距離増会社粗利" },
    { key: "distanceMoneyDriverSalary", label: "距離増ドライバー運賃" },
    { key: "distanceMoneyBillingAmount", label: "距離増請求額" },
  ];

  const forms = [
    {
      label: "現場名",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "取引先名",
      key: "supplierId",
      type: FormType.AutoCompleteSelect,
      options: suppliers,
    },
    {
      label: "報酬区分",
      key: "rewardType",
      type: FormType.Select,
      options: Object.entries(RewardType).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
    {
      label: "備考",
      key: "remarks",
      type: FormType.TextArea,
    },
    {
      label: "会社粗利1",
      key: "companyProfit1",
      type: FormType.Number,
    },
    {
      label: "ドライバー運賃1",
      key: "driverSalary1",
      type: FormType.Number,
    },
    {
      label: "請求額1",
      key: "billingAmount1",
      type: FormType.Number,
    },
    {
      label: "会社粗利2",
      key: "companyProfit2",
      type: FormType.Number,
    },
    {
      label: "ドライバー運賃2",
      key: "driverSalary2",
      type: FormType.Number,
    },
    {
      label: "請求額2",
      key: "billingAmount2",
      type: FormType.Number,
    },
    {
      label: "残業代会社粗利",
      key: "overtimeMoneyCompanyProfit",
      type: FormType.Number,
    },
    {
      label: "残業代ドライバー運賃",
      key: "overtimeMoneyDriverSalary",
      type: FormType.Number,
    },
    {
      label: "残業代請求額",
      key: "overtimeMoneyBillingAmount",
      type: FormType.Number,
    },
    {
      label: "距離増会社粗利",
      key: "distanceMoneyCompanyProfit",
      type: FormType.Number,
    },
    {
      label: "距離増ドライバー運賃",
      key: "distanceMoneyDriverSalary",
      type: FormType.Number,
    },
    {
      label: "距離増請求額",
      key: "distanceMoneyBillingAmount",
      type: FormType.Number,
    },
  ];

  const onClickSearch = async () => {
    dispatch(
      fetchAllDeal({
        req: {
          limit,
          offset: 0,
          supplierIds: selectSupplierIds.includes("すべて")
            ? undefined
            : selectSupplierIds.map((id) => Number(id)),
        },
      })
    );
    const result = await DealApi.count({
      limit: 0,
      offset: 0,
      supplierIds: selectSupplierIds.includes("すべて")
        ? undefined
        : selectSupplierIds.map((id) => Number(id)),
    });
    setTotalCount(result);
  };
  return (
    <Box display="flex" flexDirection="column">
      <Header
        selectedSupplierIds={selectSupplierIds}
        setSelectedSupplierIds={setSelectSupplierIds}
        onClickSearch={onClickSearch}
      />
      <DealTable
        formId={"deal"}
        data={deals}
        addFunc={(formData) => createDeal({ req: formData })}
        addType={DealReq}
        values={[
          (s) => s.name,
          (s) => s.supplier.name,
          (s) => s.supplier.kana,
          (s) => EnumUtils.mapToEnum(RewardType, s.rewardType) || "未設定",
          (s) => s.remarks || "未設定",
          (s) => s.companyProfit1 || "未設定",
          (s) => s.driverSalary1 || "未設定",
          (s) => s.billingAmount1 || "未設定",
          (s) => s.companyProfit2 || "未設定",
          (s) => s.driverSalary2 || "未設定",
          (s) => s.billingAmount2 || "未設定",
          (s) => s.overtimeMoneyCompanyProfit || "未設定",
          (s) => s.overtimeMoneyDriverSalary || "未設定",
          (s) => s.overtimeMoneyBillingAmount || "未設定",
          (s) => s.distanceMoneyCompanyProfit || "未設定",
          (s) => s.distanceMoneyDriverSalary || "未設定",
          (s) => s.distanceMoneyBillingAmount || "未設定",
        ]}
        limit={limit}
        setLimit={setLimit}
        offset={offset}
        setOffset={setOffset}
        totalCount={totalCount}
        deleteFunc={(formData) => deleteDeal({ id: formData.id })}
        title={"現場"}
        rows={headers}
        updateFunc={(formData) =>
          updateDeal({ id: formData.id, req: formData })
        }
        forms={forms}
      />
    </Box>
  );
};

type HeaderProps = {
  selectedSupplierIds: string[];
  setSelectedSupplierIds: React.Dispatch<React.SetStateAction<string[]>>;
  onClickSearch: () => Promise<void>;
};
const Header = ({
  selectedSupplierIds,
  setSelectedSupplierIds,
  onClickSearch,
}: HeaderProps) => {
  const supplierIds = useAppSelector((state) => state.supplier.result);
  const supplierEntities = useAppSelector(
    (state) => state.supplier.entities.suppliers
  );
  const suppliers = supplierIds.map((id) => supplierEntities[id]);
  useEffect(() => {
    setSelectedSupplierIds([
      ...suppliers.map((supplier) => String(supplier.id)),
      "すべて",
    ]);
  }, [supplierIds]);

  return (
    <Box display="flex" margin={2} alignItems={"flex-end"}>
      <Box marginLeft={1}>
        <MultipleSelect
          selected={selectedSupplierIds}
          setSelected={setSelectedSupplierIds}
          id={"select-multiple-supplier"}
          options={suppliers}
          label={"取引先"}
        />
      </Box>
      <Box marginLeft={1}>
        <Button variant="contained" color="secondary" onClick={onClickSearch}>
          検索
        </Button>
      </Box>
    </Box>
  );
};

const DealTable = styled<React.FC<CommonTableProps<DealRes, DealReq>>>(
  CommonTable
)`
  margin-top: 24px;
`;

export default Deal;
