import DriverRes from "types/res/DriverRes";
import { createSlice } from "@reduxjs/toolkit";
import {
  createDriver,
  deleteDriver,
  fetchAllDriver,
  updateDriver,
} from "redux/actions/driver";

export type DriverState = {
  entities: { drivers: { [key: number]: DriverRes } };
  result: number[];
};
const initialState: DriverState = { entities: { drivers: {} }, result: [] };

const driverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllDriver.fulfilled, (_, { payload }) => ({
      entities: { drivers: { ...payload.entities.drivers } },
      result: payload.result,
    }));
    builder.addCase(createDriver.fulfilled, (state, { payload }) => ({
      entities: {
        drivers: { ...state.entities.drivers, ...payload.entities.drivers },
      },
      result: [...state.result, payload.result],
    }));
    builder.addCase(updateDriver.fulfilled, (state, { payload }) => ({
      entities: {
        drivers: {
          ...state.entities.drivers,
          ...payload.entities.drivers,
        },
      },
      result: state.result,
    }));
    builder.addCase(deleteDriver.fulfilled, (state, { payload }) => {
      const entities = { ...state.entities.drivers };
      delete entities[payload];
      return {
        entities: {
          drivers: {
            ...entities,
          },
        },
        result: state.result.filter((id) => id !== payload),
      };
    });
  },
});

export default driverSlice.reducer;
