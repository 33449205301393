import BaseRes from "types/res/BaseRes";
import { Exclude, Expose, Type } from "class-transformer";
import { BankAccountType } from "types/enum/BankAccountType";
import DealRes from "types/res/DealRes";

export default class DriverRes extends BaseRes {
  @Expose() readonly name!: string;
  @Expose() readonly kana!: string;
  @Expose() readonly invoiceNumber?: string;
  @Expose() readonly tel?: string;
  @Expose() readonly email!: string;
  @Exclude()
  @Expose()
  readonly password!: string;
  @Expose() readonly postCode?: string;
  @Expose() readonly address?: string;
  @Expose() readonly remarks?: string;
  @Type(() => Date)
  @Expose()
  readonly registeredAt?: Date;
  @Type(() => DealRes)
  @Expose()
  readonly deals?: DealRes[];
  @Expose() readonly bankName?: string;
  @Expose() readonly bankAccountName?: string;
  @Expose() readonly bankAccountType?: BankAccountType;
  @Expose() readonly bankAccountNumber?: string;
}
