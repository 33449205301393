import DealRes from "types/res/DealRes";
import { createSlice } from "@reduxjs/toolkit";
import {
  createDeal,
  deleteDeal,
  fetchAllDeal,
  updateDeal,
} from "redux/actions/deal";

export type DealState = {
  entities: { deals: { [key: number]: DealRes } };
  result: number[];
};
const initialState: DealState = { entities: { deals: {} }, result: [] };

const dealSlice = createSlice({
  name: "deal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllDeal.fulfilled, (_, { payload }) => ({
      entities: { deals: { ...payload.entities.deals } },
      result: payload.result,
    }));
    builder.addCase(createDeal.fulfilled, (state, { payload }) => ({
      entities: {
        deals: { ...state.entities.deals, ...payload.entities.deals },
      },
      result: [...state.result, payload.result],
    }));
    builder.addCase(updateDeal.fulfilled, (state, { payload }) => ({
      entities: {
        deals: {
          ...state.entities.deals,
          ...payload.entities.deals,
        },
      },
      result: state.result,
    }));
    builder.addCase(deleteDeal.fulfilled, (state, { payload }) => {
      const entities = { ...state.entities.deals };
      delete entities[payload];
      return {
        entities: {
          deals: {
            ...entities,
          },
        },
        result: state.result.filter((id) => id !== payload),
      };
    });
  },
});

export default dealSlice.reducer;
