import RequestUtils from "utils/RequestUtils";
import InvoiceRes from "types/res/InvoiceRes";
import InvoiceReq from "types/req/InvoiceReq";
import SearchInvoiceReq from "types/req/search/SearchInvoiceReq";
import DetailRes from "types/res/DetailRes";

export default class InvoiceApi {
  static async findAll(body: SearchInvoiceReq) {
    return await RequestUtils.postArray(
      InvoiceRes,
      `/management/invoice/find-all`,
      body
    );
  }

  static async count(body: SearchInvoiceReq) {
    return await RequestUtils.postCount(`/management/invoice/count`, body);
  }

  static async sendMail(body: { invoiceId: number; image: string }) {
    return await RequestUtils.postVoid(`/management/invoice/send-mail`, body);
  }
  static async find(id: number) {
    return await RequestUtils.get(InvoiceRes, `/management/invoice/${id}`);
  }

  static async findPrevData(supplierId: number, month: string) {
    return await RequestUtils.get(
      InvoiceRes,
      `/management/invoice/findPrevData/${supplierId}/${month}`
    );
  }

  static async create(req: InvoiceReq) {
    return await RequestUtils.post(InvoiceRes, `/management/invoice`, req);
  }

  static async update(id: number, req: InvoiceReq) {
    return await RequestUtils.put(InvoiceRes, `/management/invoice/${id}`, req);
  }

  static async delete(id: number) {
    return await RequestUtils.delete(`/management/invoice/${id}`);
  }
}
