import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import DepartureRes from "types/res/DepartureRes";
import DepartureApi from "api/DepartureApi";

const Departure = () => {
  const [departures, setDepartures] = useState<DepartureRes[]>([]);

  useEffect(() => {
    const apiCall = async () => {
      const res = await DepartureApi.findAll();
      setDepartures(res);
    };
    apiCall();
  }, []);
  return (
    <Box display={"flex"} flexDirection={"column"} margin={2}>
      <Box>
        <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
          出発済み通知一覧
        </Typography>
        {departures.map((departure) => (
          <Box
            display={"flex"}
            margin={1}
            padding={2}
            width={"300px"}
            style={{ border: "1px solid gray", borderRadius: "10px" }}
          >
            <Typography>{departure?.driver?.name}</Typography>
            <Typography style={{ marginLeft: "8px" }}>
              {departure?.time}
            </Typography>
            <Typography style={{ marginLeft: "8px" }}>出発</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
export default Departure;
