import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  MaxLength,
} from "class-validator";
import Spec from "constant/Spec";
import { RewardType } from "types/enum/RewardType";

export default class DealReq {
  @IsNotEmpty({
    message: "未入力です",
  })
  @IsNumber()
  supplierId!: number;

  @IsNotEmpty({ message: "未入力です" })
  @IsString()
  name!: string;

  @IsOptional()
  rewardType?: RewardType;

  @IsOptional()
  @MaxLength(Spec.maxLength.driver.remarks, {
    message: "最大文字数を超えています",
  })
  @IsString()
  remarks?: string;

  @IsOptional()
  @IsNumber()
  companyProfit1?: number;

  @IsOptional()
  @IsNumber()
  driverSalary1?: number;

  @IsOptional()
  @IsNumber()
  billingAmount1?: number;

  @IsOptional()
  @IsNumber()
  companyProfit2?: number;

  @IsOptional()
  @IsNumber()
  driverSalary2?: number;

  @IsOptional()
  @IsNumber()
  billingAmount2?: number;

  @IsOptional()
  @IsNumber()
  overtimeMoneyCompanyProfit?: number;

  @IsOptional()
  @IsNumber()
  overtimeMoneyDriverSalary?: number;

  @IsOptional()
  @IsNumber()
  overtimeMoneyBillingAmount?: number;

  @IsOptional()
  @IsNumber()
  distanceMoneyCompanyProfit?: number;

  @IsOptional()
  @IsNumber()
  distanceMoneyDriverSalary?: number;

  @IsOptional()
  @IsNumber()
  distanceMoneyBillingAmount?: number;

  constructor(arg: Partial<DealReq>) {
    Object.assign(this, arg);
  }
}
