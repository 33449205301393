import RequestUtils from "utils/RequestUtils";
import StaffRes from "types/res/StaffRes";
import StaffReq from "types/req/StaffReq";

export default class StaffApi {
  static async findAll() {
    return await RequestUtils.getArray(StaffRes, `/management/staff`);
  }

  static async create(req: StaffReq) {
    return await RequestUtils.post(StaffRes, `/management/staff`, req);
  }

  static async update(id: number, req: StaffReq) {
    return await RequestUtils.put(StaffRes, `/management/staff/${id}`, req);
  }

  static async delete(id: number) {
    return await RequestUtils.delete(`/management/staff/${id}`);
  }

  static async findByResetToken(resetToken: string) {
    return await RequestUtils.get(
      StaffRes,
      `/management/staff/findByResetToken?token=${resetToken}`
    );
  }

  static async resetPassword(staffId: number, password: string) {
    return await RequestUtils.post(
      StaffRes,
      `/management/staff/passwordReset`,
      {
        staffId,
        password,
      }
    );
  }

  static async resetPasswordSendEmail(email: string) {
    return await RequestUtils.postVoid(
      `/management/staff/passwordReset/sendEmail`,
      {
        email,
      }
    );
  }
}
