import BaseRes from "types/res/BaseRes";
import { Expose, Type } from "class-transformer";
import SupplierRes from "types/res/SupplierRes";
import AccountRes from "types/res/AccountRes";
import { InvoiceType } from "types/enum/InvoiceType";
import InvoiceItemRes from "types/res/InvoiceItemRes";
import { HonorificTitle } from "types/enum/HonorificTitle";

export default class InvoiceRes extends BaseRes {
  @Type(() => AccountRes)
  @Expose()
  readonly account!: AccountRes;
  @Expose() readonly supplierId!: number;
  @Type(() => SupplierRes)
  @Expose()
  readonly supplier!: SupplierRes;
  @Expose() readonly targetMonth!: string;
  @Expose() readonly type!: InvoiceType;
  @Expose() readonly title!: string;
  @Expose() readonly payDate!: string;
  @Expose() readonly honorificTitle!: HonorificTitle;
  @Expose() readonly supplierInvoiceNumber?: string;
  @Expose() readonly supplierPostCode?: string;
  @Expose() readonly supplierAddress?: string;
  @Expose() readonly accountName?: string;
  @Expose() readonly accountPostCode?: string;
  @Expose() readonly accountAddress?: string;
  @Expose() readonly accountTel?: string;
  @Expose() readonly accountFax?: string;
  @Expose() readonly accountBankName?: string;
  @Expose() readonly accountBankAccountName?: string;
  @Expose() readonly accountBankAccountType?: string;
  @Expose() readonly accountBankAccountNumber?: string;
  @Expose() readonly subtotal!: number;
  @Expose() readonly tax!: number;
  @Expose() readonly advancePaid?: number;
  @Expose() readonly total!: number;
  @Expose() readonly remarks?: string;
  @Expose() readonly customize1?: string;
  @Expose() readonly customize2?: string;
  @Expose() readonly customize3?: string;
  @Expose() readonly customize4?: string;
  @Expose() readonly customize5?: string;
  @Expose() readonly customize6?: string;
  @Expose() readonly customize7?: string;
  @Expose() readonly customize8?: string;
  @Expose() readonly customize9?: string;
  @Expose() readonly customize10?: string;
  @Expose() readonly subtotalTitle?: string;
  @Expose() readonly taxTitle?: string;
  @Expose() readonly advancePaidTitle?: string;
  @Expose() readonly totalTitle?: string;
  @Type(() => InvoiceItemRes)
  @Expose()
  readonly invoiceItems!: InvoiceItemRes[];
}
