import RequestUtils from "utils/RequestUtils";
import DetailItemReq from "types/req/DetailItemReq";
export default class DetailItemApi {
  static async create(req: DetailItemReq[]) {
    return await RequestUtils.postVoid(`/management/detail-item`, req);
  }

  static async update(req: DetailItemReq[]) {
    return await RequestUtils.putVoid(`/management/detail-item`, req);
  }

  static async delete(id: number) {
    return await RequestUtils.delete(`/management/detail-item/${id}`);
  }
}
